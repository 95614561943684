import React, { useEffect } from 'react'
import Footer from "./Footer"
// import Navigation from "./Navigationnew";
import Navigation from "./Navprastype"
import { Row, Col } from "react-bootstrap"

const Terms = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="justify_content_center homfinmgmthead ">
                        TERMS AND CONDITIONS
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead mt-4">
                        SALE
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        1. SALE AND PURCHASE OF GOODS
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Aaradhy Silk Hand Craft ("Seller") hereby agrees to sell, and You ("Buyer") hereby agree to purchase, goods of the description and quantity described on the checkout window ("Checkout") and incorporated herein by this reference ("Goods") on the terms and conditions set forth in this Agreement.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        2. PURCHASE PRICE
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    Buyer agrees to pay the Purchase Price of the Goods as posted on this website attached hereto.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        3. PAYMENT TERMS
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    The total amount of the Purchase Price shall be payable in full by Buyer according to the payment due date stated at Checkout. In addition, Seller shall have the right to pursue any remedies available at law or as provided herein and shall be entitled to reimbursement from Buyer for Seller's costs of collection, including attorney fees, legal fees and costs and disbursements, if any.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        4. DELIVERY
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    Unless otherwise agreed in writing, delivery shall be made in accordance with Seller's shipping policy in effect on the date of shipment. Delivery dates provided by Seller are estimates only. Seller will make reasonable efforts to deliver in accordance with such dates; however, Seller will not be liable for failure to deliver as estimated. Unless otherwise agreed in writing by Seller, Goods shall be packaged according to Seller's standards and practices.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        5. CUSTOMS, DUTIES, AND TAXES
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    The recipient is responsible for assuring that the product can be lawfully imported to the destination country. When ordering from aaradhycraft.com, the recipient is the importer of record and must comply with all laws and regulations of the destination country. Orders that are shipped to countries outside of India may be subject to import taxes, customs duties and fees levied by the destination country. The recipient of an international shipment may be subject to such import taxes, customs duties and fees, which are levied once a shipment reaches the destination country. Additional charges for customs clearance must be borne by the recipient; we have no control over these charges and cannot predict what they may be. Customs policies vary widely from country to country; the recipient should contact their local customs office for further information. When customs clearance procedures are required, it can cause delays beyond our original delivery estimates.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    Customs, duties and taxes may be collected from the recipient by the carrier after paying it to the local customs office on recipient's behalf. Similarly, Octroi charges, as applicable, will be collected by the carrier from the consignee (recipient).
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    The recipient will submit social security number, tax ID or any such proof of address or business as required by their customs office to have the shipment cleared.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        6. DISCLAIMER OF WARRANTY/LIMITATION OF LIABILITY
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    Seller undertakes no responsibility for the quality of the Goods or that the Goods will be fit for any particular purpose for which Buyer may be buying the Goods, except as otherwise provided in this Agreement, and Seller disclaims all other warranties and conditions, express or implied.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    ELLER (INCLUDING ITS SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR SUBCONTRACTORS, ALL OF WHICH ARE REFERRED TO HEREIN COLLECTIVELY AS THE "SELLER AFFILIATES") SHALL NOT BE LIABLE UNDER ANY CIRCUMSTANCE TO BUYER OR ANY OTHER PARTY FOR ANY SPECIAL, CONSEQUENTIAL, INCIDENTAL OR EXEMPLARY DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE GOODS OR OTHERWISE, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOST PROFITS, LOSS OF THE GOODS OR ANY ASSOCIATED EQUIPMENT, COST OF CAPITAL, COST OF SUBSTITUTE OR REPLACEMENT EQUIPMENT, FACILITIES OR SERVICES, DOWN TIME, BUYER'S TIME, LOST DATA, INJURY TO PROPERTY OR ANY DAMAGES OR SUMS PAID BY BUYER TO THIRD PARTIES, EVEN IF SELLER OR ANY OF THE SELLER AFFILIATES HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY WHETHER ANY CLAIM IS BASED UPON PRINCIPLES OF CONTRACT, WARRANTY, NEGLIGENCE, OR OTHER TORT, BREACH OF ANY STATUTORY DUTY, PRINCIPLES OF INDEMNITY OR CONTRIBUTION, THE FAILURE OF ANY LIMITED OR EXCLUSIVE REMEDY TO ACHIEVE ITS ESSENTIAL PURPOSE, OR OTHERWISE.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    IN NO EVENT SHALL SELLER OR ANY SELLER AFFILIATE BE LIABLE TO BUYER OR ANY OTHER PARTY FOR LOSS, DAMAGE, OR INJURY OF ANY KIND OR NATURE ARISING OUT OF OR IN CONNECTION WITH THESE TERMS AND CONDITIONS IN EXCESS OF THE NET PURCHASE PRICE OF THE GOODS ACTUALLY DELIVERED TO AND PAID FOR BY BUYER HEREUNDER.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    SELLER DISCLAIMS ANY WARRANTIES OF NON-INFRINGEMENT WITH RESPECT TO THE GOODS AND NONE OF SELLER OR ANY SELLER AFFILIATE SHALL HAVE ANY DUTY TO DEFEND, INDEMNIFY, OR HOLD HARMLESS BUYER FROM AND AGAINST ANY OR ALL DAMAGES OR COSTS INCURRED BY BUYER ARISING FROM THE INFRINGEMENT OF PATENTS OR TRADEMARKS OR VIOLATION OF COPYRIGHTS BY ANY OF THE GOODS.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        7. COLOUR DISCLAIMER
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    The colours of products you see on computer screen will vary slightly from those of the actual product. This is because of the difference in underlying technologies of computer monitors and fabric; monitors that are not properly calibrated also add to the difference in colours. In addition, the lighting used while taking photographs also factor in the final colour shown on the screen.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        8. FORCE MAJEURE
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    Seller shall not be held responsible for any failure of performance to make timely delivery of all or any part of the Goods in the event such failure was due, in whole or in part, to federal, provincial or municipal action, statute, ordinance or regulation, strike or other labor trouble, fire or other damage to or destruction of, in whole or in part, the Goods or the manufacturing facility for the Goods, the lack of or inability to obtain raw materials, labor, fuel, electrical power, water or supplies, or any other cause, act of God, contingency or circumstances not subject to the reasonable control of Seller, which causes delays or hinders the manufacture or delivery of Goods. Seller shall determine in good faith the extent to which it can reasonably control a cause, contingency, or circumstance that affects the performance of its obligations.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        9. GENERAL
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    yer may not assign this Agreement without Seller's written consent. Seller is the sole intended beneficiary of this Agreement. If there is any inconsistency between this Agreement and any other agreement included with or relating to the Goods, this Agreement shall govern. This Agreement may not be modified, altered or amended without the written agreement of Seller. Any additional or altered terms attached to any order submitted by Buyer shall be null and void, unless expressly agreed to in writing by Seller. If any term of this Agreement is illegal or unenforceable, the legality and enforceability of the remaining provisions shall not be affected or impaired. This Agreement shall be interpreted under the laws of the Chhattisgarh, INDIA, without giving effect to conflicts-of-law rules; and in the event of a dispute under this Agreement; Buyer submits to the exclusive jurisdiction and venue of the jurisdiction of Sarangarh and hereby waives any objection to such jurisdiction and venue.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    
                    <Row className="justify_content_center homfinmgmtsubhead mt-4">
                    WEB SITE TERMS AND CONDITIONS OF USE
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        1. TERMS
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        2. USE LICENSE
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    Permission is granted to temporarily download one copy of the photo on Aaradhya's web site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:  modify or copy the images; use the images for any commercial purpose, or for any public display (commercial or non-commercial); attempt to decompile or reverse engineer any images contained on Aaradhya's web site; remove any copyright or other proprietary notations from the images; or transfer the images to another person or "mirror" the images on any other server. If you violate any of these restrictions Aaradhya has full rights to take legal action against you.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        3. DISCLAIMER
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    The materials on Aaradhya web site are provided "as is". Aaradhya makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further,Aaradhya does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        4. LIMITATIONS
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    In no event shall Aaradhya or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on Aaradhya's Internet site, even if Aaradhya or a Aaradhya authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        5. REVISIONS AND ERRATA
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    The materials appearing on Aaradhya's web site could include technical, typographical, or photographic errors. Aaradhya does not warrant that any of the materials on its web site are accurate, complete, or current. Aaradhya may make changes to the materials contained on its web site at any time without notice. Aaradhya does not, however, make any commitment to update the materials.
                    </Row>
                    
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        6. LINKS
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    Aaradhya has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Aaradhya of the site. Use of any such linked web site is at the user's own risk.
                    </Row>
                    
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        7. SITE TERMS OF USE MODIFICATIONS
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    Aaradhya may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        8. GOVERNING LAW
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    Any claim relating to Aaradhya's web site shall be governed by jurisdiction of Chhatiisgarh, India without regard to its conflict of law provisions. General Terms and Conditions applicable to Use of a Web Site.
                    </Row>
                    
                </Col>
                <Col xs={1}></Col>
            </Row>



            <Row className="footermargin  mt-5">
                <Footer />
            </Row>

        </div >
    )
}

export default Terms