import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Card, ListGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Url from "./Url";
import Navigation from "./Navprastype";
import Footer from "./Footer";
import { FaCheckCircle } from "react-icons/fa";

const Returnitem = () => {
    const navigate = useNavigate();
    const url1 = Url();
    const url = url1["url"];
    const s3 = url1["s3"];

    const [customer_name, setCustomer_name] = useState("")
    const [phone_no, setPhone_no] = useState("")
    const [customer_email, setCustomer_email] = useState("")
    const [order_id, setOrder_id] = useState("")
    const [courier_name, setCourier_name] = useState("")
    const [courier_date, setCourier_date] = useState("")
    const [courier_trackingid, setCourier_trackingid] = useState("")
    const [return_reason, setReturn_reason] = useState("")




    const returnItem = () => {

        const api = url + "createReturn_detail"
        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ customer_name, phone_no, customer_email, order_id, courier_name, courier_date, courier_trackingid, return_reason })
        })
            .then((apidata) => apidata.json())
            .then((actualdata) => {
                if (actualdata["message"] === "success") {
                    alert("Return request sent successfully")
                    window.location.reload()
                }

            });


    };

    return (
        <>
            <Row>
                <Navigation />
            </Row>

            <Row className="mt-5 mx-5">
                <Col sm={3}></Col>

                <Col >
                    <Card>
                        <Card.Header className="carthead hombannercardtext">
                            Return details
                        </Card.Header>
                        <Card.Body>
                            <Row className="justify_content_center returnitemaddresshead mt-1 ">
                                Return Address
                            </Row>
                            <Row className="justify_content_center returnitemaddresstext mt-1 ">
                                Mr. Rajesh Dewangan
                            </Row>
                            <Row className="justify_content_center returnitemaddresstext mt-1 ">
                                S/O Mr. Santram dewangan
                            </Row>
                            <Row className="justify_content_center returnitemaddresstext mt-0 ">
                                H.No. 36, Durga Mandir Road,
                            </Row>
                            <Row className="justify_content_center returnitemaddresstext mt-0 ">
                                Ward No. 08, Sarangarh
                            </Row>
                            <Row className="justify_content_center returnitemaddresstext mt-0 ">
                                Distt- Sarangarh- Bilaigarh, Chhattisgarh
                            </Row>
                            <Row className="justify_content_center returnitemaddresstext mt-0 ">
                                Pin Code: 496445
                            </Row>
                            <Row className="justify_content_center articlereturntext mt-3">
                                Note: If you need to return a product for any reason, kindly use any courier service of your choice to send it back to us. Please note that the shipping charges for the return will be your responsibility and must be paid at the time of dispatch.
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required onChange={(e) => setCustomer_name(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Name *</label>
                            </Row>

                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="number" pattern="[1-9]{1}[0-9]{9}" placeholder=" " required onChange={(e) => setPhone_no(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Mobile no *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="email" pattern="[1-9]{1}[0-9]{9}" placeholder=" " required onChange={(e) => setCustomer_email(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Email *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required onChange={(e) => setOrder_id(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Order Id *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required onChange={(e) => setCourier_name(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Courier company *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="date" placeholder=" " onChange={(e) => setCourier_date(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Courier date</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required onChange={(e) => setCourier_trackingid(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Courier Tracking Id *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <textarea className="floating-input" placeholder=" " required onChange={(e) => setReturn_reason(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Reason *</label>
                            </Row>
                            <Row className="justify_content_center">
                                <button className="homgreqmsgbtn mt-4" onClick={() => returnItem()}>

                                    Return
                                </button>
                            </Row>


                        </Card.Body>
                    </Card>
                </Col>


                <Col sm={3}></Col>
            </Row>

            <Row className="mt-5">
                <Footer />
            </Row>
        </>
    );
};

export default Returnitem;