import React, { useState, useEffect } from "react"
import { Row, Col, OverlayTrigger, Tooltip, Modal } from "react-bootstrap"
import Url from "./Url"
import Cropper from 'react-easy-crop';
import { getOrientation } from 'get-orientation/browser';
import { getCroppedImg, getRotatedImage } from './utils';
import Slider from '@material-ui/core/Slider';
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegEdit, FaEye } from "react-icons/fa";
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component";
import Navigation from "./Navadmin"
import { IoCloseSharp } from "react-icons/io5"
import loadinggif from "./Images/giphy.gif"
// import Leftvericalmenu from "./Leftverticalmenu"
// import department_vector from "./Images/department_vector.png"

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
};

const Articlepixelcrop = () => {

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    const usertoken = localStorage.getItem("usertoken")

    const [rukjlk, setRukjlk] = useState(false)

    /*--------------------------- Image cropper states---------------------------*/
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [originalFileName, setOriginalFileName] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [thumbShowModal, setThumbShowModal] = useState(false)
    const [aspectratio, setAspectratio] = useState(1 / 1)
    const [cropshape, setCropshape] = useState('rect')

    const [cropWidth, setCropWidth] = useState(300);
    const [cropHeight, setCropHeight] = useState(300);
    /*--------------------------- Image cropper states---------------------------*/


    //const [email, setEmail] = useState("")
    const [article_name, setarticle_name] = useState("")
    const [article_price, setArticle_price] = useState("")
    const [article_stock, setArticle_stock] = useState("")
    const [material, setMaterial] = useState("")
    const [article_Blouse, setArticle_Blouse] = useState("")
    const [article_Length, setArticle_Length] = useState("")
    const [article_wash, setArticle_wash] = useState("")
    const [article_remark, setArticle_remark] = useState("")
    const [article_fb, setArticle_fb] = useState("")
    const [article_twit, setArticle_twit] = useState("")
    const [article_pinint, setArticle_pinint] = useState("")
    const [article_id, setArticle_id] = useState("")
    const [category, setCategory] = useState([])
    const [getmaterial, setGetmaterial] = useState([])

    const [idset, setIdset] = useState("")
    const [article_Idset, setArticle_Idset] = useState("")

    const [getarticlebyid, setGetarticlebyid] = useState([])

    const [addarticlemodal, setAddarticlemodal] = useState(false)
    const [editarticlemodal, setEditarticlemodal] = useState(false)

    //const [searchcontent, setSearchcontent] = useState("")
    const [search, SetSearch] = useState('');
    const [filter, setFilter] = useState([]);


    const [data, setData] = useState([])

    const column = [
        {
            name: "Add Thumbnail",
            cell: (row) => (
                (row.article_Thumbnail === "NA" ?
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Thumbnail</Tooltip>}>
                        <button className="addphotobtn" onClick={() => handleThumbShowModal(row.id)}>+</button>
                    </OverlayTrigger>
                    :
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Change Thumbnail</Tooltip>}>
                        <button className="updatethumbbtn" onClick={() => handleThumbShowModal(row.id)}>+</button>
                    </OverlayTrigger>
                )
            )
        },
        {
            name: "Add Pic",
            cell: (row) => (
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Pic</Tooltip>}>
                    <button className="addphotobtn" onClick={() => handleShowModal(row.id, row.article_Id)}>+</button>
                </OverlayTrigger>
            )
        },
        {
            name: "Article name",
            selector: row => row.article_name,
            sortable: true
        },
        {
            name: "Article Id",
            selector: row => row.article_Id,
            sortable: true
        },
        {
            name: "Article Price",
            selector: row => row.article_price,
            sortable: true
        },
        {
            name: "Article stock",
            selector: row => row.article_stock,
            sortable: true
        },
        {
            name: "Material",
            selector: row => row.Material,
            sortable: true
        }
        ,
        {
            name: "Article blouse",
            selector: row => row.article_Blouse,
            sortable: true
        }
        ,
        {
            name: "article length",
            selector: row => row.article_Length,
            sortable: true
        }
        ,
        {
            name: "article wash",
            selector: row => row.article_wash,
            sortable: true
        }
        ,
        {
            name: "article remark",
            selector: row => row.article_remark,
            sortable: true
        }
        ,
        {
            name: "Facebook",
            selector: row => row.article_fb,
            sortable: true
        },
        ,
        {
            name: "Twitter",
            selector: row => row.article_twit,
            sortable: true
        },
        ,
        {
            name: "Pinterest",
            selector: row => row.article_pinint,
            sortable: true
        },
        {
            name: "View",
            cell: (row) => (
                <Link to={"/Viewarticleadmin/" + row.id} target="_blank">
                    <button className="btn btn-success" ><FaEye /></button>
                </Link>
            )
        },
        {
            name: "Edit",
            cell: (row) => (
                <button className="btn btn-primary" onClick={() => getarticledatabyid(row.id)}><FaRegEdit /></button>
            )
        }
        ,
        {
            name: "Delete",
            cell: (row) => (
                <button className="btn btn-danger" onClick={() => delarticle(row.id)}><RiDeleteBinLine /></button>
            )
        }
    ]

    useEffect(() => {
        const fetchCSRFToken = async () => {
            try {
                const response = await fetch('your-laravel-api-url/sanctum/csrf-cookie', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }

                // CSRF token is now set in cookies, and you're ready to make authenticated requests
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCSRFToken();
    }, []); // Run once on component mount

    useEffect(() => {
        const api = url + "getarticle_category"

        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Fail") {
                    setCategory(actualdata)
                }
            })
    }, [])

    useEffect(() => {
        const api = url + "getmaterial_master"

        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Fail") {
                    const processedData = actualdata.map(item => ({
                        ...item,
                        displayValue: `${item.material_type} - ${item.material_remark}`,
                        combinedValue: `${item.material_type} - ${item.material_remark}`
                    }));
                    setGetmaterial(processedData);
                    // setGetmaterial(actualdata)
                }
            })
    }, [])

    /*---------------------Add article --------------------- */

    const addarticle = e => {

        e.preventDefault()
        //console.log("article_remark= ", article_remark)
        var Material = material
        // var article_remark1 = "NA"

        // if (article_remark === "") {
        //     //alert("article_remark= ", article_remark)
        //     setArticle_remark("NA")
        // }        

        // if (article_fb === "") {
        //     //alert("article_fb= ", article_fb)
        //     article_fb = "NA"
        // }

        // if (article_pinint === "")
        //     article_pinint = "NA"

        // if (article_pinint === "")
        //     article_twit = "NA"

        // if (article_Thumbnail === "")
        const article_Thumbnail = "NA"

        const article_Id = article_id

        const api = url + "createArticle"
        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${usertoken}`, // Include the bearer token
            },
            body: JSON.stringify({ article_Thumbnail, article_name, article_Blouse, article_Length, article_price, article_remark, article_fb, article_pinint, article_twit, article_stock, article_wash, Material, article_Id })
        })
            .then((apidata) => {
                //console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                // alert("")
                if (actualdata["message"] === "success") {
                    alert("article created successfully")
                    setAddarticlemodal(false)
                    window.location.reload()
                }

            })
    }

    useEffect(() => {
        const api = url + "getArticle"

        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Fail") {
                    setData(actualdata)
                    setFilter(actualdata)
                }
            })
    }, [])

    const getarticledatabyid = (id) => {
        const api = url + "getArticleById/" + id
        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata1) => {
                return apidata1.json()
            })
            .then((actualdata1) => {
                //console.log(actualdata1)
                if (actualdata1["message"] !== "Fail") {
                    setGetarticlebyid(actualdata1)
                    setEditarticlemodal(true)
                }
            }
            )
    }

    const updatearticle = (id) => {

        const Material = material
        const article_Thumbnail = getarticlebyid.article_Thumbnail

        const article_Id = article_id

        const api = url + "updateArticle/" + id

        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${usertoken}`, // Include the bearer token
            },
            body: JSON.stringify({ article_Thumbnail, article_name, article_Blouse, article_Length, article_price, article_remark, article_fb, article_pinint, article_twit, article_stock, article_wash, Material, article_Id })
        })
            .then((apidata) => {
                //console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata["message"] === "success") {
                    alert("article Updated successfully")
                    setEditarticlemodal(false)
                    window.location.reload()
                }

            })
    }

    const delarticle = (id) => {

        const api = url + "delArticleById/" + id

        var res = window.confirm("You want to delete this article, are you sure?")
        if (res) {
            fetch(api, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${usertoken}`, // Include the bearer token
                },
            })
                .then((actualdata) => {
                    return actualdata.json()
                })
                .then((actualdata1) => {
                    //console.log(actualdata1)
                    if (actualdata1["message"] === "success") {
                        alert("Article Deleted")
                        window.location.reload()
                    }
                })
        }
    }

    useEffect(() => {
        const result = data.filter((item) => {
            return item.article_name.toLowerCase().match(search.toLocaleLowerCase());
        });
        setFilter(result);
    }, [search]);


    const tableHeaderstyle = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                backgroundColor: "#ccc"

            },
        },
    }

    /*--------------------------- Image cropper ---------------------------*/

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);

            try {
                const orientation = await getOrientation(file);
                const rotation = ORIENTATION_TO_ANGLE[orientation];
                if (rotation) {
                    imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
                }
            } catch (e) {
                console.warn('failed to detect the orientation');
            }

            setImageSrc(imageDataUrl);
            setOriginalFileName(file.name);
        }
    };

    const handleShowModal = (id, article_Id) => {
        setIdset(id)
        setArticle_Idset(article_Id)
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setImageSrc(null)
        setShowModal(false);
        setAspectratio(1 / 1)
        setCropshape("rect")
    };


    const saveToS3 = async () => {

        setRukjlk(true)

        try {
            const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);

            if (!croppedImageBlob) {
                throw new Error('Failed to crop the image.');
            }

            const formData = new FormData();
            formData.append('pic', croppedImageBlob, originalFileName);

            fetch(url + 'articlePicUpload', {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${usertoken}`,
                }
            })
                .then((op) => op.json())
                .then((op2) => {
                    //console.log(op2);

                    const article_pic = op2["message"];
                    const article_id_1 = idset;
                    const article_id_2 = article_Idset;


                    const api = url + "articlePicData"
                    fetch(api, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${usertoken}`,
                        },
                        body: JSON.stringify({ article_pic, article_id_1, article_id_2 }),
                    })
                        .then((op) => op.json())
                        .then((op1) => {
                            if (op1["message"] === "success") {
                                alert("image uploaded successfully");
                                setRukjlk(false)
                            }
                        });
                })
                .catch(error => {
                    console.error('Error during image upload:', error);
                });

            handleCloseModal();
        } catch (error) {
            console.error('Error saving image to AWS S3:', error);
        }
    };

    /*--------------------------- Image cropper end ---------------------------*/

    /*--------------------------- Thumbnail Image cropper ---------------------------*/

    const onThumbCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const onThumbFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);

            try {
                const orientation = await getOrientation(file);
                const rotation = ORIENTATION_TO_ANGLE[orientation];
                if (rotation) {
                    imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
                }
            } catch (e) {
                console.warn('failed to detect the orientation');
            }

            setImageSrc(imageDataUrl);
            setOriginalFileName(file.name);
        }
    };

    const handleThumbShowModal = (id) => {
        setIdset(id)
        //setAddarticlemodal(false)
        setThumbShowModal(true);
    };

    const handleThumbCloseModal = () => {
        setImageSrc(null)
        setThumbShowModal(false);
        setAspectratio(1 / 1)
        setCropshape("rect")
    };


    const saveThumbToS3 = async () => {

        setRukjlk(true)

        try {
            const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);

            if (!croppedImageBlob) {
                throw new Error('Failed to crop the image.');
            }
            const id = idset;
            const formData = new FormData();
            formData.append('pic', croppedImageBlob, originalFileName);
            formData.append('id', JSON.stringify(id));

            fetch(url + 'article_ThumbnailUpload', {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${usertoken}`,
                }
            })
                .then((op) => op.json())
                .then((op2) => {
                    //console.log(op2);
                    if (op2["message"] === "File-uploaded") {
                        alert("image uploaded successfully");
                        setRukjlk(false)
                        window.location.reload()
                    }

                })
                .catch(error => {
                    console.error('Error during image upload:', error);
                });

            handleThumbCloseModal();
        } catch (error) {
            console.error('Error saving image to AWS S3:', error);
        }
    };

    /*--------------------------- Thumbnail cropper end ---------------------------*/

    /*--------------------------- Generate CSV ---------------------------*/
    // const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //     //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    //     var arrData =
    //         typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    //     var CSV = "";

    //     //This condition will generate the Label/Header
    //     if (ShowLabel) {
    //         var row = "";

    //         //This loop will extract the label from 1st index of on array
    //         for (var index in arrData[0]) {
    //             //Now convert each value to string and comma-seprated
    //             row += index + ",";
    //         }

    //         row = row.slice(0, -1);

    //         //append Label row with line break
    //         CSV += row + "\r\n";
    //     }

    //     //1st loop is to extract each row
    //     for (var i = 0; i < arrData.length; i++) {
    //         var row = "";

    //         //2nd loop will extract each column and convert it in string comma-seprated
    //         for (var index in arrData[i]) {
    //             row += '"' + arrData[i][index] + '",';
    //         }

    //         row.slice(0, row.length - 1);

    //         //add a line break after each row
    //         CSV += row + "\r\n";
    //     }

    //     if (CSV === "") {
    //         alert("Invalid data");
    //         return;
    //     }

    //     //Generate a file name
    //     var fileName = "";
    //     //this will remove the blank-spaces from the title and replace it with an underscore
    //     fileName += ReportTitle.replace(/ /g, "_");

    //     //Initialize file format you want csv or xls
    //     var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    //     // Now the little tricky part.
    //     // you can use either>> window.open(uri);
    //     // but this will not work in some browsers
    //     // or you will not get the correct file extension

    //     //this trick will generate a temp <a /> tag
    //     var link = document.createElement("a");
    //     link.href = uri;

    //     //set the visibility hidden so it will not effect on your web-layout
    //     link.style = "visibility:hidden";
    //     link.download = fileName + ".csv";

    //     //this part will append the anchor tag and remove it after automatic click
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };
    /*--------------------------- Generate CSV ---------------------------*/


    ///////////////Render from top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <Modal
                size="sm"
                show={rukjlk}
                centered
                aria-labelledby="example-modal-sizes-title-sm"
                className="modal-backdrop"
            >
                <Modal.Body>
                    <Row className="">
                        <img src={loadinggif} alt="" className="modalbgruk" />
                    </Row>
                    <Row className="Loginheading jstfy_all_cntr mt-2">
                        Please wait, uploading image!
                    </Row>
                </Modal.Body>
            </Modal>

            {/*-----------------Image crop modal --------------------*/}
            <Modal lg show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body closeButton>
                    {imageSrc ? (
                        <>

                            <div className="crop-container " style={{ height: 400, position: 'relative' }}>
                                <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    rotation={rotation}
                                    zoom={zoom}
                                    // aspect={aspectratio}
                                    // aspect={16 / 9}
                                    aspect={cropWidth / cropHeight}
                                    cropShape={cropshape}
                                    onCropChange={setCrop}
                                    onRotationChange={setRotation}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>
                            <Row className="controls justify_content_center">
                                <Row>
                                    <Col className="slider-container">
                                        <Typography variant="overline">
                                            Zoom
                                        </Typography>
                                        <Slider
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aria-labelledby="Zoom"
                                            className="slider"
                                            onChange={(e, zoom) => setZoom(zoom)}
                                        />
                                    </Col>
                                    <Col className="slider-container">
                                        <Typography variant="overline">
                                            Rotation
                                        </Typography>
                                        <Slider
                                            value={rotation}
                                            min={0}
                                            max={360}
                                            step={1}
                                            aria-labelledby="Rotation"
                                            className="slider"
                                            onChange={(e, rotation) => setRotation(rotation)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="justify_content_center aspecthead mt-1">
                                    Aspect Ratio
                                </Row>
                                <Row className="justify_content_center mt-3">
                                    <Col>
                                        <Typography variant="overline">
                                            Custom Crop Size (px)
                                        </Typography>
                                        <div className="d-flex">
                                            <input
                                                type="number"
                                                value={cropWidth}
                                                onChange={(e) => setCropWidth(Number(e.target.value))}
                                                placeholder="Width"
                                                className="form-control mr-2"
                                            />
                                            <input
                                                type="number"
                                                value={cropHeight}
                                                onChange={(e) => setCropHeight(Number(e.target.value))}
                                                placeholder="Height"
                                                className="form-control"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row className='mt-1'>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(16 / 9)} className="aspectbutton">16/9</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(9 / 16)} className="aspectbutton">9/16</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(4 / 3)} className="aspectbutton">4/3</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(1 / 1)} className="aspectbutton">1/1</button>
                                    </Col>
                                </Row> */}
                                {/* <Row className="justify_content_center aspecthead mt-3">
                                    Crop shape
                                </Row>
                                <Row className="justify_content_center  mt-1">
                                    <Col></Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => {
                                            setCropshape("round")
                                            setAspectratio(1 / 1)
                                        }} className="aspectbutton">Round</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => {
                                            setCropshape("rect")
                                            setAspectratio(9 / 16)
                                        }} className="aspectbutton">Box</button>
                                    </Col>
                                    <Col></Col>
                                </Row> */}
                                <hr className="mt-4 " />
                                <Row className="justify_content_center mt-0">
                                    <button
                                        onClick={saveToS3}
                                        variant="contained"

                                        className=" cropsavbtn"
                                    >
                                        Upload
                                    </button>
                                </Row>
                            </Row>
                        </>
                    ) : (
                        <input type="file" onChange={onFileChange} accept="image/*" />
                    )}
                </Modal.Body>
            </Modal>
            {/*-----------------Image crop modal end --------------------*/}

            {/*-----------------Thumb Image crop modal --------------------*/}
            <Modal lg show={thumbShowModal} onHide={handleThumbCloseModal}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body closeButton>
                    {imageSrc ? (
                        <>

                            <div className="crop-container ">
                                <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    rotation={rotation}
                                    zoom={zoom}
                                    // aspect={aspectratio}
                                    // aspect={16 / 9}
                                    aspect={cropWidth / cropHeight}
                                    cropShape={cropshape}
                                    onCropChange={setCrop}
                                    onRotationChange={setRotation}
                                    onCropComplete={onThumbCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>
                            <Row className="controls justify_content_center">
                                <Row>
                                    <Col className="slider-container">
                                        <Typography variant="overline">
                                            Zoom
                                        </Typography>
                                        <Slider
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aria-labelledby="Zoom"
                                            className="slider"
                                            onChange={(e, zoom) => setZoom(zoom)}
                                        />
                                    </Col>
                                    <Col className="slider-container">
                                        <Typography variant="overline">
                                            Rotation
                                        </Typography>
                                        <Slider
                                            value={rotation}
                                            min={0}
                                            max={360}
                                            step={1}
                                            aria-labelledby="Rotation"
                                            className="slider"
                                            onChange={(e, rotation) => setRotation(rotation)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="justify_content_center aspecthead mt-1">
                                    Aspect Ratio
                                </Row>
                                <Row className="justify_content_center mt-3">
                                    <Col>
                                        <Typography variant="overline">
                                            Custom Crop Size (px)
                                        </Typography>
                                        <div className="d-flex">
                                            <input
                                                type="number"
                                                value={cropWidth}
                                                onChange={(e) => setCropWidth(Number(e.target.value))}
                                                placeholder="Width"
                                                className="form-control mr-2"
                                            />
                                            <input
                                                type="number"
                                                value={cropHeight}
                                                onChange={(e) => setCropHeight(Number(e.target.value))}
                                                placeholder="Height"
                                                className="form-control"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row className='mt-1'>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(16 / 9)} className="aspectbutton">16/9</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(9 / 16)} className="aspectbutton">9/16</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(4 / 3)} className="aspectbutton">4/3</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(1 / 1)} className="aspectbutton">1/1</button>
                                    </Col>
                                </Row> */}
                                {/* <Row className="justify_content_center aspecthead mt-3">
                                    Crop shape
                                </Row>
                                <Row className="justify_content_center  mt-1">
                                    <Col></Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => {
                                            setCropshape("round")
                                            setAspectratio(1 / 1)
                                        }} className="aspectbutton">Round</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => {
                                            setCropshape("rect")
                                            setAspectratio(9 / 16)
                                        }} className="aspectbutton">Box</button>
                                    </Col>
                                    <Col></Col>
                                </Row> */}
                                <hr className="mt-4 " />
                                <Row className="justify_content_center mt-0">
                                    <button
                                        onClick={saveThumbToS3}
                                        variant="contained"

                                        className=" cropsavbtn"
                                    >
                                        Upload
                                    </button>
                                </Row>
                            </Row>
                        </>
                    ) : (
                        <input type="file" onChange={onThumbFileChange} accept="image/*" />
                    )}
                </Modal.Body>
            </Modal>
            {/*-----------------Thumb Image crop modal end --------------------*/}

            {/*-----------------Add article modal--------------------*/}
            <Modal
                show={addarticlemodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalbg"
            >
                <Row className="justify_content_center">
                    <Col className="appointmodalhead"></Col>
                    <Col xs={1} className="appointmodalhead"
                        onClick={() => { setAddarticlemodal(false) }}
                        style={{ cursor: "pointer", marginLeft: "-100px" }}
                    >
                        <IoCloseSharp />
                    </Col>
                </Row>
                <Row className="deptmodalhead">
                    <Col>Add New article</Col>
                </Row>
                <Modal.Body>

                    <Row>

                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article Name*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setarticle_name(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article Id*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Price" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setArticle_id(e.target.value) }}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article price*
                            </Row>
                            <Row >
                                <input type="number" placeholder="Enter Price" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setArticle_price(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article stock*
                            </Row>
                            <Row >
                                <input type="number" placeholder="Enter Stock" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setArticle_stock(e.target.value) }}
                                />
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Material*
                            </Row>
                            <Row >
                                <select className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setMaterial(e.target.value) }}
                                >
                                    <option value="">Select article material</option>
                                    {
                                        getmaterial.length > 0 ?
                                            getmaterial.map(item => (
                                                <option key={item.id} value={item.combinedValue}>
                                                    {item.displayValue}
                                                </option>
                                                // <option value={item.material_type}>{item.material_type}</option>
                                            ))
                                            :
                                            null
                                    }
                                    {/* <option value="Pure silk">Pure silk</option>
                                    <option value="Tussar silk">Tussar silk</option>
                                    <option value="Art silk">Art silk</option>
                                    <option value="Semi silk">Semi silk</option> */}
                                </select>
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article Blouse*
                            </Row>
                            <Row >
                                <select className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setArticle_Blouse(e.target.value) }}
                                >
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article Length*
                            </Row>
                            <Row >
                                <input type="number" placeholder="Enter Length" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setArticle_Length(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article wash*
                            </Row>
                            <Row >
                                <select className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setArticle_wash(e.target.value) }}
                                >
                                    <option value="">Select</option>
                                    <option value="Hand wash">Hand wash</option>
                                    <option value="Machine wash">Machine wash</option>
                                    <option value="Dry wash">Dry Clean</option>
                                </select>
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article Category*
                            </Row>
                            <Row >
                                <select className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setArticle_pinint(e.target.value) }}
                                >
                                    <option value="">Select</option>
                                    {
                                        category.length > 0 ?
                                            category.map(item => (
                                                <option value={item.article_category_name}>{item.article_category_name}</option>
                                            ))
                                            :
                                            null
                                    }
                                </select>

                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Facebook
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Facebook Link" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setArticle_fb(e.target.value) }}
                                />
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Twitter
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Twitter Link" className="createdeptmodalinput"

                                    onChange={(e) => { setArticle_twit(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article remark
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Remarks" className="createdeptmodalinput"

                                    onChange={(e) => { setArticle_remark(e.target.value) }}
                                />
                            </Row>

                        </Col>
                        {/* <Col sm className="appointcentercols">

                        </Col> */}
                    </Row>
                    <Row className="mt-4 mb-3">
                        <Col className="justify_content_center">
                            <button className="serachdeptbtn"
                                onClick={addarticle}
                            >Create</button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            {/*-----------------Add article modal end--------------------*/}

            {/*-----------------Edit article modal--------------------*/}
            <Modal
                show={editarticlemodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalbg"
            >
                <Row className="justify_content_center">
                    <Col className="appointmodalhead"></Col>
                    <Col xs={1} className="appointmodalhead"
                        onClick={() => { setEditarticlemodal(false) }}
                        style={{ cursor: "pointer", marginLeft: "-100px" }}
                    >
                        <IoCloseSharp />
                    </Col>
                </Row>
                <Row className="deptmodalhead">
                    <Col>Edit New article</Col>
                </Row>
                <Modal.Body>

                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article Name*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_name}
                                    onChange={(e) => { setarticle_name(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article ID*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter " className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_Id}
                                    onChange={(e) => { setArticle_id(e.target.value) }}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article price*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter " className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_price}
                                    onChange={(e) => { setArticle_price(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article stock*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter " className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_stock}
                                    onChange={(e) => { setArticle_stock(e.target.value) }}
                                />
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Material*
                            </Row>
                            <Row >
                                <select className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.Material}
                                    onChange={(e) => { setMaterial(e.target.value) }}
                                >
                                    <option value="">Select article material</option>
                                    {
                                        getmaterial.length > 0 ?
                                            getmaterial.map(item => (
                                                <option value={item.material_type}>{item.material_type}</option>
                                            ))
                                            :
                                            null
                                    }
                                    {/* <option value="">Select article material</option>
                                    <option value="Pure silk">Pure silk</option>
                                    <option value="Tussar silk">Tussar silk</option>
                                    <option value="Art silk">Art silk</option>
                                    <option value="Semi silk">Semi silk</option> */}
                                </select>
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article Blouse*
                            </Row>
                            <Row >
                                <select className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_Blouse}
                                    onChange={(e) => { setArticle_Blouse(e.target.value) }}
                                >
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article Length*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter " className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_Length}
                                    onChange={(e) => { setArticle_Length(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article wash*
                            </Row>
                            <Row >
                                <select className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_wash}
                                    onChange={(e) => { setArticle_wash(e.target.value) }}
                                >
                                    <option value="">Select</option>
                                    <option value="Hand wash">Hand wash</option>
                                    <option value="Machine wash">Machine wash</option>
                                    <option value="Dry wash">Dry wash</option>
                                </select>
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article Category*
                            </Row>
                            <Row >
                                <select className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setArticle_pinint(e.target.value) }}
                                    defaultValue={getarticlebyid.article_pinint}
                                >
                                    <option value="">Select</option>
                                    {
                                        category.length > 0 ?
                                            category.map(item => (
                                                <option value={item.article_category_name}>{item.article_category_name}</option>
                                            ))
                                            :
                                            null
                                    }
                                </select>
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Facebook*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter " className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_fb}
                                    onChange={(e) => { setArticle_fb(e.target.value) }}
                                />
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Twitter*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter " className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_twit}
                                    onChange={(e) => { setArticle_twit(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article remark*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter " className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_remark}
                                    onChange={(e) => { setArticle_remark(e.target.value) }}
                                />
                            </Row>

                        </Col>

                    </Row>

                    <Row className="mt-4 mb-3">
                        <Col className="justify_content_center">
                            <button className="serachdeptbtn"
                                onClick={() => { updatearticle(getarticlebyid.id) }}
                            >Update</button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            {/*-----------------Edit article modal end--------------------*/}



            <Row className="">
                {/* <Col sm={1}>
                    <Leftvericalmenu />
                </Col> */}
                <Col>
                    <Row><Navigation /></Row>
                    <Row className="appointmentcard mt-2 ">

                        <Row className="appointcenterhead mt-3">

                            <Col sm={1} className="justify_content_left">
                                <Link to="/Dashboardadmin">Dashboard {">"}</Link>
                            </Col>

                            <Col className="justify_content_left">Article</Col>
                            <Col className="justify_content_right">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add article</Tooltip>}>
                                    <span className="d-inline-block">
                                        <button className="homgreqmsgbtn"
                                            onClick={() => { setAddarticlemodal(true) }}
                                        >
                                            Add article
                                            {/* <BiPlusMedical /> */}
                                        </button>
                                    </span>
                                </OverlayTrigger>
                            </Col>
                        </Row>

                        <Row>

                            <Col className="appointcentercols jus">
                                {/*<Row className="deptinputhead mt-5">
                                    <Col>Department Name</Col>
    </Row>*/}

                                <Row className=" mt-3">
                                    {/* <Col>
                                        <input type="text" className="createdeptinput" placeholder="Search article"
                                        //onChange={handleFilter}

                                        />
                                    </Col>
                                    <Col>
                                        <button className="serachdeptbtn"

                                        >Search</button>
                                    </Col> */}
                                    {/* <Col>
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add New article</Tooltip>}>
                                            <span className="d-inline-block">
                                                <button className="adddeptbtn"
                                                    onClick={() => { setAddarticlemodal(true) }}
                                                >
                                                    Add new article
                                                    
                                                </button>
                                            </span>
                                        </OverlayTrigger>
                                    </Col> */}
                                </Row>
                            </Col>

                            <Col sm={5} className="justify_content_center">
                                {/* <img src={department_vector} alt="" className="deptvector" /> */}
                            </Col>
                        </Row>
                        { /*<Row className="mt-4 mb-3">
                            <Col className="jstfy_all_cntr">
                                <button className="appointmentcreatebtn"
                                    onClick={createDepartment}
                                >Create</button>
                            </Col>
    </Row>*/}

                        {/* <Row className="deptmodalhead">
                            <Col>Add New article</Col>
                        </Row>


                        <Row>
                            <Col sm={1}></Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Name*
                                </Row>
                                <Row >
                                    <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setName(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Email Id*
                                </Row>
                                <Row >
                                    <input type="email" placeholder="Enter Email" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setEmail(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                        <Row>
                            <Col sm={1}></Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Password*
                                </Row>
                                <Row >
                                    <input type="password" placeholder="Enter Password" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setPassword(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Mobile No.*
                                </Row>
                                <Row >
                                    <input type="text" placeholder="Enter mobile no" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setMobileno(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                        <Row>
                            <Col sm={1}></Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Address*
                                </Row>
                                <Row >
                                    <input type="text" placeholder="Enter Address" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setAddress(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Area*
                                </Row>
                                <Row >
                                    <input type="text" placeholder="Enter Area" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setArea(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                        <Row>
                            <Col sm={1}></Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Joining Date*
                                </Row>
                                <Row >
                                    <input type="date" placeholder="Enter Joing Date" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setJdate(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Remarks*
                                </Row>
                                <Row >
                                    <input type="text" placeholder="Enter Renarks" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setRemark(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                        <Row className="mt-4 mb-3">
                            <Col className="justify_content_center">
                                <button className="serachdeptbtn"
                                    onClick={addarticle}
                                >Create</button>
                            </Col>
                        </Row> */}

                        <Row className="depttablebox mt-5 mb-3">
                            <Row className="homereqmsghead justify_content_center">articles</Row>
                            <Row>
                                <DataTable
                                    customStyles={tableHeaderstyle}
                                    columns={column}
                                    data={filter}
                                    pagination
                                    //selectableRows
                                    fixedHeader
                                    // selectableRowsHighlight
                                    highlightOnHover
                                    // actions={
                                    //     <button className="btn btn-success"
                                    //         onClick={() => JSONToCSVConvertor(data, "_article List", true)}
                                    //     >Export Excel</button>
                                    // }
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text"
                                            className="w-100 form-control"
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(e) => SetSearch(e.target.value)}

                                        />
                                    }
                                    subHeaderAlign="left"

                                />
                            </Row>
                            {/* <div className="deptheadbg">
                                <Row>
                                    <DataTable
                                        columns={column}
                                        data={data}
                                        selectableRows
                                        fixedHeader
                                        pagination
                                    />
                                </Row>
                            </div> */}

                            {/* <div className="deptheadbg">
                                <Row>
                                    
                                    <Col  className="deptlisthead justify_content_center">S.No.</Col>
                                    <Col  className="deptlisthead justify_content_center">Name</Col>
                                    
                                    <Col  className="deptlisthead justify_content_center">Email</Col>
                                    <Col  className="deptlisthead justify_content_center">Mobile no</Col>
                                    <Col  className="deptlisthead justify_content_center">Area</Col>
                                    <Col  className="deptlisthead justify_content_center">Address</Col>
                                    <Col  className="deptlisthead justify_content_center">Joining Date</Col>
                                    <Col  className="deptlisthead justify_content_center">
                                        Edit
                                    </Col>
                                    <Col  className="deptlisthead justify_content_center">
                                        Delete
                                    </Col>
                                    
                                </Row>
                            </div> */}

                            {/* <Row className=" mt-1 mb-3">
                                {
                                    data.length ?
                                        data.filter((item) => {
                                            if (searchcontent === null) { return item }
                                            else if (item.article_name.toLowerCase().includes(searchcontent.toLowerCase())) { return item }{console.log(item)}
                                        })
                                            .map((item, srno) => (
                                                <Row className=" mt-1 mb-2" >
                                                    

                                                    <Col  className="deptlistitem  justify_content_center">{srno + 1}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.article_name}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.article_email}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.article_mob}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.article_area}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.article_address}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.article_joindt}</Col>
                                                    <Col  className="editicon justify_content_center">
                                                        <FaEdit className="deleteicon justify_content_center"
                                                        //onClick={() => { getDeptbyid(item.id) }}
                                                        />
                                                    </Col>
                                                    <Col  className="deleteicon justify_content_center">
                                                        <RiDeleteBinLine className="deleteicon justify_content_center"
                                                        //onClick={() => { delDept(item.id) }}
                                                        />
                                                    </Col>
                                                    
                                                </Row>
                                            ))
                                        :
                                        null
                                }
                            </Row> */}


                        </Row>
                    </Row>

                </Col>
            </Row>
        </>
    )
}

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}

export default Articlepixelcrop