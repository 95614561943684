import React, { useState, useEffect } from "react"
import { Row, Col, OverlayTrigger, Tooltip, Modal } from "react-bootstrap"
import Url from "./Url"
import Cropper from 'react-easy-crop';
import { getOrientation } from 'get-orientation/browser';
import { getCroppedImg, getRotatedImage } from './utils';
import Slider from '@material-ui/core/Slider';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component";
import Navigation from "./Navadmin"
import { IoCloseSharp } from "react-icons/io5"
import loadinggif from "./Images/giphy.gif"
// import Leftvericalmenu from "./Leftverticalmenu"
// import department_vector from "./Images/department_vector.png"

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
};

const Categorymasteractive = () => {

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    const usertoken = localStorage.getItem("usertoken")

    const [rukjlk, setRukjlk] = useState(false)

    /*--------------------------- Image cropper states---------------------------*/
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [originalFileName, setOriginalFileName] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [aspectratio, setAspectratio] = useState(1 / 1)
    const [cropshape, setCropshape] = useState('rect')
    const [cropWidth, setCropWidth] = useState(300);
    const [cropHeight, setCropHeight] = useState(300);
    /*--------------------------- Image cropper states---------------------------*/


    //const [email, setEmail] = useState("")
    const [article_category_name, setArticle_category_name] = useState("")
    const [category_img, setCategory_img] = useState(null)

    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const [showStatusModal, setShowStatusModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("");

    const [idset, setIdset] = useState("")
    const [article_Idset, setArticle_Idset] = useState("")

    const [getarticlebyid, setGetarticlebyid] = useState([])

    const [addarticlemodal, setAddarticlemodal] = useState(false)
    const [editarticlemodal, setEditarticlemodal] = useState(false)

    //const [searchcontent, setSearchcontent] = useState("")
    const [search, SetSearch] = useState('');
    const [filter, setFilter] = useState([]);
    const [data, setData] = useState([])

    const column = [
        {
            name: "Category name",
            selector: row => row.article_category_name,
            sortable: true
        },
        {
            name: "Pic",
            selector: (row) => <img src={s3url + row.article_category_image} height={70} width={70} />
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true
        },
        // {
        //     name: "Add Pic",
        //     cell: (row) => (
        //         <button className="addphotobtn" onClick={() => handleShowModal(row.id, row.article_Id)}>Add Pic</button>
        //     )
        // },
        // {
        //     name: "View",
        //     cell: (row) => (
        //         <Link to={"/Viewarticleadmin/" + row.id} target="_blank">
        //             <button className="btn btn-primary" >View</button>
        //         </Link>
        //     )
        // }
    ]

    useEffect(() => {
        const fetchCSRFToken = async () => {
            try {
                const response = await fetch('your-laravel-api-url/sanctum/csrf-cookie', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }

                // CSRF token is now set in cookies, and you're ready to make authenticated requests
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCSRFToken();
    }, []); // Run once on component mount

    useEffect(() => {
        const api = url + "getarticle_category"

        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Fail") {
                    setData(actualdata)
                    setFilter(actualdata)
                }
            })
    }, [])

    // Add checkbox column when showCheckboxes is true
    const getColumns = () => {
        if (showCheckboxes) {
            return [
                {
                    name: 'Select',
                    cell: (row) => (
                        <input
                            type="checkbox"
                            checked={selectedRows.includes(row.id)}
                            onChange={() => handleCheckboxChange(row.id)}
                            className="w-4 h-4"
                        />
                    ),
                    width: '100px'
                },
                ...column
            ];
        }
        return column;
    };

    // Handler for checkbox changes
    const handleCheckboxChange = (id) => {
        setSelectedRows(prev => {
            if (prev.includes(id)) {
                return prev.filter(rowId => rowId !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    // Handler for submit button
    // const handleSubmit = () => {
    //     const ids = selectedRows.join(',');
 
    //     //console.log('Selected IDs:', typeof(selectedIdsString));
    //     // Here you can add your API call or other logic to handle the selected IDs
    //     //alert(`Selected IDs: ${selectedRows.join(', ')}`);

    //     const status = "Yes"        

    //     //console.log(ids)
    //     //return
    //     const api = url + "updateArticleCategoryStatus"
    //     fetch(api, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${usertoken}`, // Include the bearer token
    //         },
    //         body: JSON.stringify({ ids, status })
    //     })
    //         .then((apidata) => {
    //             //console.log(apidata)
    //             return apidata.json()
    //         })
    //         .then((actualdata) => {
    //             if (actualdata.message === "Status_updated_successfully") {
    //                 alert("Category updated successfully")
    //                 window.location.reload()
    //             }

    //         })
    // };

    const handleSubmit = () => {
        setShowStatusModal(true);
    };

    const handleStatusUpdate = (status) => {
        const ids = selectedRows.join(',');

        const api = url + "updateArticleCategoryStatus";
        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${usertoken}`,
            },
            body: JSON.stringify({ ids, status })
        })
            .then((apidata) => {
                return apidata.json();
            })
            .then((actualdata) => {
                if (actualdata.message === "Status_updated_successfully") {
                    alert("Category updated successfully");
                    window.location.reload();
                }
            });
        
        setShowStatusModal(false);
    };


    // Toggle checkboxes visibility
    const toggleCheckboxes = () => {
        setShowCheckboxes(!showCheckboxes);
        setSelectedRows([]); // Clear selections when toggling
    };

   

    useEffect(() => {
        const result = data.filter((item) => {
            return item.article_name.toLowerCase().match(search.toLocaleLowerCase());
        });
        setFilter(result);
    }, [search]);


    const tableHeaderstyle = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                backgroundColor: "#ccc"

            },
        },
    }

    /*--------------------------- Image cropper ---------------------------*/

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const onFileChange = async (e) => {
        setAddarticlemodal(false)
        // console.log(e.target.files[0])

        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);

            try {
                const orientation = await getOrientation(file);
                const rotation = ORIENTATION_TO_ANGLE[orientation];
                if (rotation) {
                    imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
                }
            } catch (e) {
                console.warn('failed to detect the orientation');
            }

            setCategory_img(imageDataUrl);
            setOriginalFileName(file.name);

            handleShowModal()
        }
    };

    const handleShowModal = () => {
        //console.log(category_img)
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setCategory_img(null)
        setShowModal(false);
        setAspectratio(1 / 1)
        setCropshape("rect")
    };


    const saveToS3 = async () => {

        setRukjlk(true)

        try {
            const croppedImageBlob = await getCroppedImg(category_img, croppedAreaPixels, rotation);

            if (!croppedImageBlob) {
                throw new Error('Failed to crop the image.');
            }

            const formData = new FormData();
            formData.append('article_category_image', croppedImageBlob, originalFileName);

            fetch(url + 'articlecategoryPicUpload', {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${usertoken}`,
                }
            })
                .then((op) => op.json())
                .then((op2) => {
                    //console.log(op2);

                    const article_category_image = op2["message"];

                    const api = url + "createarticle_category"
                    fetch(api, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${usertoken}`, // Include the bearer token
                        },
                        body: JSON.stringify({ article_category_name, article_category_image })
                    })
                        .then((apidata) => {
                            //console.log(apidata)
                            return apidata.json()
                        })
                        .then((actualdata) => {
                            if (actualdata.message === "success") {
                                alert("Category created successfully")
                                setRukjlk(false)
                                window.location.reload()
                            }

                        })
                })
                .catch(error => {
                    console.error('Error during image upload:', error);
                });

            handleCloseModal();
        } catch (error) {
            console.error('Error saving image to AWS S3:', error);
        }
    };

    /*--------------------------- Image cropper end ---------------------------*/

    /*--------------------------- Generate CSV ---------------------------*/
    // const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //     //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    //     var arrData =
    //         typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    //     var CSV = "";

    //     //This condition will generate the Label/Header
    //     if (ShowLabel) {
    //         var row = "";

    //         //This loop will extract the label from 1st index of on array
    //         for (var index in arrData[0]) {
    //             //Now convert each value to string and comma-seprated
    //             row += index + ",";
    //         }

    //         row = row.slice(0, -1);

    //         //append Label row with line break
    //         CSV += row + "\r\n";
    //     }

    //     //1st loop is to extract each row
    //     for (var i = 0; i < arrData.length; i++) {
    //         var row = "";

    //         //2nd loop will extract each column and convert it in string comma-seprated
    //         for (var index in arrData[i]) {
    //             row += '"' + arrData[i][index] + '",';
    //         }

    //         row.slice(0, row.length - 1);

    //         //add a line break after each row
    //         CSV += row + "\r\n";
    //     }

    //     if (CSV === "") {
    //         alert("Invalid data");
    //         return;
    //     }

    //     //Generate a file name
    //     var fileName = "";
    //     //this will remove the blank-spaces from the title and replace it with an underscore
    //     fileName += ReportTitle.replace(/ /g, "_");

    //     //Initialize file format you want csv or xls
    //     var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    //     // Now the little tricky part.
    //     // you can use either>> window.open(uri);
    //     // but this will not work in some browsers
    //     // or you will not get the correct file extension

    //     //this trick will generate a temp <a /> tag
    //     var link = document.createElement("a");
    //     link.href = uri;

    //     //set the visibility hidden so it will not effect on your web-layout
    //     link.style = "visibility:hidden";
    //     link.download = fileName + ".csv";

    //     //this part will append the anchor tag and remove it after automatic click
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };
    /*--------------------------- Generate CSV ---------------------------*/


    ///////////////Render from top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <Modal
                size="sm"
                show={rukjlk}
                centered
                aria-labelledby="example-modal-sizes-title-sm"
                className="modal-backdrop"
            >
                <Modal.Body>
                    <Row className="">
                        <img src={loadinggif} alt="" className="modalbgruk" />
                    </Row>
                    <Row className="Loginheading jstfy_all_cntr mt-2">
                        Please wait, uploading image!
                    </Row>
                </Modal.Body>
            </Modal>

            {/* Status Selection Modal */}
            <Modal
                show={showStatusModal}
                onHide={() => setShowStatusModal(false)}
                centered
                size="sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-around gap-3 p-3">
                        <button 
                            className="btn btn-success"
                            onClick={() => handleStatusUpdate("Yes")}
                        >
                            Yes
                        </button>
                        <button 
                            className="btn btn-danger"
                            onClick={() => handleStatusUpdate("No")}
                        >
                            No
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            {/*-----------------Image crop modal --------------------*/}
            <Modal lg show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body closeButton>
                    {category_img ? (
                        <>

                            <div className="crop-container ">
                                <Cropper
                                    image={category_img}
                                    crop={crop}
                                    rotation={rotation}
                                    zoom={zoom}
                                    // aspect={aspectratio}
                                    //aspect={16 / 9}
                                    aspect={cropWidth / cropHeight}
                                    cropShape={cropshape}
                                    onCropChange={setCrop}
                                    onRotationChange={setRotation}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>
                            <Row className="controls justify_content_center">
                                <Row>
                                    <Col className="slider-container">
                                        <Typography variant="overline">
                                            Zoom
                                        </Typography>
                                        <Slider
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aria-labelledby="Zoom"
                                            className="slider"
                                            onChange={(e, zoom) => setZoom(zoom)}
                                        />
                                    </Col>
                                    <Col className="slider-container">
                                        <Typography variant="overline">
                                            Rotation
                                        </Typography>
                                        <Slider
                                            value={rotation}
                                            min={0}
                                            max={360}
                                            step={1}
                                            aria-labelledby="Rotation"
                                            className="slider"
                                            onChange={(e, rotation) => setRotation(rotation)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="justify_content_center aspecthead mt-1">
                                    Aspect Ratio
                                </Row>
                                <Row className="justify_content_center mt-3">
                                    <Col>
                                        <Typography variant="overline">
                                            Custom Crop Size (px)
                                        </Typography>
                                        <div className="d-flex">
                                            <input
                                                type="number"
                                                value={cropWidth}
                                                onChange={(e) => setCropWidth(Number(e.target.value))}
                                                placeholder="Width"
                                                className="form-control mr-2"
                                            />
                                            <input
                                                type="number"
                                                value={cropHeight}
                                                onChange={(e) => setCropHeight(Number(e.target.value))}
                                                placeholder="Height"
                                                className="form-control"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row className='mt-1'>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(16 / 9)} className="aspectbutton">16/9</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(9 / 16)} className="aspectbutton">9/16</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(4 / 3)} className="aspectbutton">4/3</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => setAspectratio(1 / 1)} className="aspectbutton">1/1</button>
                                    </Col>
                                </Row> */}
                                {/* <Row className="justify_content_center aspecthead mt-3">
                                    Crop shape
                                </Row>
                                <Row className="justify_content_center  mt-1">
                                    <Col></Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => {
                                            setCropshape("round")
                                            setAspectratio(1 / 1)
                                        }} className="aspectbutton">Round</button>
                                    </Col>
                                    <Col className="justify_content_center">
                                        <button onClick={() => {
                                            setCropshape("rect")
                                            setAspectratio(9 / 16)
                                        }} className="aspectbutton">Box</button>
                                    </Col>
                                    <Col></Col>
                                </Row> */}
                                <hr className="mt-4 " />
                                <Row className="justify_content_center mt-0">
                                    <button
                                        onClick={saveToS3}
                                        variant="contained"

                                        className=" cropsavbtn"
                                    >
                                        Upload
                                    </button>
                                </Row>
                            </Row>
                        </>
                    ) : null}
                </Modal.Body>
            </Modal>
            {/*-----------------Image crop modal end --------------------*/}

            {/*-----------------Add article modal--------------------*/}
            <Modal
                show={addarticlemodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalbg"
            >
                <Row className="justify_content_center">
                    <Col className="appointmodalhead"></Col>
                    <Col xs={1} className="appointmodalhead"
                        onClick={() => { setAddarticlemodal(false) }}
                        style={{ cursor: "pointer", marginLeft: "-100px" }}
                    >
                        <IoCloseSharp />
                    </Col>
                </Row>
                <Row className="deptmodalhead">
                    <Col>Add New article</Col>
                </Row>
                <Modal.Body>

                    <Row>

                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Category Name*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setArticle_category_name(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Category Pic*
                            </Row>
                            <Row >
                                <input type="file" required onChange={onFileChange} accept="image/*" />
                            </Row>
                        </Col>
                    </Row>
                    {/*                     
                    <Row className="mt-4 mb-3">
                        <Col className="justify_content_center">
                            <button className="serachdeptbtn"
                                onClick={addarticle}
                            >Create</button>
                        </Col>
                    </Row> */}

                </Modal.Body>
            </Modal>
            {/*-----------------Add article modal end--------------------*/}

            {/*-----------------Edit article modal--------------------*/}
            <Modal
                show={editarticlemodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalbg"
            >
                <Row className="justify_content_center">
                    <Col className="appointmodalhead"></Col>
                    <Col xs={1} className="appointmodalhead"
                        onClick={() => { setEditarticlemodal(false) }}
                        style={{ cursor: "pointer", marginLeft: "-100px" }}
                    >
                        <IoCloseSharp />
                    </Col>
                </Row>
                <Row className="deptmodalhead">
                    <Col>Edit New article</Col>
                </Row>
                <Modal.Body>

                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Article Name*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_category_name}
                                    onChange={(e) => { setArticle_category_name(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            {/* <Row className="appointsummarytype mt-2">
                                Article Pic*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter " className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.article_price}
                                    onChange={(e) => { setArticle_price(e.target.value) }}
                                />
                            </Row> */}
                        </Col>
                    </Row>

                    {/* 
                    <Row className="mt-4 mb-3">
                        <Col className="justify_content_center">
                            <button className="serachdeptbtn"
                                onClick={() => { updatearticle(getarticlebyid.id) }}
                            >Update</button>
                        </Col>
                    </Row> */}

                </Modal.Body>
            </Modal>
            {/*-----------------Edit article modal end--------------------*/}



            <Row className="">
                {/* <Col sm={1}>
                    <Leftvericalmenu />
                </Col> */}
                <Col>
                    <Row><Navigation /></Row>
                    <Row className="appointmentcard mt-2 ">

                        <Row className="appointcenterhead mt-3">

                            <Col sm={1} className="justify_content_left">
                                <Link to="/Dashboardadmin">Dashboard {">"}</Link>
                            </Col>

                            <Col className="justify_content_left">Article Category</Col>
                            <Col className="justify_content_right">
                                <div className="d-flex gap-2 justify-content-end">
                                    <button
                                        className="btn btn-primary"
                                        onClick={toggleCheckboxes}
                                    >
                                        {showCheckboxes ? 'Cancel' : 'Activate'}
                                    </button>

                                    {showCheckboxes && (
                                        <button
                                            className="btn btn-success"
                                            onClick={handleSubmit}
                                            disabled={selectedRows.length === 0}
                                        >
                                            Submit ({selectedRows.length})
                                        </button>
                                    )}

                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Category</Tooltip>}>
                                        <span className="d-inline-block">
                                            <button
                                                className="homgreqmsgbtn"
                                                onClick={() => { setAddarticlemodal(true) }}
                                            >
                                                Add Category
                                            </button>
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </Col>
                        </Row>

                        <Row>

                            <Col className="appointcentercols jus">

                            </Col>

                            <Col sm={5} className="justify_content_center">
                                {/* <img src={department_vector} alt="" className="deptvector" /> */}
                            </Col>
                        </Row>

                        <Row className="depttablebox mt-5 mb-3">
                            <Row className="homereqmsghead justify_content_center">Category</Row>
                            <Row>
                                <DataTable
                                    customStyles={tableHeaderstyle}
                                    columns={getColumns()}
                                    data={filter}
                                    pagination
                                    //selectableRows
                                    fixedHeader
                                    // selectableRowsHighlight
                                    highlightOnHover
                                    // actions={
                                    //     <button className="btn btn-success"
                                    //         onClick={() => JSONToCSVConvertor(data, "_article List", true)}
                                    //     >Export Excel</button>
                                    // }
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text"
                                            className="w-100 form-control"
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(e) => SetSearch(e.target.value)}
                                        />
                                    }
                                    subHeaderAlign="left"
                                />
                            </Row>

                        </Row>
                    </Row>

                </Col>
            </Row>
        </>
    )
}

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}


export default Categorymasteractive