import React, { useEffect } from 'react'
import Footer from "./Footer"
// import Navigation from "./Navigationnew";
import Navigation from "./Navprastype"
import { Row, Col } from "react-bootstrap"


const Returnpolicy = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="justify_content_center homfinmgmthead ">
                        RETURN/REFUND POLICY
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-4">
                        1. An order is eligible for return within 3 days from the date of delivery.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        2. Return / Cancellation is not accepted for orders with custom tailoring including fall & edging services.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        3. Customers can initiate a return request on the following circumstances
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-3">
                <Col xs={1}></Col>
                <Col xs>
                    <Col className="homfinmgmthead justify_content_center mt-2">Return Reason</Col>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Item is damage / defective
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Incorrect item delivered
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Colour different from description
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        I do not like this material
                    </Row>
                </Col>
                <Col xs>
                    <Col className="homfinmgmthead justify_content_center mt-2">Resolution</Col>
                    <Row className="justify_content_center returnpolicytext mt-2">
                        Refund
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Refund
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Refund
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Refund
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-3">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="justify_content_center returnpolicytext mt-4">
                        4. Customer has to raise a return request by sending an email to aaradhyhandcraft@gmail.com with the order details & the return reason. Incase of damaged / incorrect item a photograph of the product, the price tag & the packing cover which got delivered is mandatory.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        5. Our support team will analyse the return request and approve the same.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        6. Once a return is approved, the item has to be packed & shipped to the below address. ATTN: Mr. Rajesh Dewangan, H.No. 36, Durga Mandir Road, Ward No. 08, Sarangarh, Sarangarh Bilaigarh, Chhattisgarh, 496445 | PH - +91 74896 15360
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        7. The products must be returned in it's original condition, unaltered and unused. The price tag ( and the silk mark tag if applicable ) has to be present. The saree folding has to be intact.
                    </Row>
                    {/* <Row className="justify_content_center returnpolicytext mt-3">
                        8. Reverse pick up is available for damaged / defective items / incorrect item for selected Pin codes.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        9. Onward & Return logistic cost of Rs. 75/- for each item will be charged on free shipping orders.
                    </Row> */}
                    <Row className="justify_content_center returnpolicytext mt-3">
                        8. Refund will be issued after receiving the item and approved by our quality assurance team. Refund amount will include only the cost of product. Any other cost incurred for the order by the company/ customer will not be refunded.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        9. All the refunds will be credited to the same payment mode through which the order was paid. The refund might take 7 to 10 working days to credit from the date of initiation.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="footermargin  mt-5">
                <Footer />
            </Row>

        </div >
    )
}

export default Returnpolicy