import React, { useEffect } from 'react'
import Footer from "./Footer"
// import Navigation from "./Navigationnew";
import Navigation from "./Navprastype"
import { Row, Col } from "react-bootstrap"
import { FaDotCircle } from "react-icons/fa";

const Shipping = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="justify_content_center homfinmgmthead ">
                        SHIPPING POLICY
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-4">
                        Aaradhy Craft offers shipping across India through reliable courier service.The courier charges will be borne by the customer.All the orders will be processed within 5-7 business days from the date of order. Shipping timelines will be intimated to the customer in advance.
                    </Row>
                    
                    <Row className="justify_content_center shippingpolicytext mt-4">
                        <ul>
                        <li className='mt-3'>All our products are packed in secure tamper proof packaging. If you find that the package is tampered with please do not accept delivery of the same from the carrier. If the customer accepts delivery, it will be deemed that the product arrived in a tamper proof condition.</li>
                        <li className='mt-3'>All products ordered in one cart will be shipped to one shipping address. If there is a requirement for the products to be shipped to more than one shipping address then please create separate orders in separate carts for each address.</li>
                        
                        <li className='mt-3'>Octroi & Entry tax if applicable as per the local laws in the delivery location shall be borne by the customer.</li>
                        </ul>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>


            <Row className="footermargin  mt-5">
                <Footer />
            </Row>

        </div >
    )
}

export default Shipping