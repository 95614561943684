import React, { useState, useEffect } from "react"
import { Row, Col, OverlayTrigger, Tooltip, Modal } from "react-bootstrap"
import Url from "./Url"
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component";
import Navigation from "./Navadmin"
import { IoCloseSharp } from "react-icons/io5"
import { FaEye } from "react-icons/fa";
// import Leftvericalmenu from "./Leftverticalmenu"
// import department_vector from "./Images/department_vector.png"

const Sell = () => {

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    const usertoken = localStorage.getItem("usertoken")



    //const [searchcontent, setSearchcontent] = useState("")
    const [search, SetSearch] = useState('');
    const [filter, setFilter] = useState([]);


    const [data, setData] = useState([])

    const column = [
       
        {
            name: "Email",
            selector: row => row.customer_email,
            sortable: true
        },
        {
            name: "Mobile no",
            selector: row => row.b_phone,
            sortable: true
        }
        ,
        {
            name: "Order Id",
            selector: row => row.order_id,
            sortable: true
        },
        
        {
            name: "Purchase Status",
            selector: row => row.purchase_status,
            sortable: true
        },
        {
            name: "Shipping status",
            selector: row => row.shipping_status,
            sortable: true
        },
        {
            name: "Purchase Date",
            selector: row => row.created_at,
            sortable: true
        },
        // {
        //     name: "Action",
        //     cell: (row) => (
        //         <button className="btn btn-primary" onClick={() => getbannerdatabyid(row.id)}>Edit</button>
        //     )
        // },
        {
            name: "View",
            cell: (row) => (
                <Link to={"/Vieworderadmin/" + row.order_id} target="_blank">
                    <button className="btn btn-success" ><FaEye /></button>
                </Link>
            )
        }
    ]

    useEffect(() => {
        const fetchCSRFToken = async () => {
            try {
                const response = await fetch('your-laravel-api-url/sanctum/csrf-cookie', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }

                // CSRF token is now set in cookies, and you're ready to make authenticated requests
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCSRFToken();
    }, []); // Run once on component mount


    useEffect(() => {
        const api = url + "getCart"

        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata.message)
                if (actualdata["message"] != "Fail") {
                    setData(actualdata)
                    setFilter(actualdata)
                }
            })
    }, [])



    useEffect(() => {
        const result = data.filter((item) => {
            return item.banner_name.toLowerCase().match(search.toLocaleLowerCase());
        });
        setFilter(result);
    }, [search]);


    const tableHeaderstyle = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                backgroundColor: "#ccc"

            },
        },
    }



    ///////////////Render from top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Row className="">
                {/* <Col sm={1}>
                    <Leftvericalmenu />
                </Col> */}
                <Col>
                    <Row><Navigation /></Row>
                    <Row className="appointmentcard mt-2 ">

                        <Row className="appointcenterhead mt-3">

                            <Col sm={1} className="justify_content_left">
                                <Link to="/Dashboardadmin">Dashboard {">"}</Link>
                            </Col>

                            <Col className="justify_content_left">Sell</Col>
                            <Col className="justify_content_right">

                            </Col>
                        </Row>

                        <Row>

                            <Col className="appointcentercols jus">


                                <Row className=" mt-3">

                                </Row>
                            </Col>

                            <Col sm={5} className="justify_content_center">

                            </Col>
                        </Row>


                        <Row className="depttablebox mt-5 mb-3">
                            <Row className="homereqmsghead justify_content_center">Sell</Row>
                            <Row>
                                <DataTable
                                    customStyles={tableHeaderstyle}
                                    columns={column}
                                    data={filter}
                                    pagination
                                    //selectableRows
                                    fixedHeader
                                    selectableRowsHighlight
                                    highlightOnHover
                                    // actions={
                                    //     <button className="btn btn-success"
                                    //         onClick={() => JSONToCSVConvertor(data, "_banner List", true)}
                                    //     >Export Excel</button>
                                    // }
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text"
                                            className="w-100 form-control"
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(e) => SetSearch(e.target.value)}

                                        />
                                    }
                                    subHeaderAlign="left"

                                />
                            </Row>



                        </Row>
                    </Row>

                </Col>
            </Row>
        </>
    )
}

export default Sell