import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Footer from "./Footer"
// import Navigation from "./Navigationnew"
import Navigation from "./Navprastype"
import { Row, Col, Form } from "react-bootstrap"
import { ImOffice } from "react-icons/im"
import { HiOutlineMail } from "react-icons/hi"
import { FiPhoneCall } from "react-icons/fi"
import Url from "./Url";

const Contactus = () => {

    const url1 = Url();
    const url = url1["url"];

    const [customer_name, setCustomer_name] = useState("")
    const [customer_email, setCustomer_email] = useState("")
    const [phone_no, setPhone_no] = useState("")
    const [message, setMessage] = useState("")

    const createContactus = () => {

        const api = url + "createcontactus"
        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                
            },
            body: JSON.stringify({ customer_name, customer_email, phone_no, message })
        })
            .then((apidata) => {
                //console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata["message"] === "success") {
                    alert("Your message sent successfully")
                    window.location.reload()
                }

            })
    }

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className=" contactustopbg justify_content_center">
                <Col className="">
                    <Row className="justify_content_center aboutheadingtxt">

                        Contact Us

                    </Row>

                    <Row className="justify_content_center ">
                        <Col className="justify_content_center ">

                            <span className="aboutustoptext1 mr-0"><Link to="/" className="aboutustoptext1">Home</Link></span>
                            <span className="aboutustoptext1 mx-3 mr-3">{">"}</span>
                            <span className="aboutustoptext2 ml-0">Contact us</span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className=" justify_content_center mt-5 mb-5 " >
                <Col sm={1}></Col>
                <Col className="" >
                    <Row className="homreqmsgrect" data-aos="fade-up">
                        <Col className="mt-0">
                            <Row className="homesecondtop mt-5">
                                SEND A MESSAGE
                            </Row>
                            <Row className="homereqmsghead mt-4">
                                Request a call back
                            </Row>
                            <Row className="floating-label mt-5">
                                <input className="floating-input" type="text" placeholder=" "
                                    required
                                    onChange={(e)=>setCustomer_name(e.target.value)}
                                />
                                <span className="highlight"></span>
                                <label>Your name *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="email" placeholder=" "
                                    required
                                    onChange={(e)=>setCustomer_email(e.target.value)}
                                />
                                <span className="highlight"></span>
                                <label>Email *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="number" placeholder=" "
                                pattern="[1-9]{1}[0-9]{9}"
                                    required
                                    onChange={(e)=>setPhone_no(e.target.value)}
                                />
                                <span className="highlight"></span>
                                <label>Mobile no *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <textarea className="floating-input" type="text" placeholder=" " style={{ height: '120px' }}
                                    required
                                    onChange={(e)=>setMessage(e.target.value)}
                                />
                                <span className="highlight"></span>
                                <label>Your message *</label>
                            </Row>

                            <Row className="justify_content_left mt-5">
                                <button className="homgreqmsgbtn"
                                    onClick={createContactus}
                                >Submit</button>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className="mt-0 mx-5" data-aos="fade-left">
                    <Row className="homesecondsecwelcom mt-5">
                        CONTACT US
                    </Row>
                    <Row className="homesecondsechead mt-4">
                        We’d Love to Hear From You.
                    </Row>
                    <Row className="mt-4">
                        <Col xs={3} className="homesecondsecborder"></Col>
                    </Row>
                    {/* <Row className="homesecondsecdesc mt-4 mx-2">
                        We deeply care for our clients, firmly believe in our people and are Passionate about our quality of services. We are equipped to meet the changing demands of the corporate and non-corporate entities with our trained personnel, capable of rendering wide spectrum of services.
                    </Row> */}
                    <Row className="mt-4">
                        <Col sm className="">
                            <Row>
                                <Col xs={3} className='contactusicon'>
                                    <FiPhoneCall />
                                </Col>
                                <Col>
                                    <Row className="contactusheads  mt-3">
                                        Call Us
                                    </Row>
                                    <Row className="contactusdesc  mt-0">
                                        +91 7489615360
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm>
                            <Row>
                                <Col xs={3} className='contactusicon'>
                                    <HiOutlineMail />
                                </Col>
                                <Col>
                                    <Row className="contactusheads  mt-3">
                                        Email Address
                                    </Row>
                                    <Row className="contactusdesc  mt-0">
                                        aaradhyhandcraft@gmail.com
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm>
                            <Row>
                                <Col xs={3} className='contactusicon'>
                                    <ImOffice />
                                </Col>
                                <Col>
                                    <Row className="contactusheads  mt-2">
                                        Visit
                                    </Row>
                                    <Row className="contactusdesc  mt-0">
                                        Rajesh Dewangan<br />H.NO 36,<br /> DURGA MANDIR ROAD,<br />WARD NO. 08, Sarangarh,<br />
                                        Sarangarh Bilaigarh, Chhattisgarh,<br />496445
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm></Col>
                    </Row>
                </Col>

            </Row>

            <Row className="footermargin  ">
                <Footer />
            </Row>

        </div>
    )
}

export default Contactus