import React, { useState, useEffect } from "react"
import { Row, Col, Carousel } from "react-bootstrap"
import { useParams } from "react-router-dom"
import Url from "./Url"
import { Link } from "react-router-dom"
//import DataTable from "react-data-table-component";
import Navigation from "./Navigationnew"
import Footer from "./Footer"
import { FaCheck, FaDotCircle } from "react-icons/fa"
import Saree1_1 from "./Images/New folder/PhotoRoom-20231210_174947.png"
import Saree1_2 from "./Images/New folder/PhotoRoom-20231210_181626.png"
import Saree1_3 from "./Images/New folder/PhotoRoom-20231210_181656.png"
import Saree1_4 from "./Images/New folder/PhotoRoom-20231211_124601.png"

const Viewarticle3 = () => {

    const { sareeid } = useParams()

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Row className="">
                {/* <Col sm={1}>
                    <Leftvericalmenu />
                </Col> */}
                <Col>
                    <Row><Navigation /></Row>
                    <Row className="appointmentcard mt-2 ">

                        <Row className="appointcenterhead mt-3">

                            <Col sm={1} className="justify_content_left">
                                <Link to="/Dashboardadmin">Dashboard {">"}</Link>
                            </Col>

                            <Col className="justify_content_left">Article details</Col>

                        </Row>
                        <Row className="homereqmsghead justify_content_center mt-0">
                            Article details
                        </Row>

                        <Row className=" mt-2 mb-3 mx-2">

                            <Col sm={7}>
                                <Row>
                                    <Col sm className="mt-3">
                                        <img src={Saree1_1} alt="" className="articledetailpics" />
                                    </Col>
                                    <Col sm className="mt-3">
                                        <img src={Saree1_2} alt="" className="articledetailpics" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm className="mt-3">
                                        <img src={Saree1_3} alt="" className="articledetailpics" />
                                    </Col>
                                    <Col sm className="mt-3">
                                        <img src={Saree1_4} alt="" className="articledetailpics" />
                                    </Col>
                                </Row>
                                <Row className="homecountercardtext mt-5">
                                    DESCRIPTION
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={2} className="homfeatureheading">
                                        Material:
                                    </Col>
                                    <Col xs className="homfeaturetext">
                                        Semi Silk
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col  className="homfeatureheading">
                                    Handloom mark
                                    </Col>
                                   
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={2} className="homfeatureheading">
                                        Blouse:
                                    </Col>
                                    <Col xs className="homfeaturetext">
                                        Attached
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={2} className="homfeatureheading">
                                        Length:
                                    </Col>
                                    <Col xs className="homfeaturetext">
                                        6.2 mts (5.5 mts saree & 70 cms blouse)
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={2} className="homfeatureheading">
                                        Wash & Care:
                                    </Col>
                                    <Col xs className="homfeaturetext">
                                        Dry clean only
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm className="mx-5">
                                <Row className="articledetailtext mt-3 ">
                                    PURE SILK SAREE Heena AND GOLDEN BORDER STYLE
                                </Row>
                                <Row className="articledetailtext mt-3 ">
                                    Article id: AA4145
                                </Row>
                                <Row className="articledetailtext mt-3 ">
                                    ₹4499
                                </Row>
                                <Row className="artcledetailtexred  mt-3" >

                                    <Col><FaCheck />In stock, only one Unit</Col>
                                </Row>
                                <Row className="articledetailtext mt-3 ">
                                    <Col ><FaCheck /> Dispatched within 24 Hrs </Col>

                                </Row>
                                <Row className="mt-3">
                                    <button className="homgreqmsgbtn">
                                        Add to cart
                                        {/* <BiPlusMedical /> */}
                                    </button>
                                </Row>
                                <Row className="articledetailtext mt-3 ">
                                    <Col xs={8}><FaCheck /> 3 Days Return Policy </Col>

                                </Row>
                                <Row className="articledetailtext mt-3 ">
                                    <Col xs={8}><FaCheck /> Secured & Safe payments </Col>

                                </Row>
                                <Row className="articledetailtext mt-3 ">
                                    <Col xs={8}><FaCheck /> Above mentioned pricing is inclusive of applicable taxes. </Col>

                                </Row>
                            </Col>

                        </Row>
                    </Row>

                </Col>
            </Row>
            <Row className="mt-5">
                <Footer />
            </Row>
        </>
    )
}

export default Viewarticle3