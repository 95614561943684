import React, { useEffect } from "react"
import './App.css';
import { HashRouter as Router, Routes, Route } from "react-router-dom"
import { Container } from 'react-bootstrap';
import Homepage from "./Components/Homepage"
import Aboutus from './Components/Aboutus';
import Contactus from './Components/Contactus';
// import Allarticlelist from './Components/Allarticlelist';
import Allarticlelist from './Components/Alllistingpagination';
import Banner from './Components/Banner';
import Popup from "./Components/Popup";
import Logout from './Components/Logout';
import Dashboardadmin from './Components/Dashboardadmin';
// import Article from './Components/Article';
import Article from './Components/Articlepixelcrop';
import Viewarticleadmin from './Components/Viewarticleadmin';
//import Categorymaster from './Components/Categorymaster';
import Categorymaster from './Components/Categorymasteractive';
import Materialmaster from './Components/Materialmaster';
import Returnpolicy from './Components/Returnpolicy';
import Shipping from './Components/Shipping';
import Terms from './Components/Terms';
import Privacypolicy from './Components/Privacypolicy';
// import Viewarticle from './Components/Viewarticle';
import Viewarticle from './Components/Viewarticlemobilefinger';
//import Checkout from './Components/Checkout';
import Checkout from './Components/Checkoutcod';
// import Viewarticlenew from './Components/Viewarticlenew';
import Viewarticle2 from './Components/Viewarticle2';
import Viewarticle3 from './Components/Viewarticle3';
import Viewarticle4 from './Components/Viewarticle4';
import Viewarticle5 from './Components/Viewarticle5';
import Sell from './Components/Sell';
import CoD from "./Components/CoD";
import Vieworderadmin from './Components/Vieworderadmin';
import Admincontactus from './Components/Admincontactus';
import Success from './Components/Success';
import Successcod from "./Components/Successcod";
import Fail from './Components/Fail';
import Returnitem from './Components/Returnitem';
import Returnviewadmin from './Components/Returnviewadmin';
import  {initMetaPixel}  from './Components/Fbevents';

function App() {
  useEffect(() => {
    initMetaPixel('1288056515520748');
  }, []);
  return (
    <div className="">
      <Container fluid className="">
        <Router>
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/Aboutus" element={<Aboutus />} />
            <Route exact path="/Contactus" element={<Contactus />} />
            <Route exact path="/Allarticlelist" element={<Allarticlelist />} />
            <Route exact path="/Banner" element={<Banner />} />
            <Route exact path="/Logout" element={<Logout />} />
            <Route exact path="/Dashboardadmin" element={<Dashboardadmin />} />
            <Route exact path="/Article" element={<Article />} />
            <Route exact path="/Viewarticleadmin/:id" element={<Viewarticleadmin />} />
            <Route exact path="/Categorymaster" element={<Categorymaster />} />
            <Route exact path="/Materialmaster" element={<Materialmaster />} />
            <Route exact path="/Returnpolicy" element={<Returnpolicy />} />
            <Route exact path="/Shippingpolicy" element={<Shipping />} />
            <Route exact path="/Terms" element={<Terms />} />
            <Route exact path="/Privacypolicy" element={<Privacypolicy />} />
            <Route exact path="/Viewarticle/:id/:article_name" element={<Viewarticle />} />
            <Route exact path="/Checkout" element={<Checkout />} />
            <Route exact path="/Viewarticle2" element={<Viewarticle2 />} />
            <Route exact path="/Viewarticle3" element={<Viewarticle3 />} />
            <Route exact path="/Viewarticle4" element={<Viewarticle4 />} />
            <Route exact path="/Viewarticle5" element={<Viewarticle5 />} />
            <Route exact path="/Success" element={<Success />} />
            <Route exact path="/Succes" element={<Successcod />} />
            <Route exact path="/Fail" element={<Fail />} />
            <Route exact path="/Sell" element={<Sell />} />
            <Route exact path="/CoD" element={<CoD />} />
            <Route exact path="/Vieworderadmin/:order_id" element={<Vieworderadmin />} />
            <Route exact path="/Returnitem" element={<Returnitem />} />
            <Route exact path="/Returnviewadmin" element={<Returnviewadmin />} />
            <Route exact path="/Admincontactus" element={<Admincontactus />} />
            <Route exact path="/Popup" element={<Popup />} />
          </Routes>
        </Router>
      </Container>
    </div>
  );
}

export default App;
