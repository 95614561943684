import React, { useEffect, useState } from "react";
import "./Styles.css"
import Footer from "./Footer";
import Aos from "aos"
import "aos/dist/aos.css"
// import Navigation from "./Navigationnew";
import Navigation from "./Navprastype"
import { Row, Col, Carousel, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCheck, FaQuoteLeft, FaDotCircle } from "react-icons/fa"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Carouselimg1 from "./Images/Home corousel/colorful_threads1.jpg"
// import Carouselimg2 from "./Images/Home corousel/saree1.jpg"
// import Carouselimg3 from "./Images/Home corousel/textiles_sale1.jpg"
import Carouselimg1 from "./Images/Dummy saree/Saree4small.jpg"
import Carouselimg2 from "./Images/Dummy saree/Saree5small.jpg"
import Carouselimg3 from "./Images/Dummy saree/Saree6small.jpg"
import Carouselimg4 from "./Images/Dummy saree/Saree7small.jpg"
import Saree1 from "./Images/New folder/PhotoRoom-20231209_225109.png"
// import Saree2 from "./Images/New folder/PhotoRoom-20231210_174302.png"
// import Saree3 from "./Images/New folder/PhotoRoom-20231210_174947.png"
// import Saree4 from "./Images/New folder/PhotoRoom-20231210_183710.png"
import Saree5 from "./Images/New folder/PhotoRoom-20231210_184823.png"
import Sareecard1 from "./Images/Dummy saree/sareecard1.png"
import Sareecard2 from "./Images/Dummy saree/sareecard2.png"
import Sareecard3 from "./Images/Dummy saree/sareecard3.png"
import Widget from "./Widget";
import Url from './Url';

const Homepage = () => {

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    const [getbanner, setGetbanner] = useState("")
    const [getarticle, setGetarticle] = useState([])

    const [mobilescr, setMobilescr] = useState(false)
    const [getdevwidth, setGetdevwidth] = useState("")

    useEffect(() => {

        setGetdevwidth(window.innerWidth + 'px')

        if (window.innerWidth <= 500) {
            setMobilescr(true)
        }
        //console.log(getdevwidth)

    }, [getdevwidth])

    // useEffect(() => {
    //     setGetbanner("WELCOME TO AARADHY SILK HAND CRAFT")
    // }, [])

    useEffect(() => {
        const api = url + "getActiveBannerOne"

        fetch(api, {
            method: 'GET',
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Not found") {
                    setGetbanner(actualdata.banner_name)
                }
                else {
                    setGetbanner("WELCOME TO AARADHY SILK HAND CRAFT")
                }
            })
    }, [])

    useEffect(() => {
        const api = url + "getArticle_Pic_Home"

        fetch(api, {
            method: 'GET',
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Fail") {
                    setGetarticle(actualdata)
                }

            })
    }, [])

    const addToRecentlyViewed = (article) => {
        const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
        const updatedRecentlyViewed = [article, ...recentlyViewed.filter(item => item.id !== article.id)].slice(0, 8);
        localStorage.setItem('recentlyViewed', JSON.stringify(updatedRecentlyViewed));
    };

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5, // Number of items to show at once
        // slidesToScroll: 1,
        swipeToSlide: true,
        // variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.5,
                    arrows: false,
                }
            }
        ]
    };

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    // const callBack = () => {
    //     // var elmnt = document.getElementById("Callback");
    //     // elmnt.scrollIntoView();
    //     // window.scrollTo({ behavior: 'smooth' });
    //     window.scrollTo(0, 800)
    // }

    return (
        <>
            <Widget />

            <Row className="mb-0">
                <Navigation />
            </Row>
            {/* {
                !mobilescr ?
                    <>
                        <Row className="mt-5 "></Row>
                        <Row className="mt-5 "></Row>
                    </>
                    :
                    null
            } */}
            {!mobilescr ?
                <Row className="mt-2 mb-0">
                    <Col xs className="justify_content_center homecounterbg">
                        <Col xs className="hometopadd justify-content-center " data-aos="fade-right">
                            <Col >
                                <Row className="hombannercardtext justify-content-center mt-2  mb-4">
                                    {getbanner}
                                </Row>
                            </Col>
                        </Col>
                    </Col>
                </Row>
                :
                <Row className="" style={{ marginTop: "-15px" }}>
                    <Col xs className="justify_content_center homecounterbg">
                        <Col xs className="hometopadd justify-content-center " data-aos="fade-right">
                            <Col >
                                <Row className="hombannercardtext justify-content-center mt-2  mb-4">
                                    {getbanner}
                                </Row>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            }
            <Row className="" style={{ marginTop: "-25px" }}>
                <Carousel fade className="">
                    <Carousel.Item interval='3000'>
                        <img
                            className="d-block w-100"
                            src={Carouselimg1}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <Col xs></Col>
                            <Col xs className="mt-0">
                            </Col>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval='3000'>
                        <img
                            className="d-block w-100"
                            src={Carouselimg2}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval='3000' >
                        <img
                            className="d-block w-100"
                            src={Carouselimg3}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval='3000' >
                        <img
                            className="d-block w-100"
                            src={Carouselimg4}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    {/* <Carousel.Item interval='1000' >
                        <img
                            className="d-block w-100"
                            src={Carouselimg4}
                            alt="First slide"
                        />
                    </Carousel.Item> */}
                </Carousel>
            </Row>

            {/* <Row className="mt-3">
                <Col sm={3}></Col>
                <Col className="">
                    <Row className=" justify_content_center align_vertical_center">
                        <img src={Sareecard1} alt="" className="sareesamplecardsaree" />
                    </Row>
                    <Row className="homecountercardtext justify_content_center mt-2">
                        Hand Weaving Saree
                    </Row>
                </Col>
                <Col className="">
                    <Row className=" justify_content_center align_vertical_center">
                        <img src={Sareecard2} alt="" className="sareesamplecardsaree" />
                    </Row>
                    <Row className="homecountercardtext justify_content_center mt-2">
                        Hand Weaving Salwar Suit
                    </Row>
                </Col>
                <Col className="">
                    <Row className=" justify_content_center align_vertical_center">
                        <img src={Sareecard3} alt="" className="sareesamplecardsaree" />
                    </Row>
                    <Row className="homecountercardtext justify_content_center mt-2">
                        Hand Weaving Kurta
                    </Row>
                </Col>
                <Col sm={3}></Col>
            </Row> */}



            {/* <Row className=" justify_content_center mt-5 ">
                <span className="collectionhead">Complete </span>
                <span className="collectionhandwovenhead">Handwoven</span> 
                <span className="collectionhead">Saree</span>
            </Row> */}

            <Row className="justify_content_center mt-5">
                <div className="collection-title">
                    <span className="collection-regular">Complete </span>
                    <span className="collection-highlight">Handwoven </span>
                    <span className="collection-regular">Saree</span>
                </div>
            </Row>



            <Row className="homecounterbg justify-content-center mt-5">
                <Slider {...settings}>
                    {
                        getarticle.length > 0 ? getarticle.map(item => (
                            <Col xs={2} className=" justify-content-center " >
                                <Link to={"/Viewarticle/" + item.id + "/" + item.article_name} onClick={() => addToRecentlyViewed(item)}>
                                    <Col className="homecountercardbhiya">
                                        <Row className="justify-content-center">
                                            <img src={s3url + item.article_Thumbnail} alt="" className="featuresimgbhiya" />
                                        </Row>
                                        <Row className="homecountercardtext justify-content-center mt-2 mx-2">
                                            {item.article_name}
                                        </Row>
                                        <Row className="homecountercardtext justify-content-center mt-2 mx-2">
                                            ₹{item.article_price}
                                        </Row>
                                        <Row className="homecountercardtexred justify-content-center mt-2 mb-4" >
                                            {/* <FaDotCircle /> In stock, only one Unit */}
                                        </Row>
                                    </Col>
                                </Link>
                            </Col>))
                            :
                            null
                    }
                </Slider>

            </Row>



            {/* <Carousel.Item interval='3000' className="justify-content-center">
                            <Link to="/Viewarticle5">
                                <Col >
                                    <Row className="justify-content-center">
                                        <img src={Saree5} alt="" className="featuresimg1" />
                                    </Row>
                                    <Row className="homecountercardtext justify-content-center mt-2">
                                        Semi silk Hand woven
                                    </Row>
                                    <Row className="homecountercardtext justify-content-center mt-2 ">
                                        ₹4499
                                    </Row>
                                    <Row className="homecountercardtexred justify-content-center mt-2 mb-4" >
                                        <FaDotCircle /> In stock, only one Unit
                                    </Row>
                                </Col>
                            </Link>
                        </Carousel.Item> */}



            <Row className="mt-5">
                <Col className="justify_content_right">
                    <Link to="/Allarticlelist">
                        <button className="homgreqmsgbtn">
                            See more
                        </button>
                    </Link>
                </Col>
            </Row>


            <Row className="mt-5">
                <Footer />
            </Row>
        </>
    )
}

export default Homepage