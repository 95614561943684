import React from 'react';

export default function Url() {

    const checklocal = window.location.href

    var url
    var url1
    var s3

    // url = "http://127.0.0.1:8000/api/"
    // //url = "http://3.88.161.177/api/"
    // url1 = "http,localhost,api"
    // s3 = "https://pandariya-file-upload.s3.ap-south-1.amazonaws.com/"

    if (checklocal.includes("localhost")) {
        url = "http://127.0.0.1:8000/api/"
        url1 = "http,localhost,api"
        s3 = "https://aaradhycraft.s3.ap-south-1.amazonaws.com/aradhy/"
    }

    if (checklocal.includes("127.0.0.1")) {
        url = "http://127.0.0.1:8000/api/"
        url1 = "http,localhost,api"
        s3 = "https://aaradhycraft.s3.ap-south-1.amazonaws.com/aradhy/"
    }

    // if (checklocal.includes("13.126.128.1")) {
    //     url = "http://13.126.128.1/api/"
    //     url1 = "http,localhost,api"
    //     s3 = "https://aaradhycraft.s3.ap-south-1.amazonaws.com/aradhy/"
    // }

    if (checklocal.includes("aaradhycraft.com")) {
        url = "https://www.aaradhycraft.com/api/"
        url1 = "http,localhost,api"
        s3 = "https://aaradhycraft.s3.ap-south-1.amazonaws.com/aradhy/"
    }

    // if (checklocal.includes("localhost")) {
    //     url = "http://127.0.0.1:8000/api/"
    //     url1 = "http,localhost,api"
    //     s3 = ""
    // }

    // if (checklocal.includes("13.201.7.200")) {
    //     url = "http://13.201.7.200/api/"
    //     url1 = "http,localhost,api"
    //     s3 = ""
    // }

    // if (checklocal.includes("aaradhycraft.com")) {
    //     url = "http://13.201.7.200/api/"
    //     url1 = "http,localhost,api"
    //     s3 = ""
    // }

    return (

        { url, url1, s3 }

    );
}
