import React, { useState, useEffect } from "react"
import { Row, Col, OverlayTrigger, Tooltip, Modal } from "react-bootstrap"
import Url from "./Url"
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component";
import Navigation from "./Navadmin"
import { IoCloseSharp } from "react-icons/io5"

// import Leftvericalmenu from "./Leftverticalmenu"
// import department_vector from "./Images/department_vector.png"



const Materialmaster = () => {

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    const usertoken = localStorage.getItem("usertoken")

    const [rukjlk, setRukjlk] = useState(false)



    //const [email, setEmail] = useState("")
    const [material_type, SetMaterial_type] = useState("")
    const [material_remark, SetMaterial_remark] = useState("")

    const [getarticlebyid, setGetarticlebyid] = useState([])

    const [addarticlemodal, setAddarticlemodal] = useState(false)
    const [editarticlemodal, setEditarticlemodal] = useState(false)

    //const [searchcontent, setSearchcontent] = useState("")
    const [search, SetSearch] = useState('');
    const [filter, setFilter] = useState([]);
    const [data, setData] = useState([])

    const column = [
        {
            name: "Material type",
            selector: row => row.material_type,
            sortable: true
        },
        {
            name: "Material remark",
            selector: row => row.material_remark,
            sortable: true
        },
        {
            name: "Edit",
            cell: (row) => (
                <button className="btn btn-primary" onClick={() => getarticledatabyid(row.id)}>Edit</button>
            )
        },
        {
            name: "Delete",
            cell: (row) => (
                <button className="btn btn-danger" onClick={() => delarticle(row.id)}>Delete</button>
            )
        },
        // 
        // {
        //     name: "View",
        //     cell: (row) => (
        //         <Link to={"/Viewarticleadmin/" + row.id} target="_blank">
        //             <button className="btn btn-primary" >View</button>
        //         </Link>
        //     )
        // }
    ]

    useEffect(() => {
        const fetchCSRFToken = async () => {
            try {
                const response = await fetch('your-laravel-api-url/sanctum/csrf-cookie', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }

                // CSRF token is now set in cookies, and you're ready to make authenticated requests
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCSRFToken();
    }, []); // Run once on component mount

    useEffect(() => {
        const api = url + "getmaterial_master"

        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Fail") {
                    setData(actualdata)
                    setFilter(actualdata)
                }
            })
    }, [])

    /*---------------------Add article --------------------- */

    const addarticle = e => {
        e.preventDefault()

        const api = url + "creatematerial_master"
        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${usertoken}`, // Include the bearer token
            },
            body: JSON.stringify({ material_type, material_remark })
        })
            .then((apidata) => {
                //console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata.message === "success") {
                    alert("Material created successfully")
                    setAddarticlemodal(false)
                    window.location.reload()
                }

            })
    }

    const getarticledatabyid = (id) => {
        const api = url + "getmaterial_masterById/" + id
        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata1) => {
                return apidata1.json()
            })
            .then((actualdata1) => {
                //console.log(actualdata1)
                if (actualdata1.message !== "Fail") {
                    setGetarticlebyid(actualdata1)
                    setEditarticlemodal(true)
                }
            }
            )
    }

    const updatearticle = (id) => {

        const api = url + "updatematerial_master/" + id

        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${usertoken}`, // Include the bearer token
            },
            body: JSON.stringify({ material_type, material_remark })
        })
            .then((apidata) => {
                //console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata.message === "success") {
                    alert("article Updated successfully")
                    setEditarticlemodal(false)
                    window.location.reload()
                }

            })
    }

    const delarticle = (id) => {

        const api = url + "delmaterial_masterById/" + id

        var res = window.confirm("You want to delete this material, are you sure?")
        if (res) {
            fetch(api, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${usertoken}`, // Include the bearer token
                },
            })
                .then((actualdata) => {
                    return actualdata.json()
                })
                .then((actualdata1) => {
                    //console.log(actualdata1)
                    if (actualdata1.message === "success") {
                        alert("article Deleted")
                        window.location.reload()
                    }
                })
        }
    }

    useEffect(() => {
        const result = data.filter((item) => {
            return item.article_name.toLowerCase().match(search.toLocaleLowerCase());
        });
        setFilter(result);
    }, [search]);


    const tableHeaderstyle = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                backgroundColor: "#ccc"

            },
        },
    }


    /*--------------------------- Generate CSV ---------------------------*/
    // const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //     //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    //     var arrData =
    //         typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    //     var CSV = "";

    //     //This condition will generate the Label/Header
    //     if (ShowLabel) {
    //         var row = "";

    //         //This loop will extract the label from 1st index of on array
    //         for (var index in arrData[0]) {
    //             //Now convert each value to string and comma-seprated
    //             row += index + ",";
    //         }

    //         row = row.slice(0, -1);

    //         //append Label row with line break
    //         CSV += row + "\r\n";
    //     }

    //     //1st loop is to extract each row
    //     for (var i = 0; i < arrData.length; i++) {
    //         var row = "";

    //         //2nd loop will extract each column and convert it in string comma-seprated
    //         for (var index in arrData[i]) {
    //             row += '"' + arrData[i][index] + '",';
    //         }

    //         row.slice(0, row.length - 1);

    //         //add a line break after each row
    //         CSV += row + "\r\n";
    //     }

    //     if (CSV === "") {
    //         alert("Invalid data");
    //         return;
    //     }

    //     //Generate a file name
    //     var fileName = "";
    //     //this will remove the blank-spaces from the title and replace it with an underscore
    //     fileName += ReportTitle.replace(/ /g, "_");

    //     //Initialize file format you want csv or xls
    //     var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    //     // Now the little tricky part.
    //     // you can use either>> window.open(uri);
    //     // but this will not work in some browsers
    //     // or you will not get the correct file extension

    //     //this trick will generate a temp <a /> tag
    //     var link = document.createElement("a");
    //     link.href = uri;

    //     //set the visibility hidden so it will not effect on your web-layout
    //     link.style = "visibility:hidden";
    //     link.download = fileName + ".csv";

    //     //this part will append the anchor tag and remove it after automatic click
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };
    /*--------------------------- Generate CSV ---------------------------*/


    ///////////////Render from top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            {/*-----------------Add Material modal--------------------*/}
            <Modal
                show={addarticlemodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalbg"
            >
                <Row className="justify_content_center">
                    <Col className="appointmodalhead"></Col>
                    <Col xs={1} className="appointmodalhead"
                        onClick={() => { setAddarticlemodal(false) }}
                        style={{ cursor: "pointer", marginLeft: "-100px" }}
                    >
                        <IoCloseSharp />
                    </Col>
                </Row>
                <Row className="deptmodalhead">
                    <Col>Add New article</Col>
                </Row>
                <Modal.Body>

                    <Row>

                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Material Type*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { SetMaterial_type(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Material remark*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { SetMaterial_remark(e.target.value) }}
                                />
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-4 mb-3">
                        <Col className="justify_content_center">
                            <button className="serachdeptbtn"
                                onClick={addarticle}
                            >Create</button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            {/*-----------------Add article modal end--------------------*/}

            {/*-----------------Edit article modal--------------------*/}
            <Modal
                show={editarticlemodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalbg"
            >
                <Row className="justify_content_center">
                    <Col className="appointmodalhead"></Col>
                    <Col xs={1} className="appointmodalhead"
                        onClick={() => { setEditarticlemodal(false) }}
                        style={{ cursor: "pointer", marginLeft: "-100px" }}
                    >
                        <IoCloseSharp />
                    </Col>
                </Row>
                <Row className="deptmodalhead">
                    <Col>Edit New article</Col>
                </Row>
                <Modal.Body>

                    <Row>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Material Type*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.material_type}
                                    onChange={(e) => { SetMaterial_type(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Material Remark*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                    defaultValue={getarticlebyid.material_remark}
                                    onChange={(e) => { SetMaterial_remark(e.target.value) }}
                                />
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-4 mb-3">
                        <Col className="justify_content_center">
                            <button className="serachdeptbtn"
                                onClick={() => { updatearticle(getarticlebyid.id) }}
                            >Update</button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            {/*-----------------Edit article modal end--------------------*/}



            <Row className="">
                {/* <Col sm={1}>
                    <Leftvericalmenu />
                </Col> */}
                <Col>
                    <Row><Navigation /></Row>
                    <Row className="appointmentcard mt-2 ">

                        <Row className="appointcenterhead mt-3">

                            <Col sm={1} className="justify_content_left">
                                <Link to="/Dashboardadmin">Dashboard {">"}</Link>
                            </Col>

                            <Col className="justify_content_left">Material Master</Col>
                            <Col className="justify_content_right">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Material</Tooltip>}>
                                    <span className="d-inline-block">
                                        <button className="homgreqmsgbtn"
                                            onClick={() => { setAddarticlemodal(true) }}
                                        >
                                            Add Material
                                            {/* <BiPlusMedical /> */}
                                        </button>
                                    </span>
                                </OverlayTrigger>
                            </Col>
                        </Row>

                        <Row>

                            <Col className="appointcentercols jus">

                            </Col>

                            <Col sm={5} className="justify_content_center">
                                {/* <img src={department_vector} alt="" className="deptvector" /> */}
                            </Col>
                        </Row>

                        <Row className="depttablebox mt-5 mb-3">
                            <Row className="homereqmsghead justify_content_center">Material</Row>
                            <Row>
                                <DataTable
                                    customStyles={tableHeaderstyle}
                                    columns={column}
                                    data={filter}
                                    pagination
                                    //selectableRows
                                    fixedHeader
                                    // selectableRowsHighlight
                                    highlightOnHover
                                    // actions={
                                    //     <button className="btn btn-success"
                                    //         onClick={() => JSONToCSVConvertor(data, "_article List", true)}
                                    //     >Export Excel</button>
                                    // }
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text"
                                            className="w-100 form-control"
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(e) => SetSearch(e.target.value)}
                                        />
                                    }
                                    subHeaderAlign="left"
                                />
                            </Row>

                        </Row>
                    </Row>

                </Col>
            </Row>
        </>
    )
}

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}


export default Materialmaster