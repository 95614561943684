import React, { useState, useEffect } from "react"
import { Row, Col, OverlayTrigger, Tooltip, Modal } from "react-bootstrap"
import Url from "./Url"
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component";
import Navigation from "./Navadmin"
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5"
// import Leftvericalmenu from "./Leftverticalmenu"
// import department_vector from "./Images/department_vector.png"

const Admincontactus = () => {

    const url1 = Url()
    const url = url1["url"]

    const usertoken = localStorage.getItem("usertoken")

    //const [searchcontent, setSearchcontent] = useState("")
    const [search, SetSearch] = useState('');
    const [filter, setFilter] = useState([]);

    const [data, setData] = useState([])

    const [getcontactusbyid, setGetcontactusbyid] = useState([])

    const [customer_name, setCustomer_name] = useState("")
    const [customer_email, setCustomer_email] = useState("")
    const [phone_no, setPhone_no] = useState("")
    const [message, setMessage] = useState("")

    const [editcontactusemodal, setEditcontactusemodal] = useState(false)

    const column = [
        {
            name: "Customer Name",
            selector: row => row.customer_name,
            sortable: true
        },
        {
            name: "Email",
            selector: row => row.customer_email,
            sortable: true
        },
        {
            name: "Mobile no",
            selector: row => row.phone_no,
            sortable: true
        }
        ,        
        {
            name: "Messsage",
            selector: row => row.message,
            sortable: true
        },
        {
            name: "Action",
            cell: (row) => (
                <button className="btn btn-primary" onClick={() => getContactusById(row.id)}><FaRegEdit /></button>
            )
        },
        {
            name: "Action",
            cell: (row) => (
                <button className="btn btn-danger" onClick={() => delContactusById(row.id)}><RiDeleteBinLine /></button>
            )
        },
        // {
        //     name: "View",
        //     cell: (row) => (
        //         <Link to={"/Vieworderadmin/" + row.order_id} target="_blank">
        //             <button className="btn btn-success" ><FaEye /></button>
        //         </Link>
        //     )
        // }
    ]

    useEffect(() => {
        const fetchCSRFToken = async () => {
            try {
                const response = await fetch('your-laravel-api-url/sanctum/csrf-cookie', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }

                // CSRF token is now set in cookies, and you're ready to make authenticated requests
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCSRFToken();
    }, []); // Run once on component mount


    useEffect(() => {
        const api = url + "getcontactus"

        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata.message)
                if (actualdata["message"] != "Fail") {
                    setData(actualdata)
                    setFilter(actualdata)
                }
            })
    }, [])

    const getContactusById = (id) => {
        const api = url + "getcontactusById/" + id
        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata1)
                if (actualdata["message"] !== "Fail") {
                    setGetcontactusbyid(actualdata)
                    setEditcontactusemodal(true)
                }
            }
            )
    }

    const updateConatactus = (id) => {

        const api = url + "updatecontactus/" + id

        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${usertoken}`, // Include the bearer token
            },
            body: JSON.stringify({ customer_name, customer_email, phone_no, message })
        })
            .then((apidata) => {
                //console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata["message"] === "success") {
                    alert("Contact us details Updated successfully")
                    setEditcontactusemodal(false)
                    window.location.reload()
                }

            })
    }

    const delContactusById = (id) => {

        const api = url + "delcontactusById/" + id

        var res = window.confirm("You want to delete this contact us details, are you sure?")
        if (res) {
            fetch(api, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${usertoken}`, // Include the bearer token
                },
            })
                .then((actualdata) => {
                    return actualdata.json()
                })
                .then((actualdata1) => {
                    //console.log(actualdata1)
                    if (actualdata1["message"] === "success") {
                        alert("Contact us data Deleted")
                        window.location.reload()
                    }
                })
        }
    }



    useEffect(() => {
        const result = data.filter((item) => {
            return item.banner_name.toLowerCase().match(search.toLocaleLowerCase());
        });
        setFilter(result);
    }, [search]);


    const tableHeaderstyle = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                backgroundColor: "#ccc"

            },
        },
    }



    ///////////////Render from top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

        {/*-----------------Edit contact us modal--------------------*/}
        <Modal
                show={editcontactusemodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalbg"
            >
                <Row className="justify_content_center">
                    <Col className="appointmodalhead"></Col>
                    <Col xs={1} className="appointmodalhead"
                        onClick={() => { setEditcontactusemodal(false) }}
                        style={{ cursor: "pointer", marginLeft: "-100px" }}
                    >
                        <IoCloseSharp />
                    </Col>
                </Row>
                <Row className="deptmodalhead">
                    <Col>Edit New article</Col>
                </Row>
                <Modal.Body>

                    <Row>
                        <Col sm className="appointcentercols">
                        <Row className="floating-label mt-5">
                                <input className="floating-input" type="text" placeholder=" "
                                    required
                                    defaultValue={getcontactusbyid.customer_name}
                                    onChange={(e)=>setCustomer_name(e.target.value)}
                                />
                                <span className="highlight"></span>
                                <label>Your name *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="email" placeholder=" "
                                    required
                                    defaultValue={getcontactusbyid.customer_email}
                                    onChange={(e)=>setCustomer_email(e.target.value)}
                                />
                                <span className="highlight"></span>
                                <label>Email *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="number" placeholder=" "
                                pattern="[1-9]{1}[0-9]{9}"
                                    required
                                    defaultValue={getcontactusbyid.phone_no}
                                    onChange={(e)=>setPhone_no(e.target.value)}
                                />
                                <span className="highlight"></span>
                                <label>Mobile no *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <textarea className="floating-input" type="text" placeholder=" " style={{ height: '120px' }}
                                    required
                                    defaultValue={getcontactusbyid.message}
                                    onChange={(e)=>setMessage(e.target.value)}
                                />
                                <span className="highlight"></span>
                                <label>Your message *</label>
                            </Row>

                            <Row className="justify_content_left mt-5">
                                <button className="homgreqmsgbtn"
                                    onClick={()=>updateConatactus(getcontactusbyid.id)}
                                >Submit</button>
                            </Row>
                        </Col>
                        
                    </Row>

                    {/* 
                    <Row className="mt-4 mb-3">
                        <Col className="justify_content_center">
                            <button className="serachdeptbtn"
                                onClick={() => { updatearticle(getarticlebyid.id) }}
                            >Update</button>
                        </Col>
                    </Row> */}

                </Modal.Body>
            </Modal>
            {/*-----------------Edit contact us modal end--------------------*/}

            <Row className="">
                {/* <Col sm={1}>
                    <Leftvericalmenu />
                </Col> */}
                <Col>
                    <Row><Navigation /></Row>
                    <Row className="appointmentcard mt-2 ">

                        <Row className="appointcenterhead mt-3">

                            <Col sm={1} className="justify_content_left">
                                <Link to="/Dashboardadmin">Dashboard {">"}</Link>
                            </Col>

                            <Col className="justify_content_left">Contact us</Col>
                            <Col className="justify_content_right">

                            </Col>
                        </Row>

                        <Row>

                            <Col className="appointcentercols jus">


                                <Row className=" mt-3">

                                </Row>
                            </Col>

                            <Col sm={5} className="justify_content_center">

                            </Col>
                        </Row>


                        <Row className="depttablebox mt-5 mb-3">
                            <Row className="homereqmsghead justify_content_center">Contact us</Row>
                            <Row>
                                <DataTable
                                    customStyles={tableHeaderstyle}
                                    columns={column}
                                    data={filter}
                                    pagination
                                    //selectableRows
                                    fixedHeader
                                    selectableRowsHighlight
                                    highlightOnHover
                                    // actions={
                                    //     <button className="btn btn-success"
                                    //         onClick={() => JSONToCSVConvertor(data, "_banner List", true)}
                                    //     >Export Excel</button>
                                    // }
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text"
                                            className="w-100 form-control"
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(e) => SetSearch(e.target.value)}

                                        />
                                    }
                                    subHeaderAlign="left"

                                />
                            </Row>



                        </Row>
                    </Row>

                </Col>
            </Row>
        </>
    )
}

export default Admincontactus