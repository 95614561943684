import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import Footer from "./Footer"
// import Navigation from "./Navigationnew"
import Navigation from "./Navprastype"
import { Row, Col } from "react-bootstrap"
import { FaCheck } from "react-icons/fa"
//import aboutimg from "./Images/SSIPMT_logo.png"


const Aboutus = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="justify_content_center homfinmgmthead ">
                        ABOUT US
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead justify_content_center">
                        Welcome to Aaradhy Craft: Your Destination for Exquisite Handwoven Silk Sarees
                    </Row>
                    <Row className="abtustext mt-3">
                        At Aaradhy Craft, we are dedicated to preserving the timeless tradition of handcrafted sarees while offering you a modern shopping experience. Our online platform, http://www.aaradhycraft.com/, is your gateway to discovering the unparalleled elegance and craftsmanship of handwoven silk sarees.

                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead justify_content_center">
                        Our Story
                    </Row>
                    <Row className="abtustext mt-3">
                        Established as a Manufacturer unit, Aaradhy Silk Handcraft is nestled in the picturesque town of Sarangarh Bilaigarh, Chhattisgarh. Our journey began with a vision to celebrate the rich heritage of Indian textiles, particularly the artistry of silk weaving, and share it with the world.
                    </Row>

                </Col>
                <Col xs={1}></Col>
            </Row>
            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>
            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead justify_content_center">
                        Exquisite Handwoven Silk Sarees
                    </Row>
                    <Row className="abtustext mt-3">
                        Each saree in our collection is a testament to the skill and dedication of our master weavers. We take pride in sourcing the finest quality silk threads and employing traditional weaving techniques passed down through generations. The result is a range of sarees that exude sophistication, grace, and unparalleled craftsmanship.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead justify_content_center">
                        Our Commitment to Quality and Tradition
                    </Row>
                    <Row className="abtustext mt-3">
                        At Aaradhy Craft, we prioritize quality and authenticity above all else. Every saree is meticulously crafted with attention to detail, ensuring that you receive a masterpiece that reflects the rich cultural heritage of India. We are committed to supporting local artisans and empowering them to preserve their craft for future generations.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead justify_content_center">
                        Explore Our Collection
                    </Row>
                    <Row className="abtustext mt-3">
                        Whether you're searching for a timeless Banarasi silk saree, an intricately woven Kanjeevaram masterpiece, or a contemporary fusion saree, our diverse collection has something for every occasion and style preference. Browse through our website and experience the luxury of handwoven silk sarees from the comfort of your home.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead justify_content_center">
                        Saree Varieties
                    </Row>
                    <Row className=" abtustext justify_content_center mt-3">
                        <Col><FaCheck /> Semi Silk Sarees</Col>
                    </Row>
                    <Row className=" abtustext justify_content_center mt-1">
                        <Col><FaCheck /> Art Silk Sarees</Col>
                    </Row>
                    <Row className=" abtustext justify_content_center mt-1">
                        <Col><FaCheck /> Pure Silk Sarees</Col>
                    </Row>
                    <Row className=" abtustext justify_content_center mt-1">
                        <Col><FaCheck /> Tussar Silk Sarees</Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead justify_content_center">
                        Customer Satisfaction Guaranteed
                    </Row>
                    <Row className="abtustext mt-3">
                        Your satisfaction is our utmost priority. We strive to provide a seamless shopping experience, from browsing our collection to receiving your cherished saree at your doorstep. Our dedicated customer support team is always available to assist you with any queries or concerns you may have.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead justify_content_center">
                        Join Us in Celebrating Tradition
                    </Row>
                    <Row className="abtustext mt-3">
                        We invite you to embark on a journey of elegance and tradition with Aaradhy Craft. Discover the unparalleled beauty of handwoven silk sarees and experience the magic of Indian craftsmanship like never before. Shop now and add a touch of timeless elegance to your wardrobe.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col></Col>
                <Col xs={6} className="homesecondsecborder"></Col>
                <Col></Col>
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="homfinmgmthead justify_content_center">
                        Contact Us
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Rajesh Dewangan<br />H.NO 36,<br /> DURGA MANDIR ROAD,<br />WARD NO. 08, Sarangarh,<br />
                        Sarangarh Bilaigarh, Chhattisgarh,<br />496445
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>

                    <Row className="justify_content_center returnpolicytext mt-3">
                        Experience the allure of handwoven silk sarees at http://www.aaradhycraft.com/.
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>


            <Row className="footermargin  mt-5">
                <Footer />
            </Row>

        </div >
    )
}

export default Aboutus