import React, { useState, useEffect } from "react"
import { Row, Col, Button, Form, Card, ListGroup } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import Url from "./Url"
import logo from "./Images/Aaradhy_logo.gif"
// import Navigation from "./Navigationnew"
import Navigation from "./Navprastype"
import Footer from "./Footer"


const Fail = () => {
    const navigate = useNavigate()
    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]

    

    return (
        <>
            <Row>
                <Navigation />
            </Row>

            <Row className="justify_content_center ">
                            <img src={logo} alt="" className="footerlogo" />
                        </Row>
            <Row className="justify_content_center articleprice  mt-2 ">
            We regret, your transaction was unsuccessful.
            </Row>
            <Row className="justify_content_center articleprice  mt-2">
            If your amount is debited from your account then it will be return in 3-4 business days.                
            </Row>

            <Row className="mt-5">
                <Footer />
            </Row>
        </>
    )
}

export default Fail