import React, { useState, useEffect } from "react"
import { Row, Col, Carousel } from "react-bootstrap"
import { useParams } from "react-router-dom"
import Url from "./Url"
import { Link } from "react-router-dom"
//import DataTable from "react-data-table-component";
import Navigation from "./Navadmin"
import { height } from "@fortawesome/free-solid-svg-icons/fa0"


const Vieworderadmin = () => {

    const { order_id } = useParams()

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    const usertoken = localStorage.getItem("usertoken")
    const email = localStorage.getItem("email")

    const [artcledetails, setArtcledetails] = useState("")
    const [cartdetails, setCartdetails] = useState("")
    const [articlepic, setArticlepic] = useState([])
    const [groupedArticles, setGroupedArticles] = useState({})

    useEffect(() => {
        const fetchCSRFToken = async () => {
            try {
                const response = await fetch('your-laravel-api-url/sanctum/csrf-cookie', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }

                // CSRF token is now set in cookies, and you're ready to make authenticated requests
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCSRFToken();
    }, []); // Run once on component mount


    useEffect(() => {
        const api = url + "getCheckoutByOrder_Id/" + order_id

        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata[0])
                if (actualdata.message != "Fail") {
                    setArtcledetails(actualdata[0])
                    // setArticlepic(actualdata)
                }
            })
    }, [])

    useEffect(() => {
        const api = url + "getCartWithArticleAndPics/" + order_id

        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Fail") {
                    // setCartdetails(actualdata[0])
                    // setArticlepic(actualdata)

                    const grouped = actualdata.reduce((acc, item) => {
                        if (!acc[item.id_1]) {
                            acc[item.id_1] = {
                                details: {
                                    id_1: item.id_1,
                                    article_Id: item.article_Id,
                                    article_name: item.article_name,
                                    article_price: item.article_price,
                                    quantity: item.quantity,
                                    shipping_status: item.shipping_status,
                                    article_Thumbnail: item.article_Thumbnail
                                },
                                pics: []
                            }
                        }
                        if (!acc[item.id_1].pics.includes(item.article_pic)) {
                            acc[item.id_1].pics.push(item.article_pic)
                        }
                        return acc
                    }, {})
                    setGroupedArticles(grouped)
                }
            })
    }, [])

    const shipped = (id_1) => {
        const shipping_status = "Yes"
        const api = url + "updateShippingStatus"
        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${usertoken}`, // Include the bearer token
            },
            body: JSON.stringify({id_1, shipping_status})
        })
            .then((apidata) => {
                //console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata["message"] === "Shipping status updated successfully") {
                    alert("Article shipped")
                    window.location.reload()
                }

            })
    }

    //console.log(cartdetails)

    ///////////////Render from top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <Row className="">
                {/* <Col sm={1}>
                    <Leftvericalmenu />
                </Col> */}
                <Col>
                    <Row><Navigation /></Row>
                    <Row className="appointmentcard mt-2 ">

                        <Row className="appointcenterhead mt-3">

                            <Col sm={1} className="justify_content_left">
                                <Link to="/Dashboardadmin">Dashboard {">"}</Link>
                            </Col>

                            <Col className="justify_content_left">Order details</Col>

                        </Row>
                        <Row className="homereqmsghead justify_content_center mt-0">
                            Order Id: {artcledetails.order_id}
                        </Row>
                        <Row className="articledetailbox mt-2 mb-3">
                            <Row className=" ">
                                <Col sm={1}></Col>
                                <Col sm>
                                    <Row className="homereqmsghead justify_content_center">
                                        Billing
                                    </Row>
                                    <Row>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">First Name:</span>
                                            <span className="homfeaturetext">{artcledetails.b_first_name}</span>
                                        </Col>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">Last Name:</span>
                                            <span className="homfeaturetext">{artcledetails.b_last_name}</span>
                                        </Col>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">Email:</span>
                                            <span className="homfeaturetext">{artcledetails.customer_email}</span>
                                        </Col>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">Mobile No:</span>
                                            <span className="homfeaturetext">{artcledetails.b_phone}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">Address_1:</span>
                                            <span className="homfeaturetext">{artcledetails.b_address_1}</span>
                                        </Col>

                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">City:</span>
                                            <span className="homfeaturetext">{artcledetails.b_city}</span>
                                        </Col>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">District:</span>
                                            <span className="homfeaturetext">{artcledetails.b_address_2}</span>
                                        </Col>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">State:</span>
                                            <span className="homfeaturetext">{artcledetails.b_country}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">Postal Code:</span>
                                            <span className="homfeaturetext">{artcledetails.b_postal_code}</span>
                                        </Col>
                                        <Col sm className="mt-3">

                                        </Col>
                                        <Col sm className="mt-3">

                                        </Col>
                                        <Col sm className="mt-3">

                                        </Col>
                                    </Row>

                                    <Row className="homereqmsghead justify_content_center">
                                        Shipping
                                    </Row>
                                    <Row>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">First Name:</span>
                                            <span className="homfeaturetext">{artcledetails.c_first_name}</span>
                                        </Col>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">Last Name:</span>
                                            <span className="homfeaturetext">{artcledetails.c_last_name}</span>
                                        </Col>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">Mobile No:</span>
                                            <span className="homfeaturetext">{artcledetails.c_phone}</span>
                                        </Col>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">Address_1:</span>
                                            <span className="homfeaturetext">{artcledetails.c_address_1}</span>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">City:</span>
                                            <span className="homfeaturetext">{artcledetails.c_city}</span>
                                        </Col>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">District:</span>
                                            <span className="homfeaturetext">{artcledetails.c_address_2}</span>
                                        </Col>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">State:</span>
                                            <span className="homfeaturetext">{artcledetails.c_country}</span>
                                        </Col>
                                        <Col sm className="mt-3">
                                            <span className="homfeatureheading mx-2">Postal Code:</span>
                                            <span className="homfeaturetext">{artcledetails.c_postal_code}</span>
                                        </Col>
                                    </Row>


                                </Col>
                                <Col sm={1}></Col>
                            </Row>
                            <Row className="homereqmsghead justify_content_center mt-5">
                                Article details
                            </Row>
                            {Object.entries(groupedArticles).map(([id_1, article], index) => (
                                <Row key={id_1} className="mt-4">
                                    <Col sm={1}></Col>
                                    <Col >
                                        <Row className="articleprice justify_content_center">ITEM: {index + 1}</Row>
                                        <Row>
                                            <Col sm className="mt-3">
                                                <span className="homfeatureheading mx-2">Article Id:</span>
                                                <span className="homfeaturetext">{article.details.article_Id}</span>
                                            </Col>
                                            <Col sm className="mt-3">
                                                <span className="homfeatureheading mx-2">Name:</span>
                                                <span className="homfeaturetext">{article.details.article_name}</span>
                                            </Col>
                                            <Col sm className="mt-3">
                                                <span className="homfeatureheading mx-2">Price:</span>
                                                <span className="homfeaturetext">{article.details.article_price}</span>
                                            </Col>
                                            <Col sm className="mt-3">
                                                <span className="homfeatureheading mx-2">Quantity:</span>
                                                <span className="homfeaturetext">{article.details.quantity}</span>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col sm className="mt-3">
                                                <span className="homfeatureheading mx-2">Shipping Status:</span>
                                                <span className="homfeaturetext">
                                                    {article.details.shipping_status === "No" ? "Not shipped" : "Shipped"}
                                                </span>
                                            </Col>
                                            <Col>
                                                {
                                                    article.details.shipping_status === "No" ?
                                                        <button className="homgreqmsgbtn" onClick={()=>shipped(article.details.id_1)}>
                                                            Shipped
                                                        </button>
                                                        : null
                                                }
                                            </Col>

                                        </Row>
                                        <Row className="mt-5">
                                            <Col sm className="mt-3">
                                                <img
                                                    className="adminarticlethumbadmin"
                                                    src={`${s3url}${article.details.article_Thumbnail}`}
                                                    alt="Article thumbnail"
                                                />
                                            </Col>
                                            <Col>
                                                <Carousel fade className="adminarticleviewimgadmin mt-2">
                                                    {article.pics.map((pic, picIndex) => (
                                                        <Carousel.Item key={picIndex} interval='3000'>
                                                            <img
                                                                className="d-block w-100"
                                                                src={`${s3url}${pic}`}
                                                                alt={`Article pic ${picIndex + 1}`}
                                                            />
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                            </Col>
                                        </Row>
                                        {(index !== Object.entries(groupedArticles).length - 1) ? <Row className="adminorderviewseperator mt-5">

                                        </Row> :
                                            <Row className="mb-5">
                                            </Row>}
                                    </Col>
                                    <Col sm={1}></Col>
                                </Row>
                            ))}
                            {/* <Row>
                                <Col sm={1}></Col>
                                <Col sm className="mt-3">
                                    <span className="homfeatureheading mx-2">Artice Id:</span>
                                    <span className="homfeaturetext">{cartdetails.article_Id}</span>
                                </Col>
                                <Col sm className="mt-3">
                                    <span className="homfeatureheading mx-2">Price:</span>
                                    <span className="homfeaturetext">{cartdetails.amount}</span>
                                </Col>
                                <Col sm className="mt-3">
                                    <span className="homfeatureheading mx-2">Quantity:</span>
                                    <span className="homfeaturetext">{cartdetails.quantity}</span>
                                </Col>
                                <Col sm className="mt-3">
                                    <span className="homfeatureheading mx-2">Shipping Status:</span>
                                    <span className="homfeaturetext">{cartdetails.shipping_status === "No" ? "Not shipped" : "Shipped"}</span>
                                </Col>
                                <Col sm={1}></Col>
                            </Row>
                            <Row className="" >
                                <Col sm={1}></Col>
                                <Col className="mt-4">
                                    <Row>
                                        <Col xs={2} className="homfeatureheading mx-2">Thumbnail:</Col>
                                        <Col>
                                            <img
                                                className="adminarticleviewimgadmin"
                                                src={s3url + cartdetails.article_Thumbnail}
                                                alt=""
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="homfeatureheading mt-4">Article pics:</Col>
                                        <Col>
                                            <Carousel fade className="adminarticleviewimgadmin mt-4" >
                                                {articlepic.length > 0 ?
                                                    articlepic.map(item => (
                                                        <Carousel.Item interval='3000'>
                                                            <img
                                                                className="d-block w-100"
                                                                src={s3url + item.article_pic}
                                                                alt="No pic available"
                                                            />
                                                        </Carousel.Item>
                                                    ))
                                                    :
                                                    null
                                                }
                                            </Carousel>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={1}></Col>
                            </Row> */}
                        </Row>

                    </Row>

                </Col>
            </Row>
        </>
    )
}

export default Vieworderadmin