import React, { useState, useEffect } from "react"
import { Row, Col, OverlayTrigger, Tooltip, Modal } from "react-bootstrap"
import Url from "./Url"
import { Link } from "react-router-dom"
import DataTable from "react-data-table-component";
import Navigation from "./Navadmin"
import { IoCloseSharp } from "react-icons/io5"
// import Leftvericalmenu from "./Leftverticalmenu"
// import department_vector from "./Images/department_vector.png"

const Banner = () => {

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    const usertoken = localStorage.getItem("usertoken")

    //const [email, setEmail] = useState("")
    const [banner_name, setBanner_name] = useState("")
    const [bannerstartdate, setBannerstartdate] = useState("")
    const [bannerenddate, setBannerenddate] = useState("")
    const [banner_place, setBanner_place] = useState("")

    const [getbannerbyid, setGetbannerbyid] = useState([])

    const [addbannermodal, setAddbannermodal] = useState(false)
    const [editbannermodal, setEditbannermodal] = useState(false)

    //const [searchcontent, setSearchcontent] = useState("")
    const [search, SetSearch] = useState('');
    const [filter, setFilter] = useState([]);


    const [data, setData] = useState([])

    const column = [
        {
            name: "Banner name",
            selector: row => row.banner_name,
            sortable: true
        },
        {
            name: "Banner place",
            selector: row => row.banner_place,
            sortable: true
        },
        {
            name: "Banner start date",
            selector: row => row.bannerstartdate,
            sortable: true
        },
        {
            name: "Banner end date",
            selector: row => row.bannerenddate,
            sortable: true
        }
        ,
        {
            name: "Status",
            selector: row => row.active,
            sortable: true
        },
        // {
        //     name: "Action",
        //     cell: (row) => (
        //         <button className="btn btn-primary" onClick={() => getbannerdatabyid(row.id)}>Edit</button>
        //     )
        // },
        {
            name: "Action",
            cell: (row) => (
                <button className="btn btn-danger" onClick={() => delbanner(row.id)}>Delete</button>
            )
        }
    ]

    useEffect(() => {
        const fetchCSRFToken = async () => {
            try {
                const response = await fetch('your-laravel-api-url/sanctum/csrf-cookie', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }

                // CSRF token is now set in cookies, and you're ready to make authenticated requests
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCSRFToken();
    }, []); // Run once on component mount

    /*---------------------Add banner --------------------- */

    const addbanner = e => {
        e.preventDefault()

        const api = url + "createBanner"
        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${usertoken}`, // Include the bearer token
            },
            body: JSON.stringify({ banner_name, bannerstartdate, bannerenddate, banner_place })
        })
            .then((apidata) => {
                //console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata["message"] === "success") {
                    alert("Banner created successfully")
                    setAddbannermodal(false)
                    window.location.reload();
                }

            })
    }

    useEffect(() => {
        const api = url + "getAllBanner"

        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata.message)
                if (actualdata.message != "No_Recored_Found") {
                    setData(actualdata)
                    setFilter(actualdata)
                }
            })
    }, [])

    const getbannerdatabyid = (id) => {
        const api = url + "getBannerById/" + id
        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata1) => {
                return apidata1.json()
            })
            .then((actualdata1) => {
                //console.log(actualdata1)
                if (actualdata1["message"] !== "Fail") {
                    setGetbannerbyid(actualdata1)
                    setEditbannermodal(true)
                }
            }
            )
    }

    const updatebanner = (id) => {

        const api = url + "updateBanner/" + id

        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${usertoken}`, // Include the bearer token
            },
            body: JSON.stringify({ banner_name, bannerstartdate, bannerenddate })
        })
            .then((apidata) => {
                //console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata["message"] === "success") {
                    alert("banner Updated successfully")
                    setEditbannermodal(false)
                    window.location.reload()
                }

            })
    }

    const delbanner = (id) => {

        const api = url + "delBannerById/" + id

        var res = window.confirm("You want to delete this banner, are you sure?")
        if (res) {
            fetch(api, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${usertoken}`, // Include the bearer token
                },
            })
                .then((actualdata) => {
                    return actualdata.json()
                })
                .then((actualdata1) => {
                    //console.log(actualdata1)
                    if (actualdata1["message"] === "success") {
                        alert("banner Deleted")
                        window.location.reload()
                    }
                })
        }
    }

    useEffect(() => {
        const result = data.filter((item) => {
            return item.banner_name.toLowerCase().match(search.toLocaleLowerCase());
        });
        setFilter(result);
    }, [search]);


    const tableHeaderstyle = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                backgroundColor: "#ccc"

            },
        },
    }

    /*--------------------------- Generate CSV ---------------------------*/
    // const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //     //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    //     var arrData =
    //         typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    //     var CSV = "";

    //     //This condition will generate the Label/Header
    //     if (ShowLabel) {
    //         var row = "";

    //         //This loop will extract the label from 1st index of on array
    //         for (var index in arrData[0]) {
    //             //Now convert each value to string and comma-seprated
    //             row += index + ",";
    //         }

    //         row = row.slice(0, -1);

    //         //append Label row with line break
    //         CSV += row + "\r\n";
    //     }

    //     //1st loop is to extract each row
    //     for (var i = 0; i < arrData.length; i++) {
    //         var row = "";

    //         //2nd loop will extract each column and convert it in string comma-seprated
    //         for (var index in arrData[i]) {
    //             row += '"' + arrData[i][index] + '",';
    //         }

    //         row.slice(0, row.length - 1);

    //         //add a line break after each row
    //         CSV += row + "\r\n";
    //     }

    //     if (CSV === "") {
    //         alert("Invalid data");
    //         return;
    //     }

    //     //Generate a file name
    //     var fileName = "";
    //     //this will remove the blank-spaces from the title and replace it with an underscore
    //     fileName += ReportTitle.replace(/ /g, "_");

    //     //Initialize file format you want csv or xls
    //     var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    //     // Now the little tricky part.
    //     // you can use either>> window.open(uri);
    //     // but this will not work in some browsers
    //     // or you will not get the correct file extension

    //     //this trick will generate a temp <a /> tag
    //     var link = document.createElement("a");
    //     link.href = uri;

    //     //set the visibility hidden so it will not effect on your web-layout
    //     link.style = "visibility:hidden";
    //     link.download = fileName + ".csv";

    //     //this part will append the anchor tag and remove it after automatic click
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };
    /*--------------------------- Generate CSV ---------------------------*/


    ///////////////Render from top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {/*-----------------Add banner modal--------------------*/}
            <Modal
                show={addbannermodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalbg"
            >
                <Row className="justify_content_center">
                    <Col className="appointmodalhead"></Col>
                    <Col xs={1} className="appointmodalhead"
                        onClick={() => { setAddbannermodal(false) }}
                        style={{ cursor: "pointer", marginLeft: "-100px" }}
                    >
                        <IoCloseSharp />
                    </Col>
                </Row>
                <Row className="deptmodalhead">
                    <Col>Add New banner</Col>
                </Row>
                <Modal.Body>

                    <Row>

                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Banner Name*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setBanner_name(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Banner Place*
                            </Row>
                            <Row >
                                <select type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setBanner_place(e.target.value) }}
                                >
                                    <option value="">Select</option>
                                    <option value="Homepage">Homepage</option>
                                    <option value="See More page banner 1">See More page Banner 1</option>
                                    <option value="See More page banner 2">See More page Banner 2</option>
                                </select>
                            </Row>
                        </Col>

                    </Row>

                    <Row>

                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Banner start date*
                            </Row>
                            <Row >
                                <input type="date" placeholder="Enter Password" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setBannerstartdate(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col sm className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Banner end date*
                            </Row>
                            <Row >
                                <input type="date" placeholder="Enter mobile no" className="createdeptmodalinput"
                                    required
                                    onChange={(e) => { setBannerenddate(e.target.value) }}
                                />
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-4 mb-3">
                        <Col className="justify_content_center">
                            <button className="serachdeptbtn"
                                onClick={addbanner}
                            >Create</button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            {/*-----------------Add banner modal end--------------------*/}

            {/*-----------------Edit banner modal--------------------*/}
            <Modal
                show={editbannermodal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalbg"
            >
                <Row className="justify_content_center">
                    <Col className="appointmodalhead"></Col>
                    <Col xs={1} className="appointmodalhead"
                        onClick={() => { setEditbannermodal(false) }}
                        style={{ cursor: "pointer", marginLeft: "-100px" }}
                    >
                        <IoCloseSharp />
                    </Col>
                </Row>
                <Row className="deptmodalhead">
                    <Col>Edit New banner</Col>
                </Row>
                <Modal.Body>

                    <Row>

                        <Col className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Name*
                            </Row>
                            <Row >
                                <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                    defaultValue={getbannerbyid.banner_name}
                                    onChange={(e) => { setBanner_name(e.target.value) }}
                                />
                            </Row>
                        </Col>
                    </Row>

                    <Row>

                        <Col className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Banner start date*
                            </Row>
                            <Row >
                                <input type="date" placeholder="Enter start date" className="createdeptmodalinput"
                                    defaultValue={getbannerbyid.banner_address}
                                    onChange={(e) => { setBannerstartdate(e.target.value) }}
                                />
                            </Row>
                        </Col>
                        <Col className="appointcentercols">
                            <Row className="appointsummarytype mt-2">
                                Banner End date*
                            </Row>
                            <Row >
                                <input type="date" placeholder="Enter end date" className="createdeptmodalinput"
                                    defaultValue={getbannerbyid.banner_area}
                                    onChange={(e) => { setBannerenddate(e.target.value) }}
                                />
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-4 mb-3">
                        <Col className="justify_content_center">
                            <button className="serachdeptbtn"
                                onClick={() => { updatebanner(getbannerbyid.id) }}
                            >Update</button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
            {/*-----------------Edit banner modal end--------------------*/}



            <Row className="">
                {/* <Col sm={1}>
                    <Leftvericalmenu />
                </Col> */}
                <Col>
                    <Row><Navigation /></Row>
                    <Row className="appointmentcard mt-2 ">

                        <Row className="appointcenterhead mt-3">

                            <Col sm={1} className="justify_content_left">
                                <Link to="/Dashboardadmin">Dashboard {">"}</Link>
                            </Col>

                            <Col className="justify_content_left">Banner</Col>
                            <Col className="justify_content_right">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add Banner</Tooltip>}>
                                    <span className="d-inline-block">
                                        <button className="homgreqmsgbtn"
                                            onClick={() => { setAddbannermodal(true) }}
                                        >
                                            Add Banner
                                            {/* <BiPlusMedical /> */}
                                        </button>
                                    </span>
                                </OverlayTrigger>
                            </Col>
                        </Row>

                        <Row>

                            <Col className="appointcentercols jus">
                                {/*<Row className="deptinputhead mt-5">
                                    <Col>Department Name</Col>
    </Row>*/}

                                <Row className=" mt-3">
                                    {/* <Col>
                                        <input type="text" className="createdeptinput" placeholder="Search banner"
                                        //onChange={handleFilter}

                                        />
                                    </Col>
                                    <Col>
                                        <button className="serachdeptbtn"

                                        >Search</button>
                                    </Col> */}
                                    {/* <Col>
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add New banner</Tooltip>}>
                                            <span className="d-inline-block">
                                                <button className="adddeptbtn"
                                                    onClick={() => { setAddbannermodal(true) }}
                                                >
                                                    Add new banner
                                                    
                                                </button>
                                            </span>
                                        </OverlayTrigger>
                                    </Col> */}
                                </Row>
                            </Col>

                            <Col sm={5} className="justify_content_center">
                                {/* <img src={department_vector} alt="" className="deptvector" /> */}
                            </Col>
                        </Row>
                        { /*<Row className="mt-4 mb-3">
                            <Col className="jstfy_all_cntr">
                                <button className="appointmentcreatebtn"
                                    onClick={createDepartment}
                                >Create</button>
                            </Col>
    </Row>*/}

                        {/* <Row className="deptmodalhead">
                            <Col>Add New banner</Col>
                        </Row>


                        <Row>
                            <Col sm={1}></Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Name*
                                </Row>
                                <Row >
                                    <input type="text" placeholder="Enter Name" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setName(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Email Id*
                                </Row>
                                <Row >
                                    <input type="email" placeholder="Enter Email" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setEmail(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                        <Row>
                            <Col sm={1}></Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Password*
                                </Row>
                                <Row >
                                    <input type="password" placeholder="Enter Password" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setPassword(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Mobile No.*
                                </Row>
                                <Row >
                                    <input type="text" placeholder="Enter mobile no" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setMobileno(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                        <Row>
                            <Col sm={1}></Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Address*
                                </Row>
                                <Row >
                                    <input type="text" placeholder="Enter Address" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setAddress(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Area*
                                </Row>
                                <Row >
                                    <input type="text" placeholder="Enter Area" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setArea(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                        <Row>
                            <Col sm={1}></Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Joining Date*
                                </Row>
                                <Row >
                                    <input type="date" placeholder="Enter Joing Date" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setJdate(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col className="appointcentercols">
                                <Row className="appointsummarytype mt-2">
                                    Remarks*
                                </Row>
                                <Row >
                                    <input type="text" placeholder="Enter Renarks" className="createdeptmodalinput"
                                        required
                                        onChange={(e) => { setRemark(e.target.value) }}
                                    />
                                </Row>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                        <Row className="mt-4 mb-3">
                            <Col className="justify_content_center">
                                <button className="serachdeptbtn"
                                    onClick={addbanner}
                                >Create</button>
                            </Col>
                        </Row> */}

                        <Row className="depttablebox mt-5 mb-3">
                            <Row className="homereqmsghead justify_content_center">banners</Row>
                            <Row>
                                <DataTable
                                    customStyles={tableHeaderstyle}
                                    columns={column}
                                    data={filter}
                                    pagination
                                    //selectableRows
                                    fixedHeader
                                    selectableRowsHighlight
                                    highlightOnHover
                                    // actions={
                                    //     <button className="btn btn-success"
                                    //         onClick={() => JSONToCSVConvertor(data, "_banner List", true)}
                                    //     >Export Excel</button>
                                    // }
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text"
                                            className="w-100 form-control"
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(e) => SetSearch(e.target.value)}

                                        />
                                    }
                                    subHeaderAlign="left"

                                />
                            </Row>
                            {/* <div className="deptheadbg">
                                <Row>
                                    <DataTable
                                        columns={column}
                                        data={data}
                                        selectableRows
                                        fixedHeader
                                        pagination
                                    />
                                </Row>
                            </div> */}

                            {/* <div className="deptheadbg">
                                <Row>
                                    
                                    <Col  className="deptlisthead justify_content_center">S.No.</Col>
                                    <Col  className="deptlisthead justify_content_center">Name</Col>
                                    
                                    <Col  className="deptlisthead justify_content_center">Email</Col>
                                    <Col  className="deptlisthead justify_content_center">Mobile no</Col>
                                    <Col  className="deptlisthead justify_content_center">Area</Col>
                                    <Col  className="deptlisthead justify_content_center">Address</Col>
                                    <Col  className="deptlisthead justify_content_center">Joining Date</Col>
                                    <Col  className="deptlisthead justify_content_center">
                                        Edit
                                    </Col>
                                    <Col  className="deptlisthead justify_content_center">
                                        Delete
                                    </Col>
                                    
                                </Row>
                            </div> */}

                            {/* <Row className=" mt-1 mb-3">
                                {
                                    data.length ?
                                        data.filter((item) => {
                                            if (searchcontent === null) { return item }
                                            else if (item.banner_name.toLowerCase().includes(searchcontent.toLowerCase())) { return item }{console.log(item)}
                                        })
                                            .map((item, srno) => (
                                                <Row className=" mt-1 mb-2" >
                                                    

                                                    <Col  className="deptlistitem  justify_content_center">{srno + 1}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.banner_name}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.banner_email}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.banner_mob}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.banner_area}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.banner_address}</Col>
                                                    <Col  className="deptlistitem justify_content_center">{item.banner_joindt}</Col>
                                                    <Col  className="editicon justify_content_center">
                                                        <FaEdit className="deleteicon justify_content_center"
                                                        //onClick={() => { getDeptbyid(item.id) }}
                                                        />
                                                    </Col>
                                                    <Col  className="deleteicon justify_content_center">
                                                        <RiDeleteBinLine className="deleteicon justify_content_center"
                                                        //onClick={() => { delDept(item.id) }}
                                                        />
                                                    </Col>
                                                    
                                                </Row>
                                            ))
                                        :
                                        null
                                }
                            </Row> */}


                        </Row>
                    </Row>

                </Col>
            </Row>
        </>
    )
}

export default Banner