import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Navigation from "./Navprastype";
import { Row, Col, Modal } from "react-bootstrap";
import { FaFilter, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Url from './Url';
import Saree1 from "./Images/Dummy saree/Saree3.jpg";
import Bottomawards from "./Images/Bottom awads.png";
import Sareecard1 from "./Images/Dummy saree/sareecard1.png"
import Sareecard2 from "./Images/Dummy saree/sareecard2.png"
import Sareecard3 from "./Images/Dummy saree/sareecard3.png"

const Alllistingpagination = () => {
    const url1 = Url();
    const url = url1["url"];
    const s3 = url1["s3"];
    const [s3url] = useState(s3);

    // States
    const [getarticle, setGetarticle] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [priceRange, setPriceRange] = useState([0, 25000]);
    const [showfilter, setShowfilter] = useState(false);
    const [welcomemodal, setWelcomemodal] = useState(true);
    const [banner1, setBanner1] = useState("")
    const [banner2, setBanner2] = useState("")

    const [popupdata, setPopupdata] = useState("")
    const [getart_cat_active, setGetart_cat_active] = useState("")

    const [mobilescr, setMobilescr] = useState(false);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 10;

    useEffect(() => {
        window.scrollTo(0, 0);
        if (window.innerWidth <= 500) {
            setMobilescr(true);
        }
    }, []);

    useEffect(() => {
        const api = url + "getActiveBannerByPlace"
        fetch(api, {
            method: 'GET',

        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata[0]["banner_name"])
                if (actualdata.message != "No active banner found for the given place.") {
                    setBanner1(actualdata[0]["banner_name"])
                    setBanner2(actualdata[1]["banner_name"])
                }

            })
    }, [])

    useEffect(() => {
        const api = url + "getpop_upByStatus"
        fetch(api, {
            method: 'GET',

        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                // console.log(actualdata)
                if (actualdata.message != "Fail") {
                    setPopupdata(actualdata)

                }
            })
    }, [])

    useEffect(() => {
        const api = url + "getpop_upByStatus"
        fetch(api, {
            method: 'GET',

        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                // console.log(actualdata)
                if (actualdata.message != "Fail") {
                    getart_cat_active(actualdata)

                }
            })
    }, [])

    useEffect(() => {
        const api = url + "getarticle_category_active"
        fetch(api, {
            method: 'GET',

        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                // console.log(actualdata)
                if (actualdata.message != "Fail") {
                    setGetart_cat_active(actualdata)

                }
            })
    }, [])



    useEffect(() => {
        fetch(`${url}getArticle`)
            .then((response) => response.json())
            .then((data) => {
                if (data.message !== "Fail") {
                    setGetarticle(data);
                }
            });
    }, [url]);

    useEffect(() => {
        const filtered = getarticle.filter(
            article => article.article_price >= priceRange[0] && article.article_price <= priceRange[1]
        );
        setFilteredArticles(filtered);
        setCurrentPage(1); // Reset to first page when filters change
    }, [priceRange, getarticle]);

    // Pagination Logic
    const totalPages = Math.ceil(filteredArticles.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentItems = filteredArticles.slice(startIndex, endIndex);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const PaginationControls = () => (
        <div className="d-flex justify-content-center align-items-center gap-3 my-4">
            <button
                className="btn btn-outline-secondary"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                <FaChevronLeft />
            </button>

            <div className="d-flex gap-2">
                {Array.from({ length: totalPages }, (_, i) => i + 1)
                    .filter(pageNum => {
                        // Show first page, last page, current page, and pages around current page
                        return pageNum === 1 ||
                            pageNum === totalPages ||
                            (pageNum >= currentPage - 1 && pageNum <= currentPage + 1);
                    })
                    .map((pageNum, index, array) => (
                        <React.Fragment key={pageNum}>
                            {index > 0 && array[index - 1] !== pageNum - 1 && (
                                <span className="mx-1">...</span>
                            )}
                            <button
                                className={`btn ${currentPage === pageNum ? 'btn-primary' : 'btn-outline-secondary'}`}
                                onClick={() => handlePageChange(pageNum)}
                            >
                                {pageNum}
                            </button>
                        </React.Fragment>
                    ))}
            </div>

            <button
                className="btn btn-outline-secondary"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                <FaChevronRight />
            </button>
        </div>
    );

    const addToRecentlyViewed = (article) => {
        const recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
        const updatedRecentlyViewed = [article, ...recentlyViewed.filter(item => item.id !== article.id)].slice(0, 8);
        localStorage.setItem('recentlyViewed', JSON.stringify(updatedRecentlyViewed));
    };

    const formatProductName = (name) => {
        const words = name.split(' ');
        return words.length <= 6 ? name.toUpperCase() : `${words.slice(0, 6).join(' ')}...`.toUpperCase();
    };

    return (
        <>
            <Navigation />

            {/* Welcome Modal */}
            <Modal size="lg" show={welcomemodal} onHide={() => setWelcomemodal(false)} centered>
                <Modal.Header closeButton></Modal.Header>
                <Row>
                    <Col sm={6} className=" justify_content_center">
                        <img src={s3url + popupdata.image} alt="" className="welcomemodalimg mb-4 mx-2" />
                    </Col>
                    <Col sm={6} className="">
                        {/* <Row className="welcome-heading">Get new arrivals in your <b>inbox!</b></Row> */}
                        <Row className="welcome-heading mx-3">{popupdata.text_1===""?"Welcome to Aaradhy Silk Hand Craft": popupdata.text_1}</Row>
                        <Row className="welcome-subheading mt-3 mx-3 mb-3">{popupdata.text_2===""?"Hand Weaving Saree": popupdata.text_2}</Row>
                        {/* <Row className="appointsummarytype mt-4">Email*</Row>
                        <Row className="" style={{ marginRight: "20px" }}>
                            <input type="email" placeholder="Enter Email" className="emailinput" required />
                        </Row>
                        <Row className="justify_content_left mt-4 mb-3">
                            <button className="homgreqmsgbtn">Subscribe</button>
                        </Row> */}
                    </Col>
                </Row>
            </Modal>

            {/* Banner */}
            <Row className="mt-2 mb-0">
                <Col xs className="justify_content_center homecounterbg">
                    <Col xs className="alllistfirstadd justify-content-center "
                    // data-aos="fade-right"
                    >
                        <Col >
                            <Row className="listpagebanner1 justify-content-center mt-2  mb-4 mx-3">
                                {banner1===""?"Welcome to Aaradhy Silk Hand Craft":banner1}
                            </Row>
                        </Col>
                    </Col>
                </Col>
            </Row>
            <Row className="mt-0 mb-0">
                <Col xs className="justify_content_center homecounterbg">
                    <Col xs className="alllistsecondadd justify-content-center "
                    // data-aos="fade-left"
                    >
                        <Col >
                            <Row className="listpagebanner2 justify-content-center mt-2  mb-4 mx-3">
                            {banner2===""?"Hand Weaving Saree":banner2}
                            </Row>
                        </Col>
                    </Col>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col sm={3}></Col>
                {getart_cat_active.length > 0 ?
                    getart_cat_active.map(item => (
                        <Col className="">
                            <Row className=" justify_content_center align_vertical_center">
                                <img src={s3url + item.article_category_image} alt="" className="sareesamplecardsaree" />
                            </Row>
                            <Row className="homecountercardtext justify_content_center mt-2">
                                {item.article_category_name}
                            </Row>
                        </Col>))
                    :
                    null
                }
                {/* <Col className="">
                    <Row className=" justify_content_center align_vertical_center">
                        <img src={Sareecard1} alt="" className="sareesamplecardsaree" />
                    </Row>
                    <Row className="homecountercardtext justify_content_center mt-2">
                        Hand Weaving Saree
                    </Row>
                </Col>
                <Col className="">
                    <Row className=" justify_content_center align_vertical_center">
                        <img src={Sareecard2} alt="" className="sareesamplecardsaree" />
                    </Row>
                    <Row className="homecountercardtext justify_content_center mt-2">
                        Hand Weaving Salwar Suit
                    </Row>
                </Col>
                <Col className="">
                    <Row className=" justify_content_center align_vertical_center">
                        <img src={Sareecard3} alt="" className="sareesamplecardsaree" />
                    </Row>
                    <Row className="homecountercardtext justify_content_center mt-2">
                        Hand Weaving Kurta
                    </Row>
                </Col> */}
                <Col sm={3}></Col>
            </Row>

            {/* Main Content */}
            <div className={`homecounterbg ${!mobilescr ? 'mx-3' : ''} mb-5`}>
                <Row>
                    {/* Filter Section */}
                    {(!mobilescr || showfilter) && (
                        <Col sm={mobilescr ? 12 : 3}>
                            <div className="filter-section mx-5">
                                <h3 className="filter-heading">Filter</h3>
                                <div className="price-filter">
                                    <h4 className="price-heading">Price Range</h4>
                                    <div className="price-labels">
                                        <span>₹{priceRange[0]}</span>
                                        <span>₹{priceRange[1]}</span>
                                    </div>
                                    <input
                                        type="range"
                                        min="0"
                                        max="25000"
                                        value={priceRange[1]}
                                        onChange={(e) => setPriceRange([priceRange[0], parseInt(e.target.value)])}
                                        className="price-slider"
                                    />
                                </div>
                            </div>
                        </Col>
                    )}

                    {/* Articles Grid */}
                    <Col>
                        {mobilescr && (
                            <Row className="mt-2">
                                <Col xs={2} className="ms-auto">
                                    <FaFilter onClick={() => setShowfilter(!showfilter)} />
                                </Col>
                            </Row>
                        )}

                        <Row className="justify-content-center">
                            {currentItems.length > 0 ? (
                                currentItems.map(item => (
                                    <Col
                                        key={item.id}
                                        xs={mobilescr ? 5 : 2}
                                        className="homecountercardalllistbhiya justify_content_center mt-5"
                                    >
                                        <Link to={"/Viewarticle/" + item.id + "/" + item.article_name} onClick={() => addToRecentlyViewed(item)}>
                                            <Col>
                                                <Row className="justify-content-center">
                                                    <img
                                                        src={s3url + item.article_Thumbnail}
                                                        alt=""
                                                        className="featuresimgbhiya"
                                                    />
                                                </Row>
                                                <Row className="homecountercardtext justify-content-center mt-2">
                                                    {mobilescr ? formatProductName(item.article_name) : item.article_name}
                                                </Row>
                                                <Row className="homecountercardtext justify-content-center mt-2">
                                                    ₹{item.article_price}
                                                </Row>
                                            </Col>
                                        </Link>
                                    </Col>
                                ))
                            ) : (
                                <Col className="text-center mt-5">
                                    No articles found in this price range.
                                </Col>
                            )}
                        </Row>

                        {/* Pagination Controls */}
                        {filteredArticles.length > ITEMS_PER_PAGE && <PaginationControls />}
                    </Col>
                </Row>
            </div>

            {/* Footer Section */}
            <hr />
            <div className="justify_content_center mt-5 mb-3">
                <img src={Bottomawards} alt="" className="bootomawardsimg" />
            </div>
            <Row className="footermargin">
                <Footer />
            </Row>
        </>
    );
};

export default Alllistingpagination;