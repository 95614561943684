import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Row, Col, Navbar, Nav, Modal, Container, Form, Button, NavDropdown, Offcanvas } from "react-bootstrap"
// import logo from "./Images/Aaradhy_logo_small.png"
import logo from "./Images/Aaradhy_logo.gif"
//import logo from "./Images/Ishaan_imgs/beamslogonew.png"
import menu from "./Images/mobile_menu_icon.svg"
import { RiArrowDropDownLine, RiDeleteBinLine } from "react-icons/ri"
import { FaUserAlt, FaSistrix, FaSearch, FaShoppingCart } from "react-icons/fa"
import Url from "./Url"
//import { Next } from "react-bootstrap/esm/PageItem"

const Navprastype = () => {

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    const [showsearchmodal, setShowsearchmodal] = useState(false)
    const [showsearchbarmob, setShowsearchbarmob] = useState(false)
    const [showlogin, setShowlogin] = useState(false)

    const [email, setEmail] = useState("")
    const [mobileno, setMobileno] = useState("")
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [isSent, setIsSent] = useState(false)
    // const [colorChange, setColorchange] = useState(false);
    const [mobilescr, setMobilescr] = useState(false)
    const [getdevwidth, setGetdevwidth] = useState("")

    const [newuser, setNewuser] = useState(false)

    const [navshow, setNavshow] = useState(false);

    const handleClose = () => setNavshow(false);
    const handleShow = () => setNavshow(true);

    useEffect(() => {
        setGetdevwidth(window.innerWidth + 'px')
        if (window.innerWidth <= 500) {
            setMobilescr(true)
        }
        //console.log(getdevwidth)
    }, [getdevwidth])

    // window.addEventListener('scroll', changeNavbarColor);

    /*---------------------Login --------------------- */

    const login = e => {
        e.preventDefault()
        const controller = "login"
        const api = url + controller
        //var api = "http://127.0.0.1:8000/api/login"
        fetch(api, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata.token != "NA") {
                    localStorage.setItem("usertoken", actualdata.token)
                    localStorage.setItem("email", email)
                    if (actualdata.role === "admin")
                        setIsSent(true)
                    // if (actualdata.role === "user")
                    //setSentuser(true)
                }
                if (actualdata.token === "NA") {
                    alert("Invalid Email or password")
                    // toast.error('Invalid Email or password', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                }

            })
    }

    if (isSent) {
        ///window.location = "#/Dashboardadmin"
    }

    /*---------------------Login end--------------------- */

    /*---------------------Register --------------------- */

    const register = e => {
        e.preventDefault()
        const controller = "createUser"
        const api = url + controller
        //var api = "http://127.0.0.1:8000/api/login"
        fetch(api, {
            method: 'POST',
            body: JSON.stringify({ email, name, password }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata["Message"] === "User_created") {
                    localStorage.setItem("usertoken", actualdata.token)
                    localStorage.setItem("email", email)
                    setNewuser(false)
                    //setIsSent(true)
                }
                else {
                    alert("Registration fail")

                }
            })
    }

    if (isSent) {
        window.location = "#/Dashboardadmin"
    }

    /*---------------------Register end --------------------- */

    const [cartCount, setCartCount] = useState(0);
    const [showCart, setShowCart] = useState(false);
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        const updateCartCount = () => {
            const count = localStorage.getItem('cartCount') || '0';
            setCartCount(parseInt(count));
            const items = JSON.parse(localStorage.getItem('cartItems')) || [];
            setCartItems(items);
        };

        updateCartCount();
        window.addEventListener('cartUpdated', updateCartCount);

        return () => {
            window.removeEventListener('cartUpdated', updateCartCount);
        };
    }, []);

    const handleCartClick = () => {
        setShowCart(true);
    };

    const handleCloseCart = () => {
        setShowCart(false);
    };

    const removeFromCart = (id) => {
        const updatedItems = cartItems.filter(item => item.id !== id);
        localStorage.setItem('cartItems', JSON.stringify(updatedItems));
        const newCount = updatedItems.reduce((total, item) => total + item.quantity, 0);
        localStorage.setItem('cartCount', newCount.toString());
        setCartItems(updatedItems);
        setCartCount(newCount);
    };

    // useEffect(() => {
    //     // Custom tracking function
    //     const trackPageView = () => {
    //         const pixelId = '1288056515520748';
    //         const event = 'PageView';
            
    //         // Create a minimal payload that doesn't include sensitive information
    //         const payload = {
    //             id: pixelId,
    //             ev: event,
    //             dl: window.location.origin, // Only send the origin, not the full URL
    //             r: document.referrer ? new URL(document.referrer).origin : '', // Only send the referrer's origin if available
    //             ts: Date.now(),
    //         };

    //         // Convert payload to query string
    //         const queryString = Object.keys(payload)
    //             .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`)
    //             .join('&');

    //         // Send the request
    //         const url = `https://www.facebook.com/tr?${queryString}`;
            
    //         fetch(url, {
    //             mode: 'no-cors',
    //             credentials: 'omit',
    //             referrerPolicy: 'strict-origin-when-cross-origin',
    //             cache: 'no-cache',
    //         }).catch(() => {
    //             // Silently fail if the request doesn't go through
    //             console.warn('Tracking request failed to send.');
    //         });
    //     };

    //     // Track page view when component mounts
    //     trackPageView();

    //     // You can add more tracking functions here if needed

    // }, []);

    return (

        <div className="">

            {/* Facebook Pixel noscript */}
            {/* <noscript>
                <img 
                    height="1" 
                    width="1" 
                    style={{display: 'none'}}
                    src="https://www.facebook.com/tr?id=1288056515520748&ev=PageView&noscript=1"
                    alt="Facebook Pixel"
                />
            </noscript> */}

            <Modal
                size="lg"
                show={showsearchmodal}
                onHide={() => setShowsearchmodal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Row>
                    <Col xs={1}></Col>
                    <Col>
                        <Row className=" mb-2" style={{ display: "block" }}>
                            {/* <Col xs={8} className="justify_content_left mb-5">
                                <input className="floating-input" type="text" placeholder="Search here" />
                            </Col>
                            <Col xs={2} lassName="justify_content_right" style={{alignItems: "center"}}>
                                <Row>
                                    <button className="navsearchmodalbtn">Search</button>
                                </Row>
                                </Col> */}
                            <Col className=" mb-5">
                                <span >
                                    <FaSearch className="homnavsrchicon" />
                                </span>
                                <span>
                                    <input type="text" className="navsearchmodalinput" placeholder="" />
                                </span>
                                <span className="mx-2">
                                    <button className="navsearchmodalbtn">Search</button>

                                </span>
                            </Col>
                            {/* <Col sm={2} lassName="justify_content_right" style={{ alignItems: "center" }}>
                                
                            </Col> */}
                        </Row>
                        {/* <Row className="justify_content_center mt-5">
                                
                            </Row> */}
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </Modal >

            <Modal
                size="lg"
                show={showlogin}
                onHide={() => setShowlogin(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Row>
                    <Col xs={1}></Col>
                    {
                        !newuser ?
                            <>
                                <Col sm className="justify_content_center align_vertical_center">
                                    <img src={logo} alt="" className="" width={300} />
                                </Col>
                                <Col sm>
                                    <form onSubmit={login}>
                                        <Row className="homloginrect mb-5" >
                                            <Col className="mt-0">

                                                <Row className="homereqmsghead justify_content_center mt-4">
                                                    Login
                                                </Row>
                                                <Row className="floating-label mt-4">
                                                    <input className="floating-input" type="email" placeholder=" "
                                                        required
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                    <span className="highlight"></span>
                                                    <label>Email *</label>
                                                </Row>
                                                <Row className="floating-label mt-4">
                                                    <input className="floating-input" type="password" placeholder=" "
                                                        required
                                                        onChange={e => setPassword(e.target.value)}
                                                    />
                                                    <span className="highlight"></span>
                                                    <label>Password *</label>
                                                </Row>
                                                <Row className="justify_content_left mt-5">
                                                    <Col>
                                                        <button type="submit" className="homgloginbtn">Submit</button>
                                                    </Col>
                                                    <Col className="newusertext justify_content_center align_vertical_center" onClick={() => setNewuser(true)}>
                                                        New User?
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </>
                            :
                            <>
                                <Col sm className="justify_content_center align_vertical_center">
                                    <img src={logo} alt="" className="" width={300} />
                                </Col>
                                <Col sm>
                                    <form onSubmit={register}>
                                        <Row className="homloginrect  mb-5" >
                                            <Col className="mt-0">

                                                <Row className="homereqmsghead justify_content_center mt-4">
                                                    Register
                                                </Row>
                                                <Row className="floating-label mt-4">
                                                    <input className="floating-input" type="email" placeholder=" "
                                                        //pattern="[1-9]{1}[0-9]{9}"
                                                        title="Please enter 10 digit mobile no...."
                                                        required
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                    <span className="highlight"></span>
                                                    <label>Email *</label>
                                                </Row>
                                                <Row className="floating-label mt-4">
                                                    <input className="floating-input" type="text" placeholder=" "
                                                        pattern="[a-zA-Z\s]+"
                                                        title="Please enter valid name. No special character or numerics values are allowed...."
                                                        required
                                                        onChange={e => setName(e.target.value)}
                                                    />
                                                    <span className="highlight"></span>
                                                    <label>Name *</label>
                                                </Row>
                                                <Row className="floating-label mt-4">
                                                    <input className="floating-input" type="password" placeholder=" "
                                                        required
                                                        onChange={e => setPassword(e.target.value)}
                                                    />
                                                    <span className="highlight"></span>
                                                    <label>Password *</label>
                                                </Row>
                                                <Row className="justify_content_left mt-5">
                                                    <Col>
                                                        <button className="homgloginbtn">Submit</button>
                                                    </Col>
                                                    <Col className="newusertext justify_content_center align_vertical_center" onClick={() => setNewuser(false)}>
                                                        Already registered?
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </>
                    }
                    <Col xs={1}></Col>
                </Row >
            </Modal >

            <Modal show={showCart} onHide={handleCloseCart}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Your Cart</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify_content_center mt-0" >
                        <img src={logo} alt="" className="cartlogo" />
                    </Row>
                    <Row className="homereqmsghead justify_content_center mt-4">
                        Your Cart
                    </Row>
                    <Row>
                        {cartItems.length === 0 ? (
                            <p>Your cart is empty</p>
                        ) : (
                            cartItems.map((item) => (
                                <div key={item.id} className="cart-item">
                                    <img src={s3url + item.thumbnail} alt={item.name} className="cart-item-image" />
                                    <div className="cart-item-details">
                                        <h5>{item.name}</h5>
                                        <p>Price: ₹{item.price}</p>
                                        <p>Quantity: {item.quantity}</p>
                                    </div>
                                    <button onClick={() => removeFromCart(item.id)} className="remove-item"><RiDeleteBinLine /></button>
                                </div>
                            ))
                        )}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCart}>
                        Close
                    </Button>
                    <Link to="/Checkout">
                        <Button variant="primary">
                            Checkout
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>

            {!mobilescr ?
                <>
                    <Row bfixed="top" className="navbg_white">
                        <Row className="navtopbg">
                            <Col sm={2} className="justify_content_left align_vertical_center">
                                <Link to="/">
                                    <img src={logo} alt="Aardhy_logo" className="rotatinglogo " />
                                </Link>
                            </Col>
                            <Col className="justify_content_left align_vertical_center">
                                <Row className=" " style={{ display: "block" }}>
                                    <Col className=" ">
                                        <span>
                                            <input type="text" className="navsearchmodalinput mx-0" placeholder="What are you looking for?" />
                                        </span>
                                        <span className="">
                                            <FaSearch className="homnavsrchprastypeicon " />
                                        </span>
                                        {/* <span className="mx-2">
                                            <button className="navsearchmodalbtn">Search</button>

                                        </span> */}
                                    </Col>

                                </Row>
                            </Col>
                            <Col sm className="justify_content_right align_vertical_center" style={{ marginRight: "50px" }}>
                                <Row>
                                    <span>
                                        {/* <Link to="" className=" ">
                                            <FaSistrix className="navuser_black" onClick={() => setShowsearchmodal(true)} />
                                        </Link> */}
                                        <Link to="" className=" ">
                                            <FaUserAlt className="navprastypeuser" onClick={() => setShowlogin(true)} />
                                        </Link>
                                        <Link to="" className="nav-link-black position-relative " onClick={handleCartClick}>
                                            <FaShoppingCart className="navprastypeuser" />
                                            {cartCount > 0 && (
                                                <span className="cart-count">{cartCount}</span>
                                            )}
                                        </Link>
                                    </span>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Nav className="justify_content_left flex-grow-1 pe-3 mx-5">
                                {/* <Nav.Link href="/" className="nav-link-black">Home</Nav.Link> */}
                                <Nav.Link href="/" className="nav-link-black navigation_dropdown ">Home <RiArrowDropDownLine disabled style={{ fontSize: "30px", color: "transparent" }} />
                                </Nav.Link>
                                <Nav.Link to="" className="nav-link-black navigation_dropdown " >Hand weaving sarees{""}<RiArrowDropDownLine style={{ fontSize: "30px" }} />
                                    <div className="dropdown-content">
                                        <Nav.Link to="/" className="nav-link-black dropdown_menu">Pure silk sarees</Nav.Link>
                                        <Nav.Link to="/" className="nav-link-black">Tussar silk</Nav.Link>
                                        <Nav.Link to="/" className="nav-link-black">Art silk</Nav.Link>
                                        <Nav.Link to="/" className="nav-link-black">Semi silk </Nav.Link>
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="#/Contactus" className="nav-link-black navigation_dropdown " >Contact Us <RiArrowDropDownLine disabled style={{ fontSize: "30px", color: "transparent" }} />
                                </Nav.Link>
                            </Nav>

                        </Row>
                    </Row>

                </>
                :
                <>
                    <Row className="navtopbg">
                        <Navbar expand="sm" className="bg-body-tertiary mb-3" style={{ background: "none" }}>
                            <Container fluid>
                                <Navbar.Toggle aria-controls="nav-link-black" />
                                <Navbar.Offcanvas>
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title >
                                            <Link to="/">
                                                <img src={logo} alt="beamslogo" className="rotatinglogo" />
                                            </Link>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-end flex-grow-1 pe-3">
                                            <Nav.Link href="/" className="nav-link-black">Home</Nav.Link>
                                            <Nav.Link to="" className="nav-link-black navigation_dropdown mt-0" >Hand weaving sarees{""}<RiArrowDropDownLine style={{ fontSize: "30px" }} />
                                                <div className="dropdown-content">
                                                    <Nav.Link to="/" className="nav-link-black dropdown_menu">Pure silk sarees</Nav.Link>
                                                    <Nav.Link to="/" className="nav-link-black">Tussar silk</Nav.Link>
                                                    <Nav.Link to="/" className="nav-link-black">Art silk</Nav.Link>
                                                    <Nav.Link to="/" className="nav-link-black">Semi silk </Nav.Link>
                                                </div>
                                            </Nav.Link>

                                            <Link to="#/Contactus" className='nav-link-black mt-1'>Contact Us</Link>
                                            {/* <Nav.Link to="" className="nav-link-black ">
                                                <FaSistrix className="" onClick={() => setShowsearchmodal(true)} />
                                            </Nav.Link>
                                            <Nav.Link to="" className="nav-link-black ">
                                                <FaUserAlt className="" onClick={() => setShowlogin(true)} />
                                            </Nav.Link> */}

                                        </Nav>

                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                                <Link to="" className="">
                                    <FaSearch className="homnavsrchprastypeiconmob " onClick={() => setShowsearchbarmob(true)} />
                                </Link>

                                <Link to="/" className="">
                                    <img src={logo} alt="beamslogo" className="rotatinglogo" />
                                </Link>
                                <Link to="" className=" navprastypeuser">
                                    <span>
                                        <FaUserAlt className="" onClick={() => setShowlogin(true)} />
                                    </span>
                                    <span>
                                        <FaShoppingCart className=" mx-3" onClick={handleCartClick} />
                                        {cartCount > 0 && (
                                            <span className="cart-count">{cartCount}</span>
                                        )}
                                    </span>
                                </Link>
                                {/* <Link to="" className="nav-link-black position-relative " onClick={handleCartClick}>
                                    <FaShoppingCart className="navprastypeuser" />
                                    {cartCount > 0 && (
                                        <span className="cart-count">{cartCount}</span>
                                    )}
                                </Link> */}
                            </Container>
                        </Navbar>
                    </Row>
                    {showsearchbarmob ?
                        <Row className="mt-1 mb-3" style={{ display: "block" }}>
                            <span className="mx-2" onClick={() => setShowsearchbarmob(false)} style={{ cursor: "pointer" }}>{"<"}</span>
                            <span className=" ">
                                <span>
                                    <input type="text" className="navsearchmodalinput mx-0" placeholder="What are you looking for?" />
                                </span>
                                <span className="">
                                    <FaSearch className="homnavsrchprastypeicon " />
                                </span>
                                {/* <span className="mx-2">
                                            <button className="navsearchmodalbtn">Search</button>

                                        </span> */}
                            </span>
                        </Row>
                        :
                        null
                    }
                </>
            }

        </div >
    )

}

export default Navprastype