import React, { useState, useEffect } from "react"
import { Row, Col, Carousel } from "react-bootstrap"
import { useParams } from "react-router-dom"
import Url from "./Url"
import { Link } from "react-router-dom"
//import DataTable from "react-data-table-component";
import Navigation from "./Navadmin"
import { height } from "@fortawesome/free-solid-svg-icons/fa0"


const Viewarticleadmin = () => {

    const { id } = useParams()

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    const usertoken = localStorage.getItem("usertoken")
    const email = localStorage.getItem("email")

    const [artcledetails, setArtcledetails] = useState("")
    const [articlepic, setArticlepic] = useState([])


    useEffect(() => {
        const fetchCSRFToken = async () => {
            try {
                const response = await fetch('your-laravel-api-url/sanctum/csrf-cookie', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }

                // CSRF token is now set in cookies, and you're ready to make authenticated requests
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCSRFToken();
    }, []); // Run once on component mount


    useEffect(() => {
        const api = url + "getArticle_Pic_ByID/" + id

        fetch(api, {
            method: 'GET',
            headers: { Authorization: `Bearer ${usertoken}`, }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Fail") {
                    setArtcledetails(actualdata[0])
                    setArticlepic(actualdata)
                }
            })
    }, [])

    ///////////////Render from top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <Row className="">
                {/* <Col sm={1}>
                    <Leftvericalmenu />
                </Col> */}
                <Col>
                    <Row><Navigation /></Row>
                    <Row className="appointmentcard mt-2 ">

                        <Row className="appointcenterhead mt-3">

                            <Col sm={1} className="justify_content_left">
                                <Link to="/Dashboardadmin">Dashboard {">"}</Link>
                            </Col>

                            <Col className="justify_content_left">Article details</Col>

                        </Row>
                        <Row className="homereqmsghead justify_content_center mt-0">
                            Article details
                        </Row>

                        <Row className="articledetailbox mt-2 mb-3">
                            <Col sm={1}></Col>
                            <Col sm>
                                <Row>
                                    <Col sm className="mt-3">
                                        <span className="homfeatureheading mx-2">Article name:</span>
                                        <span className="homfeaturetext">{artcledetails.article_name}</span>
                                    </Col>
                                    <Col sm className="mt-3">
                                        <span className="homfeatureheading mx-2">Price:</span>
                                        <span className="homfeaturetext">{artcledetails.article_price}</span>
                                    </Col>
                                    <Col sm className="mt-3">
                                        <span className="homfeatureheading mx-2">Stock:</span>
                                        <span className="homfeaturetext">{artcledetails.article_stock}</span>
                                    </Col>
                                    <Col sm className="mt-3">
                                        <span className="homfeatureheading mx-2">Material:</span>
                                        <span className="homfeaturetext">{artcledetails.Material}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm className="mt-3">
                                        <span className="homfeatureheading mx-2">Blouse:</span>
                                        <span className="homfeaturetext">{artcledetails.article_Blouse}</span>
                                    </Col>
                                    <Col sm className="mt-3">
                                        <span className="homfeatureheading mx-2">Length:</span>
                                        <span className="homfeaturetext">{artcledetails.article_Length}</span>
                                    </Col>
                                    <Col sm className="mt-3">
                                        <span className="homfeatureheading mx-2">Wash:</span>
                                        <span className="homfeaturetext">{artcledetails.article_wash}</span>
                                    </Col>
                                    <Col sm className="mt-3">
                                        <span className="homfeatureheading mx-2">Remarks:</span>
                                        <span className="homfeaturetext">{artcledetails.article_remark}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm className="mt-3">
                                        <span className="homfeatureheading mx-2">Facebook:</span>
                                        <span className="homfeaturetext">{artcledetails.article_fb}</span>
                                    </Col>
                                    <Col sm className="mt-3">
                                        <span className="homfeatureheading mx-2">Twitter:</span>
                                        <span className="homfeaturetext">{artcledetails.article_twit}</span>
                                    </Col>
                                    <Col sm className="mt-3">
                                        <span className="homfeatureheading mx-2">Pinterest:</span>
                                        <span className="homfeaturetext">{artcledetails.article_pinint}</span>
                                    </Col>
                                    <Col sm></Col>
                                </Row>

                                <Row className=" mt-4" >
                                    
                                    <Col className="mt-4">
                                    <Row>
                                        
                                        <Col>
                                        <img
                                            className="adminarticlethumbadmin"
                                            src={s3url + artcledetails.article_Thumbnail}
                                            alt=""
                                        />
                                        </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                    <Row>
                                    
                                    <Col>
                                        <Carousel fade className="adminarticleviewimgadmin mt-2" >
                                            {articlepic.length > 0 ?
                                                articlepic.map(item => (
                                                    <Carousel.Item interval='3000'>
                                                        <img
                                                            className="d-block w-100"
                                                            src={s3url + item.article_pic}
                                                            alt="No pic available"
                                                        />
                                                    </Carousel.Item>
                                                ))
                                                :
                                                null
                                            }
                                        </Carousel>
                                        </Col>
                                        </Row>
                                    </Col>
                                    
                                </Row>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </Row>

                </Col>
            </Row>
        </>
    )
}

export default Viewarticleadmin