import React, { useEffect } from 'react'
import Footer from "./Footer"
// import Navigation from "./Navigationnew";
import Navigation from "./Navprastype"
import { Row, Col } from "react-bootstrap"

const Privacypolicy = () => {

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            <Row>
                <Navigation />
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col xs>
                    <Row className="justify_content_center homfinmgmthead ">
                        PRIVACY POLICY
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead mt-4">
                        PRIVACY STATEMENT
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address and email address.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        SECTION 2 - CONSENT
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        How do you get my consent?
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-5">
                        How do I withdraw my consent?
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at aaradhyhandcraft@gmail.com or mailing us at:
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Rajesh Dewangan<br />H.NO 36,<br /> DURGA MANDIR ROAD,<br />WARD NO. 08, Sarangarh,<br />
                        Sarangarh Bilaigarh, Chhattisgarh,<br />496445
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        SECTION 3 - DISCLOSURE
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        SECTION 4 - THIRD-PARTY SERVICES
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.
                    </Row>
                    {/* <Row className="justify_content_center returnpolicytext mt-3">
                        As an example, if you are located in Canada and your transaction is processed by a payment gateway located in the United States, then your personal information used in completing that transaction may be subject to disclosure under United States legislation, including the Patriot Act.
                    </Row> */}
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Links
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.
                    </Row>
                    {/* <Row className="justify_content_center returnpolicytext mt-3">
                    Google analytics:
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                    Our store uses Google Analytics to help us learn about who visits our site and what pages are being looked at
                    </Row> */}
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        SECTION 5 - SECURITY
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        If you provide us with your credit card information, the information is encrypted using secure socket layer technology (SSL) and stored with a AES-256 encryption. Although no method of transmission over the Internet or electronic storage is 100% secure, we follow all PCI-DSS requirements and implement additional generally accepted industry standards.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        SECTION 6 - COOKIES
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Here is a list of cookies that we use. We’ve listed them here so you that you can choose if you want to opt-out of cookies or not.
                        _session_id, unique token, sessional, Allows Aaradhy to store information about your session (referrer, landing page, etc).
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        SECTION 7 - AGE OF CONSENT
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        SECTION 8 - CHANGES TO THIS PRIVACY POLICY
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.
                    </Row>
                    <Row className="justify_content_center homfinmgmtsubhead_2 mt-5">
                        QUESTIONS AND CONTACT INFORMATION
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer at aaradhyhandcraft@gmail.com or mailing us at:
                    </Row>
                    <Row className="justify_content_center returnpolicytext mt-3">
                        Rajesh Dewangan<br />H.NO 36,<br /> DURGA MANDIR ROAD,<br />WARD NO. 08, Sarangarh,<br />
                        Sarangarh Bilaigarh, Chhattisgarh,<br />496445
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row>





            <Row className="footermargin  mt-5">
                <Footer />
            </Row>

        </div >
    )
}

export default Privacypolicy