import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Modal, ModalBody } from "react-bootstrap"
import { useParams } from "react-router-dom"
import Url from "./Url"
import { Link } from "react-router-dom"
//import DataTable from "react-data-table-component";
// import Navigation from "./Navigationnew"
import Navigation from "./Navprastype"
import Footer from "./Footer"
import { FaCheck, FaDotCircle, FaChevronLeft, FaChevronRight } from "react-icons/fa"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Saree1_1 from "./Images/New folder/PhotoRoom-20231209_193608.png"
// import Saree1_2 from "./Images/New folder/PhotoRoom-20231209_195032.png"
// import Saree1_3 from "./Images/New folder/PhotoRoom-20231209_225109.png"
// import Saree1_4 from "./Images/New folder/PhotoRoom-20231210_165529.png"

const Viewarticlemobilefinger = () => {

    const { id, article_name } = useParams()


    //console.log(article_name)

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]
    const [s3url, setS3url] = useState(s3)

    // const usertoken = localStorage.getItem("usertoken")
    // const email = localStorage.getItem("email")

    const [artcledetails, setArtcledetails] = useState("")
    const [articlepic, setArticlepic] = useState([])
    const [similarProducts, setSimilarProducts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [similarProductsIndex, setSimilarProductsIndex] = useState(0);

    const [recentlyViewed, setRecentlyViewed] = useState([]);
    const [recentlyViewedIndex, setRecentlyViewedIndex] = useState(0);

    const [showimgmodal, setShowimgmodal] = useState(false)

    const [mobilescr, setMobilescr] = useState(false)
    const [getdevwidth, setGetdevwidth] = useState("")

    const [cartMessage, setCartMessage] = useState("")

    useEffect(() => {

        setGetdevwidth(window.innerWidth + 'px')

        if (window.innerWidth <= 500) {
            setMobilescr(true)
        }
        //console.log(getdevwidth)

    }, [getdevwidth])

    useEffect(() => {
        const fetchCSRFToken = async () => {
            try {
                const response = await fetch('your-laravel-api-url/sanctum/csrf-cookie', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }

                // CSRF token is now set in cookies, and you're ready to make authenticated requests
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCSRFToken();
    }, []); // Run once on component mount

    useEffect(() => {
        const api = url + "getArticle_Pic_ByID_home/" + id

        fetch(api, {
            method: 'GET',
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata[0])

                if (actualdata.message != "Fail") {
                    setArtcledetails(actualdata[0])
                    setArticlepic(actualdata)
                }
            })
    }, [])

    useEffect(() => {
        const api = url + "getsimilar_item/" + id + "/" + article_name

        fetch(api, {
            method: 'GET',
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Fail") {
                    setSimilarProducts(actualdata)
                }

            })
    }, [])

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + 5 >= similarProducts.length ? 0 : prevIndex + 5
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex - 5 < 0 ? Math.max(similarProducts.length - 5, 0) : prevIndex - 5
        );
    };

    const mobcornextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % articlepic.length);
    };

    const mobcorprevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + articlepic.length) % articlepic.length);
    };

    const selectSlide = (index) => {
        setCurrentIndex(index);
    };

    const nextSimilarProducts = () => {
        setSimilarProductsIndex((prevIndex) =>
            prevIndex + 1 >= similarProducts.length ? 0 : prevIndex + 1
        );
    };

    const prevSimilarProducts = () => {
        setSimilarProductsIndex((prevIndex) =>
            prevIndex - 1 < 0 ? similarProducts.length - 1 : prevIndex - 1
        );
    };

    // Display 4 items at a time
    // const visibleProducts = similarProducts.slice(currentIndex, currentIndex + 4);

    useEffect(() => {
        // Fetch and update recently viewed products
        const storedRecentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
        // Remove duplicates by using Set
        const uniqueRecentlyViewed = Array.from(new Set(storedRecentlyViewed.map(JSON.stringify))).map(JSON.parse);
        setRecentlyViewed(uniqueRecentlyViewed);
    }, []);

    const nextRecentlyViewed = () => {
        setRecentlyViewedIndex((prevIndex) =>
            prevIndex + 1 >= recentlyViewed.length ? 0 : prevIndex + 1
        );
    };

    const prevRecentlyViewed = () => {
        setRecentlyViewedIndex((prevIndex) =>
            prevIndex - 1 < 0 ? recentlyViewed.length - 1 : prevIndex - 1
        );
    };

    // const nextRecentlyViewed = () => {
    //     setRecentlyViewedIndex((prevIndex) =>
    //         prevIndex + 8 >= recentlyViewed.length ? 0 : prevIndex + 8
    //     );
    // };

    // const prevRecentlyViewed = () => {
    //     setRecentlyViewedIndex((prevIndex) =>
    //         prevIndex - 8 < 0 ? Math.max(recentlyViewed.length - 8, 0) : prevIndex - 8
    //     );
    // };

    const addToRecentlyViewed = (item) => {
        const updatedRecentlyViewed = [item, ...recentlyViewed.filter(i => i.id !== item.id)].slice(0, 8);
        localStorage.setItem('recentlyViewed', JSON.stringify(updatedRecentlyViewed));
        setRecentlyViewed(updatedRecentlyViewed);
    };

    const addToCart = () => {
        const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

        const newItem = {
            id: artcledetails.articles_id,
            article_Id: artcledetails.article_Id,
            name: artcledetails.article_name,
            price: artcledetails.article_price,
            thumbnail: artcledetails.article_Thumbnail,
            quantity: 1
        };

        const existingItemIndex = cartItems.findIndex(item => item.id === newItem.id);

        if (existingItemIndex > -1) {
            alert("This item is already in your cart.")
            // cartItems[existingItemIndex].quantity += 1;
            // setCartMessage("This item is already in your cart.")
            // setTimeout(() => setCartMessage(""), 3000) // Clear message after 3 seconds
        } else {
            cartItems.push(newItem)
            localStorage.setItem('cartItems', JSON.stringify(cartItems))

            // Update cart count in localStorage
            const cartCount = cartItems.reduce((total, item) => total + item.quantity, 0)
            localStorage.setItem('cartCount', cartCount.toString())

            // Trigger a custom event to notify Navigation component
            window.dispatchEvent(new Event('cartUpdated'))

            alert("Item added to cart successfully!")
        }

        // localStorage.setItem('cartItems', JSON.stringify(cartItems));

        // // Update cart count in localStorage
        // const cartCount = cartItems.reduce((total, item) => total + item.quantity, 0);
        // localStorage.setItem('cartCount', cartCount.toString());

        // // Trigger a custom event to notify Navigation component
        // window.dispatchEvent(new Event('cartUpdated'));

        // setCartMessage("Item added to cart successfully!")
        // setTimeout(() => setCartMessage(""), 3000) // Clear message after 3 seconds
    };

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    // Refs for each slider
    const mainImageRef = useRef(null)
    const similarProductsRef = useRef(null)
    const recentlyViewedRef = useRef(null)

    // Minimum swipe distance (in px)
    const minSwipeDistance = 50

    const onTouchStart = (e) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = (sliderType) => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe || isRightSwipe) {
            switch (sliderType) {
                case 'mainImage':
                    isLeftSwipe ? mobcornextSlide() : mobcorprevSlide()
                    break
                case 'similarProducts':
                    isLeftSwipe ? nextSimilarProducts() : prevSimilarProducts()
                    break
                case 'recentlyViewed':
                    isLeftSwipe ? nextRecentlyViewed() : prevRecentlyViewed()
                    break
            }
        }
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 8, // Number of items to show at once
        // slidesToScroll: 1,
        swipeToSlide: true,
        // variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.5,
                    arrows: false,
                }
            }
        ]
    };

    // const recentviewsettings = {
    //     dots: false,
    //     arrows: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 8, // Number of items to show at once
    //     // slidesToScroll: 1,
    //     swipeToSlide: true,
    //     // variableWidth: true,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 1.5,
    //                 arrows: false,
    //             }
    //         }
    //     ]
    // };


    const [zoomedImage, setZoomedImage] = useState(null);

    const handleImageClick = (imageSrc) => {
        setZoomedImage(imageSrc);
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            setZoomedImage(null);
        }
    };

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <Modal
                size="xl"
                show={showimgmodal}
                onHide={() => setShowimgmodal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Row>
                    <Col sm={1}></Col>
                    <Col>
                        <div>
                            <Carousel
                                showArrows={true}
                                swipeable={true}
                                emulateTouch={true}
                                infiniteLoop={true}
                                showThumbs={true}
                                useKeyboardArrows={true}
                                autoPlay={false}
                                stopOnHover={true}
                                dynamicHeight={true}
                                className="carousel-container"
                            >
                                {articlepic.length > 0 ?
                                    articlepic.map(item => (
                                        <div >
                                            <img src={s3url + item.article_pic} alt="" className="" />
                                        </div>
                                    ))
                                    :
                                    null
                                }
                            </Carousel>
                        </div>
                        {/* <div className="image-carousel">
                            <div className="main-image-container">
                                {articlepic.length > 0 && (
                                    <img
                                        src={s3url + articlepic[currentIndex].article_pic}
                                        alt={`Main Slide ${currentIndex + 1}`}
                                        className="main-image"
                                    />
                                )}
                                <button onClick={mobcorprevSlide} className="nav-button prev">&lt;</button>
                                <button onClick={mobcornextSlide} className="nav-button next">&gt;</button>
                            </div>
                            <div className="thumbnails-container">
                                {articlepic.map((item, index) => (
                                    <img
                                        key={index}
                                        src={s3url + item.article_pic}
                                        alt={`Thumbnail ${index + 1}`}
                                        className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
                                        onClick={() => selectSlide(index)}
                                    />
                                ))}
                            </div>
                            <div className="dot-indicators">
                                {articlepic.map((_, index) => (
                                    <span
                                        key={index}
                                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                                        onClick={() => selectSlide(index)}
                                    ></span>
                                ))}
                            </div>
                        </div> */}
                    </Col>
                    <Col sm={1}></Col>
                </Row>
            </Modal >

            <Row className="">

                <Col sm>
                    <Row><Navigation /></Row>
                    {/* <Row className="mt-5"></Row> */}
                    <Row className="appointmentcard mt-2 ">

                        {/* <div className="appointcenterhead mt-3 mx-1" style={{ display: "flex" }}>
                            <span className="justify_content_left">
                                <Link to="/">
                                    Home {"> "}</Link>
                            </span>
                            <span className="justify_content_left">{" "} {artcledetails.article_name}</span>
                        </div> */}

                        {/* <Row className="appointcenterhead mt-3">

                            <Col sm={1} className="justify_content_left">
                                <Link to="/">Home {">"}</Link>
                            </Col>

                            <Col className="justify_content_left">Article details</Col>

                        </Row> */}
                        {/* <Row className="homereqmsghead justify_content_center mt-0">
                            Article details
                        </Row> */}

                        {!mobilescr ?
                            <Row className=" mt-3 mb-3 mx-0">

                                <Col sm={7}>

                                    <Row>
                                        {articlepic.length > 0 ?
                                            articlepic.map(item => (
                                                <Col sm className="mt-3">
                                                    <img src={s3url + item.article_pic} alt="" className="articledetailpics"
                                                        onClick={() => setShowimgmodal(true)}

                                                    />
                                                </Col>
                                            ))
                                            :
                                            null
                                        }
                                    </Row>

                                    <Row className="homecountercardtext mt-4">
                                        DESCRIPTION
                                    </Row>
                                    <Row className="justify_content_left mt-3 mx-1">
                                        <ul className="articledetailtext1">
                                            <li><b>Material:</b> {artcledetails.Material}</li>
                                            <li><b>Handloom mark</b></li>
                                            <li><b>Blouse:</b> {artcledetails.article_Blouse}</li>
                                            <li><b>Length:</b> {artcledetails.article_Length}</li>
                                            <li><b>Wash & Care:</b> {artcledetails.article_wash}</li>
                                        </ul>
                                    </Row>
                                    <div className="articledetailtext1 mt-2 mx-0">
                                        <b>Care Instructions:</b>
                                        <ul className="articledetailtext1">
                                            <li>Dry clean only for optimal preservation to prevent color bleeding.</li>
                                            <li>Avoid direct sunlight while drying.</li>
                                            <li>Roll gently for storage to maintain the delicate weave and intricate design.</li>
                                        </ul>
                                    </div>
                                    <div className="articledetailtext1 mt-2 mx-0">
                                        <b>Note:</b> There might be mild colour variation in the saree due to colour reproduction in camera and screen.
                                    </div>
                                </Col>
                                <Col sm className="mx-0">
                                    <Row className="articlename mt-3 ">
                                        {artcledetails.article_name}
                                    </Row>
                                    <Row className="articleid mt-3 ">
                                        Article id: {artcledetails.article_id_2}
                                    </Row>
                                    <Row className="articleprice mt-3 ">
                                        ₹{artcledetails.article_price} MRP (Including applicable taxes)
                                    </Row>
                                    {/* <Row className="articletax mt-1 ">
                                    inclusive of applicable taxes
                                </Row> */}
                                    <Row className="artcledetailtexred  mt-3" >

                                        {/* <Col><FaCheck className="articledetailrightbullet" /> In stock, only one unit</Col> */}
                                    </Row>
                                    <Row className="articledetailtext1 mt-3 ">
                                        <Col ><FaCheck className="articledetailrightbullet" /> Dispatched within 24 Hrs </Col>

                                    </Row>
                                    <div className="mt-3">
                                        <span>
                                            <button className="homgreqmsgbtn" onClick={addToCart}>
                                                Add to cart
                                            </button>
                                        </span>
                                        <span>
                                            <Link to="/Checkout">
                                                <button className="bynowbtn mx-3" onClick={addToCart}>
                                                    Buy now
                                                </button>
                                            </Link>
                                        </span>
                                    </div>

                                    <Row className="articledetailtext1 mt-3 ">
                                        <Col ><FaCheck className="articledetailrightbullet" /> <b>3 Days</b> Return Policy </Col>

                                    </Row>
                                    <Row className="articledetailtext1 mt-3 ">
                                        <Col ><FaCheck className="articledetailrightbullet" /> <b>Secured & Safe</b> payments </Col>

                                    </Row>
                                    <Row className="articledetailtext1 mt-3 ">
                                        <Col ><FaCheck className="articledetailrightbullet" /> Above mentioned pricing is <b>inclusive of applicable taxes</b></Col>
                                    </Row>
                                </Col>
                            </Row>
                            :
                            <div className=" mt-5 mb-3 mx-0">


                                <div>
                                    <Carousel
                                        showArrows={true}
                                        swipeable={true}
                                        emulateTouch={true}
                                        infiniteLoop={true}
                                        showThumbs={true}
                                        useKeyboardArrows={true}
                                        autoPlay={false}
                                        stopOnHover={true}
                                        dynamicHeight={true}
                                        className="carousel-container"
                                    >
                                        {articlepic.length > 0 ?
                                            articlepic.map((item, index) => (
                                                <div key={index} onClick={() => handleImageClick(s3url + item.article_pic)}>
                                                    <img
                                                        src={s3url + item.article_pic}
                                                        alt=""
                                                        className="zoomable-image"
                                                        onClick={() => handleImageClick(s3url + item.article_pic)}
                                                    />
                                                </div>
                                            ))
                                            :
                                            null
                                        }
                                    </Carousel>
                                    {zoomedImage && (
                                        <div className="overlay" onClick={handleOverlayClick}>
                                            <img src={zoomedImage} alt="Zoomed Image" className="zoomed-image" />
                                        </div>
                                    )}
                                </div>

                                <Row className="mx-0">
                                    <Row className="articlename mt-3 ">
                                        {artcledetails.article_name}
                                    </Row>
                                    <Row className="articleid mt-3 ">
                                        Article id: {artcledetails.article_id_2}
                                    </Row>
                                    <Row className="articleprice mt-3 ">
                                        ₹{artcledetails.article_price} MRP (Including applicable taxes)
                                    </Row>
                                    {/* <Row className="articletax mt-1 ">
                                    inclusive of applicable taxes
                                </Row> */}
                                    <Row className="artcledetailtexred  mt-3" >

                                        {/* <Col><FaCheck className="articledetailrightbullet" /> In stock, only one unit</Col> */}
                                    </Row>
                                    <Row className="articledetailtext1 mt-3 ">
                                        <Col ><FaCheck className="articledetailrightbullet" /> Dispatched within 24 Hrs </Col>

                                    </Row>
                                    <div className="mt-3">
                                        <span>
                                            <button className="homgreqmsgbtn" onClick={addToCart}>
                                                Add to cart
                                            </button>
                                        </span>
                                        <span>
                                            <Link to="/Checkout">
                                                <button className="bynowbtn mx-3" onClick={addToCart}>
                                                    Buy now

                                                </button>
                                            </Link>
                                        </span>
                                    </div>

                                    <Row className="articledetailtext1 mt-3 ">
                                        <Col ><FaCheck className="articledetailrightbullet" /> <b>3 Days</b> Return Policy </Col>

                                    </Row>
                                    <Row className="articledetailtext1 mt-3 ">
                                        <Col ><FaCheck className="articledetailrightbullet" /> <b>Secured & Safe</b> payments </Col>

                                    </Row>
                                    <Row className="articledetailtext1 mt-3 ">
                                        <Col ><FaCheck className="articledetailrightbullet" /> Above mentioned pricing is <b>inclusive of applicable taxes</b></Col>
                                    </Row>

                                    {mobilescr ?
                                        <>
                                            <Row className="homecountercardtext mt-4">
                                                DESCRIPTION
                                            </Row>
                                            <Row className="justify_content_left mt-3 mx-1">
                                                <ul className="articledetailtext1">
                                                    <li><b>Material:</b> {artcledetails.Material}</li>
                                                    <li><b>Handloom mark</b></li>
                                                    <li><b>Blouse:</b> {artcledetails.article_Blouse}</li>
                                                    <li><b>Length:</b> {artcledetails.article_Length}</li>
                                                    <li><b>Wash & Care:</b> {artcledetails.article_wash}</li>
                                                </ul>
                                            </Row>
                                            <div className="articledetailtext1 mt-2 mx-0">
                                                <b>Care Instructions:</b>
                                                <ul className="articledetailtext1">
                                                    <li>Dry clean only for optimal preservation to prevent color bleeding.</li>
                                                    <li>Avoid direct sunlight while drying.</li>
                                                    <li>Roll gently for storage to maintain the delicate weave and intricate design.</li>
                                                </ul>
                                            </div>
                                            <div className="articledetailtext1 mt-2 mx-0">
                                                <b>Note:</b> There might be mild colour variation in the saree due to colour reproduction in camera and screen.
                                            </div>
                                        </>
                                        :
                                        null
                                    }

                                </Row>
                            </div>
                        }
                    </Row>


                    <Row className="mx-2 mt-3">
                        {similarProducts.length > 0 ?
                            <>
                                <h2 className="similar-products-title">YOU MAY ALSO LIKE</h2>
                                {similarProducts.length > 0 && (
                                    <div className="">
                                        {similarProducts.length > 1 ? (
                                            <Slider {...settings}>
                                                {similarProducts.map(item => (
                                                    <Link key={item.id} to={"/Viewarticle/" + item.id + "/" + item.article_name} target="_blank" onClick={() => addToRecentlyViewed(item)}>
                                                        <div className="similar-product-item_new ">
                                                            <div className="justify-content-center similar-product-image-container">
                                                                <img src={s3url + item.article_Thumbnail} alt="" className="similar-product-image" />
                                                            </div>
                                                            <div className="recently-viewed-details">
                                                                <div className="recently-viewed-name mx-2">{item.article_name}</div>
                                                                <div className="recently-viewed-price mx-2">₹{item.article_price}</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </Slider>
                                        ) : (
                                            <Link to={`/Viewarticle/${similarProducts[0].id}/${similarProducts[0].article_name}`} target="_blank" onClick={() => addToRecentlyViewed(similarProducts[0])}>
                                                {mobilescr ?
                                                    <div className="justify_content_center">
                                                        <div className="recent-view-item_new_01 ">
                                                            <div className="justify-content-center similar-product-image-container">
                                                                <img src={s3url + similarProducts[0].article_Thumbnail} alt="" className="similar-product-image" />
                                                            </div>
                                                            <div className="recently-viewed-details">
                                                                <div className="recently-viewed-name mx-2">{similarProducts[0].article_name}</div>
                                                                <div className="recently-viewed-price mx-2">₹{similarProducts[0].article_price}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="justify_content_left">
                                                        <div className="recent-view-item_new_01 ">
                                                            <div className="justify-content-center similar-product-image-container">
                                                                <img src={s3url + similarProducts[0].article_Thumbnail} alt="" className="similar-product-image" />
                                                            </div>
                                                            <div className="recently-viewed-details">
                                                                <div className="recently-viewed-name mx-2">{similarProducts[0].article_name}</div>
                                                                <div className="recently-viewed-price mx-2">₹{similarProducts[0].article_price}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </Link>
                                        )}
                                    </div>

                                )}
                            </>
                            :
                            null
                        }

                    </Row>
                    {/* <Row className="mx-2 mt-3">
                        <h2 className="similar-products-title">YOU MAY ALSO LIKE</h2>
                        {similarProducts.length > 0 ? (
                            <div className="">
                                <Slider {...youmaylikesettings}>
                                    {similarProducts.map(item => (
                                        <Link key={item.id} to={"/Viewarticle/" + item.id + "/" + item.article_name} target="_blank" onClick={() => addToRecentlyViewed(item)}>
                                            <div className="recent-view-item_new ">
                                                <div className="justify-content-center similar-product-image-container">
                                                    <img src={s3url + item.article_Thumbnail} alt="" className="similar-product-image" />
                                                </div>
                                                <div className="recently-viewed-details">
                                                    <div className="recently-viewed-name mx-2">{item.article_name}</div>
                                                    <div className="recently-viewed-price mx-2">₹{item.article_price}</div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </Slider>
                            </div>
                        ) : (
                            <div>No similar products available</div>
                        )}
                    </Row> */}
                    <Row className="mx-2 mt-3">
                        <h2 className="similar-products-title">RECENTLY VIEWED</h2>
                        <div className="">
                            <Slider {...settings}>
                                {recentlyViewed.length > 2 ?
                                    recentlyViewed.map(item => (
                                        <Link key={item.id} to={`/Viewarticle/${item.id}/${item.article_name}`} target="_blank" onClick={() => addToRecentlyViewed(item)}>
                                            <div className="recent-view-item_new">
                                                <div className="justify-content-center similar-product-image-container">
                                                    <img src={s3url + item.article_Thumbnail} alt="" className="similar-product-image" />
                                                </div>
                                                <div className="recently-viewed-details">
                                                    <div className="recently-viewed-name mx-2">{item.article_name}</div>
                                                    <div className="recently-viewed-price mx-2">₹{item.article_price}</div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                    :
                                    null
                                }
                            </Slider>
                        </div>
                    </Row>

                    {/* <div
                            className="similar-products-section"

                        >
                            <h2 className="similar-products-title">YOU MAY ALSO LIKE</h2>

                            <div className="similar-products-container">

                                <div className="similar-products-carousel">
                                    {similarProducts.map((product, index) => (
                                        <div
                                            key={index}
                                            className="similar-product-item"
                                            style={{
                                                display: (index >= similarProductsIndex && index < similarProductsIndex + 8) ? 'block' : 'none'
                                            }}
                                        >
                                            <Link to={"/Viewarticle/" + product.article_Id + "/" + product.article_name} target="_blank" onClick={() => addToRecentlyViewed(product)}>
                                                <div className="similar-product-image-container">
                                                    <img src={s3url + product.article_Thumbnail} alt={product.article_name} className="similar-product-image" />
                                                </div>
                                                <div className="similar-product-details">
                                                    <div className="homecountercardtext">{product.article_name}</div>
                                                    <div className="homecountercardtext">₹{product.article_price}</div>
                                                    <div className="homecountercardtexred">
                                                        <FaDotCircle /> In stock, only one Unit
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>

                                <button onClick={nextSimilarProducts} className="nav-button next">
                                    <FaChevronRight />
                                </button>

                            </div>
                        </div> */}
                    {/* <div
                            className="similar-products-section"
                            ref={similarProductsRef}
                            onTouchStart={onTouchStart}
                            onTouchMove={onTouchMove}
                            onTouchEnd={() => onTouchEnd('similarProducts')}
                        >
                            <h2 className="similar-products-title">YOU MAY ALSO LIKE</h2>

                            <div className="similar-products-container">

                                <button onClick={prevSimilarProducts} className="nav-button prev">
                                    <FaChevronLeft />
                                </button>

                                <div className="similar-products-carousel">
                                    {similarProducts.map((product, index) => (
                                        <div
                                            key={index}
                                            className="similar-product-item"
                                            style={{
                                                display: (index >= similarProductsIndex && index < similarProductsIndex + 8) ? 'block' : 'none'
                                            }}
                                        >
                                            <Link to={"/Viewarticle/" + product.article_Id + "/" + product.article_name} target="_blank" onClick={() => addToRecentlyViewed(product)}>
                                                <div className="similar-product-image-container">
                                                    <img src={s3url + product.article_Thumbnail} alt={product.article_name} className="similar-product-image" />
                                                </div>
                                                <div className="similar-product-details">
                                                    <div className="homecountercardtext">{product.article_name}</div>
                                                    <div className="homecountercardtext">₹{product.article_price}</div>
                                                    <div className="homecountercardtexred">
                                                        <FaDotCircle /> In stock, only one Unit
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>

                                <button onClick={nextSimilarProducts} className="nav-button next">
                                    <FaChevronRight />
                                </button>

                            </div>
                        </div> 
                    </Row>
*/}

                    {/* <Row className="mt-3">
                        <div
                            className="recently-viewed-section"
                            ref={recentlyViewedRef}
                            onTouchStart={onTouchStart}
                            onTouchMove={onTouchMove}
                            onTouchEnd={() => onTouchEnd('recentlyViewed')}
                        >
                            <h2 className="recently-viewed-title">RECENTLY VIEWED</h2>
                            <div className="recently-viewed-container">
                                {mobilescr ?
                                    <button onClick={prevRecentlyViewed} className="nav-button prev">
                                        <FaChevronLeft />
                                    </button>
                                    :
                                    null
                                }
                                <div className="recently-viewed-carousel">
                                    {recentlyViewed.map((product, index) => (
                                        <div
                                            key={index}
                                            className="recently-viewed-item"
                                            style={{
                                                display: (index >= recentlyViewedIndex && index < recentlyViewedIndex + 8) ? 'block' : 'none'
                                            }}
                                        >
                                            <Link to={"/Viewarticle/" + product.article_Id + "/" + product.article_name} target="_blank" onClick={() => addToRecentlyViewed(product)}>
                                                <div className="recently-viewed-image-container">
                                                    <img src={s3url + product.article_Thumbnail} alt={product.article_name} className="recently-viewed-image" />
                                                </div>
                                                <div className="recently-viewed-details">
                                                    <div className="recently-viewed-name">{product.article_name}</div>
                                                    <div className="recently-viewed-price">₹{product.article_price}</div>
                                                    <div className="recently-viewed-stock">
                                                        <FaDotCircle /> In stock, only one Unit
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                                {mobilescr ?
                                    <button onClick={nextRecentlyViewed} className="nav-button next">
                                        <FaChevronRight />
                                    </button>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </Row> */}

                </Col >
            </Row >
            <Row className="mt-5">
                <Footer />
            </Row>
        </>
    )
}

export default Viewarticlemobilefinger