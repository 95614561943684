import React, { useEffect, useState } from "react"
import { Row, Col, Card, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
//import { useNavigate } from "react-router-dom"
//import Leftvericalmenu from "./Leftverticalmenu"
import logo from "./Images/Aaradhy_logo_small.png"
import Navigation from "./Navadmin"
import loadinggif from "./Images/giphy.gif"
import { IoShirt } from "react-icons/io5";
import { GiRolledCloth, GiReceiveMoney  } from "react-icons/gi";
import { GiVerticalBanner } from "react-icons/gi";
import { GiClothes, GiReturnArrow  } from "react-icons/gi";
import { FcMoneyTransfer } from "react-icons/fc";
import { MdContactMail } from "react-icons/md";
import { GoRepoPush } from "react-icons/go";
//import { IoWomanSharp } from "react-icons/io5";
import Url from './Url';
//import mastericon from "./Images/Audit.png"
// import doctor_icon from "./Images/Audit.png"
// import nurse_icon from "./Images/Audit.png"
// import leave_icon from "./Images/Audit.png"
// import appointment_icon from "./Images/Audit.png"
// import user_icon from "./Images/Audit.png"


const Dashboardadmin = () => {

    // const navigate = useNavigate();

    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]

    const usertoken = localStorage.getItem("usertoken")

    const [mobilescr, setMobilescr] = useState(false)
    const [getdevwidth, setGetdevwidth] = useState("")

    // useEffect(() => {
    //     const api = url + "getAllBanner"

    //     fetch(api, {
    //         method: 'GET',

    //         headers: { 'Authorization': 'Bearer ' + usertoken }
    //     })
    //         .then((apidata) => {
    //             return apidata.json()
    //         })
    //         .then((actualdata) => {
    //             //console.log(actualdata)

    //         })
    // }, [])

    const [rukjlk, setRukjlk] = useState(false)

    const [hideleftnav, setHideleftnav] = useState(false)

    useEffect(() => {

        setGetdevwidth(window.innerWidth + 'px')

        if (window.innerWidth <= 500) {
            setMobilescr(true)
        }
        //console.log(getdevwidth)

    }, [getdevwidth])

    ///////////////Render from top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
        /*setRukjlk(true)
        window.setTimeout(() => {
            setRukjlk(false)
        }, 5000)*/
    }, [])

    // if (usertoken !== null || usertoken === null) {
    //     //history.push("/Employerdashboard")
    //     var today = new Date();
    //     var x = (today.getDate() + today.getMonth() + today.getFullYear() + "/")
    //     // if (usertoken === btoa(x)) {
    //     //     history.push("/")
    //     // }
    //     if (x !== atob(usertoken)) {
    //         alert("")
    //         history.push("/")
    //     }
    // }

    return (
        <>

            <Modal
                size="sm"
                show={rukjlk}
                centered
                aria-labelledby="example-modal-sizes-title-sm"

            >
                <Modal.Header className="">
                    <img src={loadinggif} alt="" className="modalbgruk" />
                </Modal.Header>

            </Modal>
            {/*<Row className="Loginheading jstfy_all_cntr mt-2">
                Dashboard
            </Row>*/}

            {!rukjlk ?
                <>
                    <Row className="">

                        <Row><Navigation /></Row>

                        <Row className="dashrowbg">
                            <div style={{ height: "0px" }}>
                                <Row>
                                    <Col sm>

                                        <Card
                                            bg="primary"
                                            className="dashtopcard mb-5"
                                        >
                                            <Link to="/Banner">
                                                <Card.Body bg="primary"
                                                    className="dashtopcard ">
                                                    <Card.Title className="dashtopcardtitle">
                                                        <span><GiVerticalBanner alt="" className="dashtopcardimg" /></span>
                                                        <span className="mt-0">Banner</span>
                                                    </Card.Title>
                                                    {/* <Card.Text className="dashtopcardtext justify_content_right ">
                                                        20
                                                    </Card.Text> */}
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                    <Col sm>
                                        <Card
                                            bg="warning"
                                            className="dashtopcard mb-5"
                                        >
                                            <Link to="/Categorymaster">
                                                <Card.Body>
                                                    <Card.Title className="dashtopcardtitle">
                                                        <span><GiClothes alt="" className="dashtopcardimg" /></span>
                                                        <span className="mt-0">   Add Article Category </span>
                                                    </Card.Title>
                                                    {/* <Card.Text className="dashtopcardtext justify_content_right ">
                                                        16
                                                    </Card.Text> */}
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                    <Col sm>
                                        <Card
                                            bg="danger"
                                            className="dashtopcard mb-5"
                                        >
                                            <Link to="/Materialmaster">
                                            <Card.Body>
                                                <Card.Title className="dashtopcardtitle">
                                                    <span><GiRolledCloth alt="" className="dashtopcardimg" /></span>
                                                    <span className="mt-0">   Add Material master </span>
                                                </Card.Title>
                                                {/* <Card.Text className="dashtopcardtext justify_content_right ">
                                                        2
                                                    </Card.Text> */}
                                            </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                    <Col sm>
                                        <Card
                                            bg="info"
                                            className="dashtopcard mb-5"
                                        >
                                            <Link to="/Article">
                                                <Card.Body>
                                                    <Card.Title className="dashtopcardtitle mt-0">
                                                        <span><IoShirt alt="" className="dashtopcardimg" /></span>
                                                        <span className="mt-0">   Add Article </span></Card.Title>
                                                    {/* <Card.Text className="dashtopcardtext justify_content_right ">
                                                        8
                                                    </Card.Text> */}
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                    <Col sm>

                                        <Card
                                            bg="success"
                                            className="dashtopcard mb-5"
                                        >
                                            <Link to="/Sell">
                                                <Card.Body bg="primary"
                                                    className="dashtopcard ">
                                                    <Card.Title className="dashtopcardtitle">
                                                        <span><FcMoneyTransfer alt="" className="dashtopcardimg" /></span>
                                                        <span className="mt-0">Sell</span>
                                                    </Card.Title>
                                                    
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                   
                                </Row>
                            </div>

                            <div >
                                <Row>
                                <Col sm>
                                    <Card
                                            bg="success"
                                            className="dashtopcard mb-5"
                                        >
                                            <Link to="/CoD">
                                                <Card.Body bg="primary"
                                                    className="dashtopcard ">
                                                    <Card.Title className="dashtopcardtitle">
                                                        <span><GiReceiveMoney  alt="" className="dashtopcardimg" /></span>
                                                        <span className="mt-0">CoD</span>
                                                    </Card.Title>
                                                    
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                    
                                    <Col sm>
                                    <Card
                                            bg="dark"
                                            className="dashtopcard mb-5"
                                        >
                                            <Link to="/Returnviewadmin">
                                                <Card.Body bg="primary"
                                                    className="dashtopcard ">
                                                    <Card.Title className="dashtopcardtitle">
                                                        <span><GiReturnArrow  alt="" className="dashtopcardimg" /></span>
                                                        <span className="mt-0">Return</span>
                                                    </Card.Title>
                                                    
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                    <Col sm>
                                    <Card
                                            bg="light"
                                            className="dashtopcard mb-5"
                                        >
                                            <Link to="/Admincontactus">
                                                <Card.Body bg="primary"
                                                    className="dashtopcard ">
                                                    <Card.Title className="dashtopcardtitledark">
                                                        <span><MdContactMail   alt="" className="dashtopcardimg" /></span>
                                                        <span className="mt-0">Contact us</span>
                                                    </Card.Title>
                                                    
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                    <Col sm>
                                    <Card
                                            bg="warning"
                                            className="dashtopcard mb-5"
                                        >
                                            <Link to="/Popup">
                                                <Card.Body bg="primary"
                                                    className="dashtopcard ">
                                                    <Card.Title className="dashtopcardtitledark">
                                                        <span><GoRepoPush   alt="" className="dashtopcardimg" /></span>
                                                        <span className="mt-0">Pop Up</span>
                                                    </Card.Title>
                                                    
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                    <Col sm>
                                    
                                    </Col>
                                    
                                </Row>
                            </div>

                            
                        </Row>

                    </Row>
                </>
                :
                null
            }

        </>
    )
}

export default Dashboardadmin