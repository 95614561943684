import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Card, ListGroup, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Url from "./Url";
import Navigation from "./Navprastype";
import Footer from "./Footer";
import { FaCheckCircle, FaShippingFast, FaLock } from "react-icons/fa";

const Checkoutcod = () => {
    const navigate = useNavigate();
    const url1 = Url();
    const url = url1["url"];
    const s3 = url1["s3"];

    const [showModal, setShowModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("online");

    const [cartItems, setCartItems] = useState([]);
    const [subTotal, setSubTotal] = useState(0);
    const [shippingFee, setShippingFee] = useState(0);
    const [total, setTotal] = useState(0);

    // Billing information
    const [customeremail, setCustomerEmail] = useState("");
    const [b_country, setBCountry] = useState("");
    const [b_first_name, setBFirstName] = useState("");
    const [b_last_name, setBLastName] = useState("");
    const [b_address_1, setBAddress1] = useState("");
    const [b_address_2, setBAddress2] = useState("");
    const [b_postal_code, setBPostalCode] = useState("");
    const [b_city, setBCity] = useState("");
    const [b_phone, setBPhone] = useState("");

    // Correspondence information
    const [c_country, setCCountry] = useState("");
    const [c_first_name, setCFirstName] = useState("");
    const [c_last_name, setCLastName] = useState("");
    const [c_address_1, setCAddress1] = useState("");
    const [c_address_2, setCAddress2] = useState("");
    const [c_postal_code, setCPostalCode] = useState("");
    const [c_city, setCCity] = useState("");
    const [c_phone, setCPhone] = useState("");

    const [sameAsBilling, setSameAsBilling] = useState(false);

    useEffect(() => {
        // Load PhonePe Checkout script
        const script = document.createElement('script');
        script.src = "https://mercury.phonepe.com/web/bundle/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        //console.log(JSON.parse(localStorage.getItem("cartItems")))
        const cartData = JSON.parse(localStorage.getItem("cartItems")) || [];
        setCartItems(cartData);

        const subTotal = cartData.reduce((total, item) => total + (item.price * item.quantity), 0);
        //setSubTotal(subTotal);
        //setShippingFee(50);
        setTotal(subTotal);
    }, []);

    // useEffect(() => {
    //     const cartData = JSON.parse(localStorage.getItem("cartItems")) || [];

    //     const api = url + "demoJSON"
    //     fetch(api, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',

    //         },
    //         body: JSON.stringify({ cartData })
    //     })
    //         .then((apidata) => {
    //             //console.log(apidata)
    //             return apidata.json()
    //         })
    //         .then((actualdata) => {


    //         })
    // }, [])

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const handleCheckoutClick = () => {
        if (!b_country || !b_first_name || !b_last_name || !b_address_1 || !b_postal_code || !b_city || !b_phone) {
            alert("Please fill all required billing fields");
            return;
        }

        if (!sameAsBilling && (!c_country || !c_first_name || !c_last_name || !c_address_1 || !c_postal_code || !c_city || !c_phone)) {
            alert("Please fill all required correspondence fields");
            return;
        }

        handleModalShow();
    };

    // const handlePaymentProceed = () => {
    //     handleModalClose();
    //     if (paymentMethod === "online") {
    //         payment(total);
    //     } else {
    //         // Handle COD logic here
    //         alert("Cash on Delivery selected - This feature will be implemented soon!");
    //     }
    // };

    const handleCheckout = () => {
        localStorage.removeItem("cartItems");
        localStorage.setItem("cartCount", "0");
        navigate("/order-confirmation");
    };

    const handleSameAsBillingChange = (e) => {
        setSameAsBilling(e.target.checked);
        if (e.target.checked) {
            // Auto-fill shipping details with billing details
            setCCountry(b_country);
            setCFirstName(b_first_name);
            setCLastName(b_last_name);
            setCAddress1(b_address_1);
            setCAddress2(b_address_2);
            setCPostalCode(b_postal_code);
            setCCity(b_city);
            setCPhone(b_phone);
        } else {
            // Clear shipping details when unchecked
            setCCountry("");
            setCFirstName("");
            setCLastName("");
            setCAddress1("");
            setCAddress2("");
            setCPostalCode("");
            setCCity("");
            setCPhone("");
        }
    };

    const payment = (amount) => {
        //alert(amount)
        handleModalClose();
        //return

        if (!b_country || !b_first_name || !b_last_name || !b_address_1 || !b_postal_code || !b_city || !b_phone) {
            alert("Please fill all required billing fields");
            return;
        }

        if (!sameAsBilling && (!c_country || !c_first_name || !c_last_name || !c_address_1 || !c_postal_code || !c_city || !c_phone)) {
            alert("Please fill all required correspondence fields");
            return;
        }

        var customer_email = customeremail

        if (customer_email === "") {
            customer_email = "NA"
        }

        const cartData = JSON.parse(localStorage.getItem("cartItems")) || [];

        // console.log(cartData)
        // return

        const api = url + "createCheckout";
        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cartData,
                customer_email,
                b_country,
                b_first_name,
                b_last_name,
                b_address_1,
                b_address_2,
                b_postal_code,
                b_city,
                b_phone,
                c_country: sameAsBilling ? b_country : c_country,
                c_first_name: sameAsBilling ? b_first_name : c_first_name,
                c_last_name: sameAsBilling ? b_last_name : c_last_name,
                c_address_1: sameAsBilling ? b_address_1 : c_address_1,
                c_address_2: sameAsBilling ? b_address_2 : c_address_2,
                c_postal_code: sameAsBilling ? b_postal_code : c_postal_code,
                c_city: sameAsBilling ? b_city : c_city,
                c_phone: sameAsBilling ? b_phone : c_phone,
                amount
            })
        })
            .then((apidata) => apidata.json())
            .then((actualdata) => {
                if (actualdata["message"] === "success") {
                    localStorage.setItem("order_Id", actualdata["order_Id"])
                    localStorage.setItem("trnx_amount", amount)

                    if (customer_email === "NA") {
                        customer_email = "aaradhycraft@gmail.com"
                    }

                    if (paymentMethod === "online") {

                        const api = url + "phonepe/initiate";
                        fetch(api, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                customer_email,
                                b_first_name,
                                b_phone,
                                amount
                            })
                        })
                            .then((apidata) => apidata.json())
                            .then((actualdata) => {
                                if (actualdata["message"].includes("cURL Error")) {
                                    alert("Payment Failed...");
                                    return;
                                }
                                // window.location.href = actualdata["message"];
                                // Use the PhonePe Checkout bundle
                                if (window.PhonePeCheckout) {
                                    window.PhonePeCheckout.transact({ tokenUrl: actualdata["message"] });
                                } else {
                                    console.error("PhonePe Checkout not loaded");
                                    alert("Payment gateway not available. Please try again later.");
                                }
                            });
                    }
                    else {
                        window.location = "#/Succes"
                    }
                }

            });


    };

    return (
        <>

            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Payment Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="payment-options">
                        <Row className="payment-option">
                            <Col xs={1} >
                                <input
                                    type="radio"
                                    id="online"
                                    name="paymentMethod"
                                    checked={paymentMethod === "online"}
                                    onChange={() => setPaymentMethod("online")}
                                    className="mt-1"
                                />
                            </Col>
                            UPI / Credit-Debit Card / Net Banking
                            <Col>
                                {/* <label htmlFor="online">
                                
                            </label> */}
                            </Col>
                        </Row>

                        <Row className="payment-option">
                            <Col xs={1} >
                                <input
                                    type="radio"
                                    id="cod"
                                    name="paymentMethod"
                                    checked={paymentMethod === "cod"}
                                    onChange={() => setPaymentMethod("cod")}
                                    className="mt-1"
                                />
                            </Col>
                            Cash on Delivery (COD)
                            <Col>
                                {/* <label htmlFor="online">
                                
                            </label> */}
                            </Col>
                        </Row>

                        {/* <div className="payment-option">
                            <input
                                type="radio"
                                id="cod"
                                name="paymentMethod"
                                checked={paymentMethod === "cod"}
                                onChange={() => setPaymentMethod("cod")}
                            />
                            <label htmlFor="cod">
                                Cash on Delivery (COD)
                            </label>
                        </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => payment(total)}>
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row>
                <Navigation />
            </Row>

            <Row className="mt-5 mx-5">
                <Col>
                    <Row className="appointcenterhead mt-3">
                        <Col sm={1} className="justify_content_left">
                            <Link to="/">Home {">"}</Link>
                        </Col>
                        <Col className="justify_content_left">Checkout</Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-5 mx-5">
                <Col sm={1}></Col>

                <Col sm={6}>
                    <Card>
                        <Card.Header className="carthead hombannercardtext">
                            Billing & Shipping Details
                        </Card.Header>
                        <Card.Body>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="email" placeholder=" " onChange={(e) => setCustomerEmail(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Email </label>
                            </Row>

                            <h5 className="mt-4">Billing Information</h5>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required onChange={(e) => setBCountry(e.target.value)} />
                                <span className="highlight"></span>
                                <label>State *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required onChange={(e) => setBFirstName(e.target.value)} />
                                <span className="highlight"></span>
                                <label>First Name *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required onChange={(e) => setBLastName(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Last Name *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required onChange={(e) => setBAddress1(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Address Line 1 *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required onChange={(e) => setBAddress2(e.target.value)} />
                                <span className="highlight"></span>
                                <label>District *</label>
                                {/* <label>Address Line 2</label> */}
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="number" pattern="[1-9]{1}[0-9]{9}" placeholder=" " required onChange={(e) => setBPostalCode(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Postal Code *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required onChange={(e) => setBCity(e.target.value)} />
                                <span className="highlight"></span>
                                <label>City *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="number" pattern="[1-9]{1}[0-9]{9}" placeholder=" " required onChange={(e) => setBPhone(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Phone *</label>
                            </Row>

                            <Form.Check
                                type="checkbox"
                                label=""
                                checked={sameAsBilling}
                                onChange={handleSameAsBillingChange}
                                className="mt-4"
                            />


                            <h5 className="mt-4">Shipping Information</h5>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required value={c_country} onChange={(e) => setCCountry(e.target.value)} />
                                <span className="highlight"></span>
                                <label>State *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required value={c_first_name} onChange={(e) => setCFirstName(e.target.value)} />
                                <span className="highlight"></span>
                                <label>First Name *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required value={c_last_name} onChange={(e) => setCLastName(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Last Name *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required value={c_address_1} onChange={(e) => setCAddress1(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Address Line 1 *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required value={c_address_2} onChange={(e) => setCAddress2(e.target.value)} />
                                <span className="highlight"></span>
                                <label>District *</label>
                                {/* <label>Address Line 2</label> */}
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="number" pattern="[1-9]{1}[0-9]{9}" placeholder=" " required value={c_postal_code} onChange={(e) => setCPostalCode(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Postal Code *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" " required value={c_city} onChange={(e) => setCCity(e.target.value)} />
                                <span className="highlight"></span>
                                <label>City *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="number" pattern="[1-9]{1}[0-9]{9}" placeholder=" " required value={c_phone} onChange={(e) => setCPhone(e.target.value)} />
                                <span className="highlight"></span>
                                <label>Phone *</label>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>

                <Col sm>
                    <Card>
                        <Card.Header className="carthead hombannercardtext">
                            Order Summary
                        </Card.Header>
                        <Card.Body>
                            <ListGroup variant="flush">
                                {cartItems.map((item) => (
                                    <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <img src={s3 + item.thumbnail} alt={item.name} className="order-item-image" />
                                            <div>
                                                <h5>{item.name}</h5>
                                                <p>Qty: {item.quantity}</p>
                                            </div>
                                        </div>
                                        <span>₹{(item.price * item.quantity).toFixed(2)}</span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>

                            <div className="order-total mt-4">
                                {/* <div className="d-flex justify-content-between">
                                        <span>Subtotal:</span>
                                        <span>₹{subTotal.toFixed(2)}</span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span>Shipping:</span>
                                        <span>₹{shippingFee.toFixed(2)}</span>
                                    </div> */}
                                <div className="d-flex justify-content-between">
                                    <span>Total:</span>
                                    <span>₹{total.toFixed(2)}</span>
                                </div>
                            </div>

                            <Row className="justify_content_center">
                                <button className="chkoutbtn mt-4" onClick={handleCheckoutClick}>
                                    <FaCheckCircle className="me-2" />
                                    Proceed to Checkout
                                </button>
                            </Row>
                            <div className="articledetailtext1 mt-3 mx-0">
                                <b>Note:</b> Orders will be shipped within 2 to 4 working days.
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={1}></Col>
            </Row>

            <Row className="mt-5">
                <Footer />
            </Row>
        </>
    );
};

export default Checkoutcod;