import React, { useState, useEffect } from "react"
import { Row, Col, Button, Form, Card, ListGroup } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import Url from "./Url"
import logo from "./Images/Aaradhy_logo.gif"
// import Navigation from "./Navigationnew"
import Navigation from "./Navprastype"
import Footer from "./Footer"


const Successcod = () => {
    const navigate = useNavigate()
    const url1 = Url()
    const url = url1["url"]
    const s3 = url1["s3"]

    const order_id = localStorage.getItem("order_Id")

    const articles = JSON.parse(localStorage.getItem("cartItems")) || [];

    // Get the part of the URL after the hash
    const hashParams = window.location.hash.slice(1);

    // Split it into the path and query string
    const [path, queryString] = hashParams.split('?');

    // Create URLSearchParams object from the query string
    const urlParams = new URLSearchParams(queryString);

    // Now extract tid and amount
    var transaction_id = urlParams.get('tid');
    // const trnx_amount = urlParams.get('amount');
    const trnx_amount = localStorage.getItem("trnx_amount")

    const actualAmount = trnx_amount ? (parseFloat(trnx_amount) / 100).toFixed(2) : '0.00';

    //console.log(transaction_id)

    const merchantUserId = "NA"
    var trnx_state

    useEffect(() => {

        localStorage.removeItem("cartItems");
        localStorage.removeItem('cartCount')

        if (transaction_id === null) {
            trnx_state = "CoD"
            transaction_id = "CoD"
        }
        else {
            trnx_state = "Paid"
        }



        const api = url + "createPayment_info"
        fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({ articles, order_id, transaction_id, trnx_amount, merchantUserId, trnx_state })
        })
            .then((apidata) => {
                //console.log(apidata)
                return apidata.json()
            })
            .then((actualdata) => {


            })
    }, [])


    // const [tid, setTid] = useState('');
    // const [amount, setAmount] = useState('');

    // useEffect(() => {
    //     // Function to parse URL parameters
    //     const parseUrlParams = () => {
    //         const hash = window.location.hash;
    //         const searchParams = new URLSearchParams(hash.split('?')[1]);
    //         setTid(searchParams.get('tid') || '');

    //         // Convert amount from paise to rupees
    //         const amountInPaise = searchParams.get('amount');
    //         if (amountInPaise) {
    //             const amountInRupees = (parseInt(amountInPaise, 10) / 100).toFixed(2);
    //             setAmount(amountInRupees);
    //         } else {
    //             setAmount('');
    //         }
    //     };

    //     // Parse URL parameters when component mounts
    //     parseUrlParams();

    //     // Add event listener for hash changes
    //     window.addEventListener('hashchange', parseUrlParams);

    //     // Clean up event listener
    //     return () => {
    //         window.removeEventListener('hashchange', parseUrlParams);
    //     };
    // }, []);


    return (
        <>
            <Row>
                <Navigation />
            </Row>

            <Row className="justify_content_center ">
                <img src={logo} alt="" className="footerlogo" />
            </Row>
            <Row className="justify_content_center articleprice  mt-2 ">
                Thank you for shopping with Aaradhy Craft.
            </Row>
            <Row className="justify_content_center articleprice  mt-2">
                Please note the following details of your order:
            </Row>

            <Row className="justify_content_center articleprice  mt-2">
                <Col xs={1}></Col>
                <Col className="justify_content_center ordersummarybox">Order ID: {order_id} </Col>
                {/* <Col className="justify_content_center ordersummarybox">Amount: ₹{trnx_amount}</Col> */}
                {/* <Col className="justify_content_center ordersummarybox">Amount: ₹{actualAmount}</Col> */}
                <Col xs={1}></Col>
            </Row>

            <Row className="justify_content_center articleprice  mt-2">
                Your order will be dispached within 24 hours and will be delivered in 7-8 days.
            </Row>

            <Row className="justify_content_center articledetailtext1 mt-5">
                Note: The price includes courier charges for this order. However, if you choose to return the item, you will be responsible for covering the return courier charges.
            </Row>

            <Row className="mt-5">
                <Footer />
            </Row>
        </>
    )
}

export default Successcod